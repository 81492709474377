/*
This file is intended to reduce dependecies between FormFlowActivity/ActivityInvokers and FormFlow implementation files.
It currently only contains types required for TaskPresenter but will be updated to contain all exported types in an upcoming workflow.
*/
import type { ResourceInfo } from "CaptionService";
import type { TaskShellOptions } from "TaskPresenter";
import type { Shell } from "UIService";
import type FinderRelatedCollectionStrategy from "Widgets/gwFinderRelatedCollectionStrategy";
import type FinderStandaloneStrategy from "Widgets/gwFinderStandaloneStrategy";
import { type PureComputed } from "knockout";
import { type WtgFrameworkTaskGenericActionPlacement } from "wtg-material-ui";

export type ShellProviderFn = (formId: string, shellOptions: TaskShellOptions) => Promise<Shell>;

export type ShellDisposeFn = () => void;

export type TaskPresenterComputedOptions = {
  canShowDocuments: PureComputed<boolean>;
  canShowEDocs: PureComputed<boolean>;
  canShowLogs: PureComputed<boolean>;
  canShowMessages: PureComputed<boolean>;
  canShowNotes: PureComputed<boolean>;
  canShowWorkflow: PureComputed<boolean>;
};

export interface UIExtension {
  applyAsync(): Promise<void>;
}

export type UIExtensionSettings = [extension: UIExtension, runWhenIdle: boolean];

export type FormFlowVariable = {
  Name: string;
  VariableTypeName?: string;
};

export type FormFlowActivity = {
  Id: string;
  Kind: string;
};

export type FormFlowAction = {
  id: string;
  caption?: string;
  onInvoke: () => Promise<unknown>;
};

export type FormFlowDefinition = {
  PK: string;
  Caption: ResourceInfo;
  EntityTypeName?: string;
  DefaultModel?: string;
  Parameters?: FormFlowVariable[];
  LocalVariables?: FormFlowVariable[];
  OutParameters?: FormFlowVariable[];
  Activities?: FormFlowActivity[];
  DisableAddToRecents?: boolean;
  DisableRefreshOnClose?: boolean;
};

export type FormFlowDefinitionProviderFn = (pk: string) => Promise<FormFlowDefinition>;

export type FormFlowAlias = {
  Name: string;
  Module: string;
};

export interface Transition {
  Kind?: string;
  NextActivityId: string;
  SuspendValidation: boolean;
}

export interface FooterTransition extends Transition {
  Id: string;
  Caption: ResourceInfo;
  VisibilityExpression?: string;
  Category?: string;
  ConditionId?: string;
  Condition?: string;
  Placement?: WtgFrameworkTaskGenericActionPlacement;
  Icon?: string;
}

export interface ControlTransition extends Transition {
  ControlId: string;
}

export interface ButtonTransition extends ControlTransition {
  caption: string;
}

export interface TextBoxTransition extends ControlTransition {
  IsGs1VariableName: string;
  TextVariableName: string;
}

export interface LookupTransition extends ControlTransition {
  ResultVariableName: string;
}

export interface FinderTransition extends ControlTransition {
  isGS1: boolean;
  SearchTermVariableName: string;
  ExpandPaths: string;
  ResultsVariableName: string;
}

export function isControlTransition(transition: Transition): transition is ControlTransition {
  return "ControlId" in transition;
}

export function isButtonTransition(transition: Transition): transition is ButtonTransition {
  return transition.Kind === "Button";
}

export function isTextBoxTransition(transition: Transition): transition is TextBoxTransition {
  return transition.Kind === "TextBox";
}

export function isFinderTransition(transition: Transition): transition is FinderTransition {
  return transition.Kind === "Finder";
}

export function isLookupTransition(transition: Transition): transition is LookupTransition {
  return transition.Kind === "Lookup";
}

export function isFooterTransition(transition: Transition): transition is FooterTransition {
  return transition.Kind === "Footer";
}

export interface ControlTransitionEventArgs {
  controlId: string;
  isHandled: boolean;
}

export interface FinderTransitionEventArgs extends ControlTransitionEventArgs {
  searchTerm: string;
  isGS1: boolean;
  strategy: FinderRelatedCollectionStrategy | FinderStandaloneStrategy;
}

export interface TextBoxTransitionEventArgs extends ControlTransitionEventArgs {
  text: string;
  isGs1: boolean;
}

export interface LookUpTransitionEventArgs extends ControlTransitionEventArgs {
  entityName: string;
  entityPK: string;
}

export function isFinderTransitionEventArgs(
  eventArgs: ControlTransitionEventArgs
): eventArgs is FinderTransitionEventArgs {
  return "strategy" in eventArgs;
}

export function isTextBoxTransitionEventArgs(
  eventArgs: ControlTransitionEventArgs
): eventArgs is TextBoxTransitionEventArgs {
  return "text" in eventArgs;
}

export function isLookupTransitionEventArgs(
  eventArgs: ControlTransitionEventArgs
): eventArgs is LookUpTransitionEventArgs {
  return "entityName" in eventArgs;
}

export type SubFormFlowEventArgs = {
  entityPK?: string;
  entityName?: string;
  entityPKs?: string[];
  headerEntityName?: string;
  headerEntityPK?: string;
  formFlowPK?: string;
  CreateNewSession?: boolean;
  ShowInDialog?: boolean;
  ShowInDrawer?: boolean;
  ShowMaximizedDialog?: boolean;
  SuspendValidation: boolean;
  DisableSaveCurrentSession?: boolean;
};
