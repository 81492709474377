import { CancellationError } from 'Errors';
import { getVueInstance, loadVueComponentDefinitionsAsync } from 'VueComponentService';
import { setBindingContext } from 'VueHooks/BindingContextHook.ts';
import { setContentViewModel } from 'VueHooks/ContentViewModelHook.ts';
import { setFormExtender } from 'VueHooks/FormExtenderHook.ts';
import { setFormInfo } from 'VueHooks/FormInfoHook.ts';
import { setKnockoutContext } from 'VueHooks/KnockoutContextHook.ts';
import { setMessageBus } from 'VueHooks/MessageBusProviderHook.ts';
import { setPageViewModel } from 'VueHooks/PageViewModelHook.ts';
import { setValidationRegistrar } from 'VueHooks/ValidationRegistrarHook.ts';
import wtgMaterialUi from 'VuePlugins/WtgMaterialUi';
import wtgPinia from 'VuePlugins/WtgPinia';
import ko from 'knockout';
import Vue, { shallowRef } from 'vue';

class VueFactory {
	constructor() {}

	async createVueInstanceAsync(options) {
		const container = options.contentContainer;
		const components = await loadVueComponentDefinitionsAsync(container);

		if (!document.contains(container)) {
			/*! SuppressStringValidation Error Message */
			throw new CancellationError('Could not create an instance whose container is not in the document');
		}

		if (getVueInstance(container)) {
			throw new Error('Vue instance already exists');
		}

		const parent = findParentVueInstance(container);
		if (options.requiresParent && !parent) {
			return null;
		}

		const componentOptions = {
			el: container,
			parent,
			name: options.name,
			comments: true,
			components,
			created() {
				if (options.bindingContext) {
					setPageViewModel(options.bindingContext);
					setContentViewModel(options.bindingContext.contentViewModel);
					setBindingContext(shallowRef(options.bindingContext));
					setMessageBus(shallowRef(options.bindingContext));
				}
				if (options.validationRegistrar) {
					setValidationRegistrar(options.validationRegistrar);
				}
				if (options.knockoutContext) {
					setKnockoutContext(options.knockoutContext);
				}
				if (options.formInfo) {
					setFormInfo(options.formInfo);
				}
				if (options.formExtender) {
					setFormExtender(
						options.formExtender,
						options.bindingContext?.pageExtensions?.dataItem,
						options.bindingContext?.pageExtensions?.messageBus
					);
				}
			},
			data: options.data || noData,
			methods: options.methods || {},
		};
		if (!parent) {
			componentOptions.wtgMaterialUi = wtgMaterialUi;
			componentOptions.pinia = wtgPinia;
		}

		const instance = new Vue(componentOptions);
		ko.utils.domNodeDisposal.addDisposeCallback(instance.$el, () => {
			instance.$destroy();
		});

		return instance;
	}
}

function noData() {
	return {};
}

function findParentVueInstance(element) {
	let parent = element.parentNode;

	while (parent) {
		const instance = getVueInstance(parent);
		if (instance) {
			return instance;
		}
		parent = parent.parentNode;
	}
}

export default new VueFactory();
