import captionService from 'CaptionService';

export const approvalBanners = {
	parentNotAwaitingApproval(description) {
		return captionService.getString(
			'e8336568-aa75-48e6-a5fb-0ce8d62b2519',
			'This {0} is not awaiting approval.',
			description
		);
	},
	taskApproved() {
		return captionService.getString(
			'5b386fd7-01e6-4cbd-9dd5-347ca465960f',
			'This task has been closed as approved.'
		);
	},
	taskAssignedToOtherUser() {
		return captionService.getString(
			'f5df92f3-2049-41df-906f-118466907566',
			'This approval task is not assigned to you.'
		);
	},
	taskNotCurrent() {
		return captionService.getString(
			'82542f73-f379-4cdd-a53d-693ecfdce0f8',
			'This approval task is not currently active.'
		);
	},
	taskPrereqRejected() {
		return captionService.getString(
			'a3a7c703-6070-4a9e-bbad-469d90fb7bde',
			'One or more prior approval tasks have been closed as rejected.'
		);
	},
	taskRejected() {
		return captionService.getString(
			'f61b8206-3b1f-47b6-aeb6-561a981272e6',
			'This task has been closed as rejected.'
		);
	},
	taskCancelled() {
		return captionService.getString(
			'ec344c4f-5f07-49ac-85ec-ad39b15a22ed',
			'This task has been canceled.'
		);
	}
};
