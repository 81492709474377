// This must be a CommonJS module because knockout is aliased to this.

const Promise = require('bluebird');
const ko = require('knockout.base');
const { lazyComputed, lazyObservable, waitForValueAsync, promiseObserver } = require('KnockoutExtensions');

ko.lazyObservable = (callback, context, initialValue) => {
	return lazyObservable(callback, context, initialValue);
};

ko.lazyObservableArray = (callback, context) => {
	const value = ko.observableArray();

	const result = lazyComputed(callback, value, context);

	//add underlying array methods onto computed observable
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	ko.utils.arrayForEach(['remove', 'removeAll', 'destroy', 'destroyAll', 'indexOf', 'replace', 'pop', 'push', 'reverse', 'shift', 'sort', 'splice', 'unshift', 'slice'], (methodName) => {
		result[methodName] = function () {
			return value[methodName].apply(value, arguments);
		};
	});

	return result;
};

function equalityComparer(first, second) {
	return first === second;
}

ko.asyncComputed = (newValueLoader, dependencyAccessor, owner) => {
	let isCommitting = false;
	const value = ko.observable();
	value.equalityComparer = equalityComparer;
	let loaderToken = null;

	const commit = (loadedValue) => {
		isCommitting = true;
		value(loadedValue);
		isCommitting = false;
	};

	let promise;

	const observable = ko.computed(
		{
			read() {
				if (isCommitting) {
					if (dependencyAccessor) {
						// Keep subscriptions alive so that the computed value will be refreshed when they change.
						dependencyAccessor.call(owner);
					}
				}
				else {
					const oldValue = value();
					const result = newValueLoader.call(owner, oldValue);

					if (result) {
						commit(result.syncValue);
						loaderToken = null;

						if (result.asyncPromise) {
							const token = {};
							loaderToken = token;
							observable.state('pending');

							promise = result.asyncPromise
								.then((loadedValue) => {
									if (token === loaderToken) {
										commit(loadedValue);
										observable.state('resolved');
										loaderToken = null;
									}

									return null;
								})
								.catch((error) => {
									if (token === loaderToken) {
										let errorHandled = false;

										observable.state('rejected');
										if (result.errorHandler) {
											errorHandled = result.errorHandler.call(owner, error);
										}

										loaderToken = null;

										if (!errorHandled) {
											throw error;
										}
									}
								})
								.finally(() => {
									if (token === loaderToken) {
										promise = null;
									}
								});
						}
						else {
							observable.state('resolved');
						}
					}
				}

				return value();
			},
			owner,
			deferEvaluation: true
		});
	observable.equalityComparer = equalityComparer;
	observable.state = ko.observable('pending');

	observable.loaded = ko.pureComputed(() => {
		return observable.state() === 'resolved';
	});

	observable.waitForLoadAsync = () => {
		return Promise.resolve(promise);
	};

	return observable;
};

ko.waitForValueAsync = function (observable, targetValue) {
	return waitForValueAsync(observable, targetValue);
};

const MaxUnwrapRecursionDepth = 2;
ko.recursiveUnwrap = function (propertyValue, recursionDepth) {
	if (recursionDepth < MaxUnwrapRecursionDepth) {
		const result = ko.unwrap(propertyValue);

		return ko.isObservable(result) ? this.recursiveUnwrap(result, recursionDepth + 1) : result;
	}

	return propertyValue;
};

const originalRateLimit = ko.extenders.rateLimit;
ko.extenders.rateLimit = (target, options) => {
	target.notifySubscribersImmediately = target.notifySubscribers;
	return originalRateLimit(target, options);
};

ko.promiseObserver = promiseObserver;

ko.observable.fn.withPausing = function () {
	this.notifySubscribers = function notifySubscribersWithPausing() {
		if (!this._pauseNotifications) {
			ko.subscribable.fn.notifySubscribers.apply(this, arguments);
		}
	};

	this.poke = function poke(newValue) {
		this._pauseNotifications = true;
		this(newValue);
		this._pauseNotifications = false;
	};

	return this;
};

/*! SuppressStringValidation ko binding attribute name */
const defaultBindingAttributeName = 'data-bind';
/*! SuppressStringValidation ko binding keyword */
const bubbleKeyword = 'Bubble';
/*! SuppressStringValidation default ko binding handler name */
const click = 'click';
/*! SuppressStringValidation custom binding handler name */
const asyncClick = 'asyncClick';

ko.bindingProvider.instance.preprocessNode = (node) => {
	if (ko.bindingProvider.instance.nodeHasBindings(node)) {
		const bindingString = getBindingsString(node);
		const keyValuePairs = ko.expressionRewriting.parseObjectLiteral(bindingString);
		validateAgainstBindingHandlers(keyValuePairs);
	}
};

function validateAgainstBindingHandlers(keyValuePairs) {
	keyValuePairs.forEach((keyValuePair) => {
		let bindingName = keyValuePair.key || keyValuePair.unknown;
		if (bindingName && !isInWhiteList(bindingName)) {
			if (bindingName.endsWith(bubbleKeyword) && !ko.bindingHandlers[bindingName]) {
				const originalBindingName = bindingName.split(bubbleKeyword)[0];

				if (!hasOriginalBindingName(keyValuePairs, originalBindingName)) {
					throw new Error(bindingName + ' must have binding attribute ' + originalBindingName);
				}
				bindingName = originalBindingName;
			}
			throwIfCustomBindingNotRegistered(bindingName);
		}
	});
}

function throwIfCustomBindingNotRegistered(bindingName) {
	if (ko.bindingHandlers[bindingName] === undefined) {
		throw new Error('Custom binding is not registered: ' + bindingName);
	}
}

function hasOriginalBindingName(keyValuePairs, originalBindingName) {
	let result = keyValuePairs.find((pair) => { return pair.key === originalBindingName; });
	if (!result && originalBindingName === click) {
		result = keyValuePairs.find((pair) => { return pair.key === asyncClick; });
	}
	return result;
}

function getBindingsString(node) {
	switch (node.nodeType) {
		case 1: return node.getAttribute(defaultBindingAttributeName); // Element
		case 8: return ko.virtualElements.virtualNodeBindingValue(node); // Comment node
		default: return null;
	}
}

function isInWhiteList(bindingName) {
	return bindingNameWhiteList.indexOf(bindingName) !== -1;
}

/*! StartNoStringValidationRegion ko binding options which don't have a handler */
const bindingNameWhiteList = [
	'optionsCaption',
	'optionsValue',
	'optionsText',
	'optionsAfterRender',
	'valueAllowUnset',
	'valueUpdate',
	'omitBlankElement',
	'gwReadOnlyCss',
	'gwDataGridColumnType',
	'gwCaptionOptions',
	'gwValidationTooltipOptions'
];
/*! EndNoStringValidationRegion */

module.exports = ko;
