import constants from 'Constants';
import EntityFunctionLookupProvider from './EntityFunctionLookupProvider';
import EntityQueryLookupProvider from './EntityQueryLookupProvider';
import EntityInfoQueryLookupProvider from './EntityInfoQueryLookupProvider';
import RuleBasedLookupProvider from './RuleBasedLookupProvider';

function create(args) {
	if (args.ruleId) {
		return createRuleBasedMetadata(args);
	}
	else if (args.lookupFunc) {
		return createEntityFunctionMetadata(args);
	}
	else {
		return createEntityQueryMetadata(args);
	}
}

function createEntityBasedMetadata(args) {
	return {
		allowFreeTextEntry: args.allowFreeTextEntry,
		codePath: args.codePath,
		descriptionPath: args.descriptionPath,
		orderByPaths: args.orderByPaths,
		searchDisplayMode: args.searchDisplayMode || getDefaultDisplayMode(args.codePath, args.descriptionPath),
		dropDownDisplayMode: args.dropDownDisplayMode || constants.DropDownDisplayModes.Unspecified,
		displayAsDropDown: args.displayAsDropDown,
		valuePath: args.valuePath,
		expandPaths: args.expandPaths,
		itemType: args.itemType
	};
}

function createEntityFunctionMetadata(args) {
	const result = createEntityBasedMetadata(args);
	result.provider = new EntityFunctionLookupProvider(args.lookupFunc, args.valuePath);
	return result;
}

function createEntityQueryMetadata(args) {
	const result = createEntityBasedMetadata(args);
	result.advancedSearchMode = decodeAdvancedSearchMode(args.advancedSearchMode);
	result.provider = createQueryLookupProvider(result.advancedSearchMode, args);

	return result;
}

function createQueryLookupProvider(providerType, args) {
	switch (providerType) {
		case constants.SearchListModes.Entity:
			return new EntityQueryLookupProvider(args);
		case constants.SearchListModes.EntityInfo:
			return new EntityInfoQueryLookupProvider(args);
		default:
			throw new Error('Unable to create query provider for ' + providerType);
	}
}

function createRuleBasedMetadata(args) {
	/*! StartNoStringValidationRegion No captions here */
	return {
		allowFreeTextEntry: args.allowFreeTextEntry,
		codePath: 'code',
		descriptionPath: 'description',
		sentimentPath: 'sentiment',
		provider: new RuleBasedLookupProvider(args.entityName, args.ruleId),
		searchDisplayMode: args.searchDisplayMode || constants.DropDownDisplayModes.CodeDesc,
		dropDownDisplayMode: args.dropDownDisplayMode || constants.DropDownDisplayModes.Unspecified,
		valuePath: 'code',
		displayAsStatus: args.displayAsStatus,
	};
	/*! EndNoStringValidationRegion */
}

function decodeAdvancedSearchMode(advancedSearchMode) {
	if (advancedSearchMode === 'Index') {
		return constants.SearchListModes.EntityInfo;
	}
	else {
		return constants.SearchListModes.Entity;
	}
}

function getDefaultDisplayMode(codePath, descriptionPath) {
	if (codePath) {
		return descriptionPath ? constants.DropDownDisplayModes.CodeDesc : constants.DropDownDisplayModes.CodeOnly;
	}
	else {
		return constants.DropDownDisplayModes.DescOnly;
	}
}

export default { create };
