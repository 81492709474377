import type breeze from "BreezeExtensions";
import captionService from "CaptionService";
import { NotificationType, compareNotificationTypes } from "NotificationType";
import ko, { type Computed, type Observable, type ObservableArray } from "knockout";

/** @abstract Implement this interface for supporting additional custom properties */
export interface AlertDetails {
  ruleId?: string;
  Level: NotificationType;
  propertyName: string;
  Text?: string;
  requiresAcknowledgement?: boolean;
  [key: string]: unknown;
}

export class Alert {
  readonly ruleId?: string;
  readonly Level: NotificationType;
  readonly propertyName: string;
  readonly Text?: string;
  readonly acknowledged?: Computed<boolean>;
  readonly acknowledgedDescription?: Computed<string>;
  private readonly _requiresAcknowledgement: boolean = false;
  [key: string]: unknown;

  constructor(details: AlertDetails, container: Notifications) {
    this.ruleId = details.ruleId;
    this.Level = details.Level;
    this.propertyName = details.propertyName;
    this.Text = details.Text;

    extendWithoutOverride(this, details);

    if (details.Level === NotificationType.Warning) {
      const isAcknowledged = ko.observable(false);
      this.acknowledged = ko.computed<boolean>({
        read(): boolean {
          return isAcknowledged();
        },
        write(this: Alert, value: boolean) {
          isAcknowledged(value);

          let index = -1;
          for (let i = 0; i < container.acknowledgedAlerts.length; i++) {
            const alert = container.acknowledgedAlerts[i];
            if (alert.ruleId === details.ruleId) {
              index = i;
              break;
            }
          }

          if (value && index === -1) {
            container.acknowledgedAlerts.push(this);
          } else if (!value && index > -1) {
            container.acknowledgedAlerts.splice(index, 1);
          }
        },
        owner: this,
      });

      this.acknowledgedDescription = ko.pureComputed(() => {
        return isAcknowledged()
          ? captionService.getString("363d3918-b20b-4041-b4ad-309569227f1e", "This warning has been acknowledged")
          : captionService.getString(
              "987f0462-1ad8-4a6e-8e2c-f010cf8b5323",
              "Click to acknowledge that you have read and understood this warning"
            );
      }, this);

      this._requiresAcknowledgement = details.requiresAcknowledgement !== false;
    }
  }

  isMessageError(): boolean {
    return this.Level === NotificationType.MessageError;
  }

  isError(): boolean {
    return this.Level === NotificationType.Error;
  }

  isInfo(): boolean {
    return this.Level === NotificationType.Information;
  }

  isWarning(): boolean {
    return this.Level === NotificationType.Warning;
  }

  requiresAcknowledgement(): boolean {
    return this.isWarning() && this._requiresAcknowledgement;
  }
}

function extendWithoutOverride(target: Alert, source: AlertDetails): void {
  for (const property in source) {
    if (source[property] !== undefined && !(property in target)) {
      target[property] = source[property];
    }
  }
}

export class Notifications {
  buckets?: NotificationBuckets;
  acknowledgedAlerts: Alert[] = [];

  static get(provider: unknown): Notifications | undefined {
    if (!isObject(provider)) {
      return undefined;
    }

    if ("notifications" in provider && provider.notifications instanceof Notifications) {
      return provider.notifications;
    }

    if (isEntity(provider)) {
      return provider.entityAspect.notifications;
    }

    return undefined;
  }

  static getLevel(alerts: Alert[]): NotificationType {
    let result = NotificationType.None;

    for (let i = 0; i < alerts.length; i++) {
      const alert = alerts[i];
      if (compareNotificationTypes(alert.Level, result) === 1) {
        result = alert.Level;
      }
    }

    return result;
  }

  hasAlerts(): boolean {
    return this.getBuckets().mergeBuckets().length > 0;
  }

  alerts(propertyName?: string): Alert[] {
    const bucket = this.getBucket(propertyName);
    return bucket();
  }

  clearAcknowledgedAlerts(): void {
    this.acknowledgedAlerts = [];
  }

  level(propertyName: string): NotificationType {
    const alerts = this.alerts(propertyName);
    return Notifications.getLevel(alerts);
  }

  push(alert: AlertDetails): void {
    this.getBucket(alert.propertyName).push(new Alert(alert, this));
    this.notifyChanged();
  }

  pushAll(alerts: AlertDetails[]): void {
    if (alerts.length > 0) {
      const changedBuckets = new Map<string, ObservableArray<Alert>>();

      alerts.forEach((alert) => {
        let bucket = changedBuckets.get(alert.propertyName);

        if (!bucket) {
          bucket = this.getBucket(alert.propertyName);
          bucket.valueWillMutate?.call(bucket);
          changedBuckets.set(alert.propertyName, bucket);
        }

        const array = bucket.peek();

        if (alert.ruleId) {
          removeAlertWithRuleId(array, alert.ruleId);
        }

        array.push(new Alert(alert, this));
      });

      changedBuckets.forEach((changedBucket) => {
        changedBucket.valueHasMutated?.call(changedBucket);
      });

      this.notifyChanged();
    }
  }

  removeAll(filter?: (item: Alert) => boolean): void {
    let changed = false;
    const buckets = this.getBuckets();

    buckets.dictionary.forEach((bucket) => {
      changed = removeAll(bucket, filter) || changed;
    });

    if (changed) {
      this.notifyChanged();
    }
  }

  removeForProperty(propertyName: string, filter?: (item: Alert) => boolean): void {
    if (removeAll(this.getBucket(propertyName), filter)) {
      this.notifyChanged();
    }
  }

  removeForProperties(propertyNames: string[]): void {
    let changed = false;
    const buckets = this.getBuckets();

    propertyNames.forEach((propertyName) => {
      const bucket = buckets.dictionary.get(propertyName);
      if (bucket) {
        changed = removeAll(bucket) || changed;
      }
    });

    if (changed) {
      this.notifyChanged();
    }
  }

  private getBucket(propertyName?: string): ObservableArray<Alert> {
    const buckets = this.getBuckets();

    if (propertyName) {
      return buckets.getBucketForProperty(propertyName);
    } else {
      return buckets.getMergedBucket();
    }
  }

  private getBuckets(): NotificationBuckets {
    if (!this.buckets) {
      this.buckets = new NotificationBuckets();
    }
    return this.buckets;
  }

  private notifyChanged(): void {
    this.buckets?.changed?.notifySubscribers();
  }
}

class NotificationBuckets {
  readonly changed: Observable<void>;
  readonly dictionary: Map<string, ObservableArray<Alert>>;
  merged?: ObservableArray<Alert>;

  constructor() {
    this.changed = ko.observable<void>();
    this.dictionary = new Map();
  }

  getBucketForProperty(propertyName: string): ObservableArray<Alert> {
    let result = this.dictionary.get(propertyName);

    if (!result) {
      result = ko.observableArray<Alert>();
      this.dictionary.set(propertyName, result);
    }

    return result;
  }

  getMergedBucket(): ObservableArray<Alert> {
    let result = this.merged;

    if (!result) {
      result = ko.computed<Alert[]>(() => this.mergeBuckets()) as unknown as ObservableArray<Alert>;
      this.merged = result;
    }

    return result;
  }

  mergeBuckets(): Alert[] {
    const result: Alert[] = [];
    this.changed();

    this.dictionary.forEach((alerts) => {
      alerts.peek().forEach((alert) => {
        result.push(alert);
      });
    });

    result.sort((a, b) => {
      return compareNotificationTypes(b.Level, a.Level);
    });
    return result;
  }
}

function removeAlertWithRuleId(array: Alert[], ruleId: string): void {
  for (let j = array.length - 1; j >= 0; j--) {
    if (array[j].ruleId === ruleId) {
      array.splice(j, 1);
    }
  }
}

function removeAll(bucket?: ObservableArray<Alert>, filter?: (item: Alert) => boolean): boolean {
  if (bucket && bucket().length > 0) {
    const removed = filter ? bucket.remove(filter) : bucket.removeAll();
    return removed.length > 0;
  }
  return false;
}

function isObject(input: unknown): input is object {
  return !!input || typeof input === "object";
}

function isEntity(input: object): input is breeze.Entity {
  // we cannot directly check instanceof breeze.EntityAspect as that would cause a circular dependency
  return "entityAspect" in input && isObject(input.entityAspect) && "notifications" in input.entityAspect;
}

export default Notifications;
