import { getActivityInvokerAsync, type ActivityResult } from "ActivityInvokerProvider";
import { addWithoutMutating, removeWithoutMutating } from "ArrayUtils";
import AsyncLock from "AsyncLock";
import { type Entity, type EntityManager } from "BreezeExtensions";
import captionService from "CaptionService";
import connection from "Connection";
import { getInterfaceName } from "EntityExtensions";
import { observeHasChanges } from "EntityManagerExtensions";
import { setSessionVariables } from "EntityManagerFormFlowSessionVariables";
import entitySaveService, { type EntitySaveResult } from "EntitySaveService";
import { getGenericTypeArgument } from "EntityTypeUtils";
import { AmbiguousModelError, FormFlowAbortError, type ErrorData } from "Errors";
import { FormFlowError, FormFlowErrorType } from "FormFlowError";
import {
  type FormFlowActivity,
  type FormFlowDefinition,
  type FormFlowDefinitionProviderFn,
  type FormFlowVariable,
} from "FormFlowTypes";
import { type Context } from "FormFlowUIContextProvider";
import { newGuid } from "GuidGenerator";
import log from "Log";
import ModelProvider from "ModelProvider";
import { SaveEntityError, SaveEntityErrorType } from "SaveEntityError";
import semaphoreProvider, { type SemaphoreUsage } from "SemaphoreProvider";
import type { OkIfNotFound } from "UtilityTypes";
import { makeDefaultValueStrategy, makeStrategy, type FormFlowVariableStrategy } from "VariableStrategyFactory";

export interface VariableChangeEvent<T = unknown> {
  session: FormFlowSession;
  name: string;
  value: T;
}

export type VariableListener<T = unknown> = (event: VariableChangeEvent<T>) => void;
export type StateListener<T = unknown> = (key: string) => T;
export type SaveListener = (event: { entityManager: EntityManager }) => Promise<EntitySaveResult> | void;

export class FormFlowSession {
  private outParameterNames: string[];
  private variableStrategies: { [key: string]: FormFlowVariableStrategy };
  readonly rootSession: FormFlowSession | undefined;
  private entityManagers: EntityManager[];
  private entityManagersLock: AsyncLock;
  private activities: { [key: string]: FormFlowActivity } = {};
  private startActivityCount: number;
  private startActivityId = "";
  readonly definitionPK: string;
  private formFlowDefinitionProviderFn: FormFlowDefinitionProviderFn;
  private sessionVariables: Record<string, FormFlowVariable> = {};
  private abortToken: { isAborted: boolean };
  private semaphoreTokens: Map<string, string>;
  private saveListeners: SaveListener[];
  private variableListeners: VariableListener[];
  private getStateListeners: StateListener[];
  private variables: { [key: string]: FormFlowVariable } = {};
  private _uiContext: Context;
  readonly modelProvider: ModelProvider;

  get uiContext(): Context {
    return this._uiContext;
  }

  set uiContext(uiContext: Context) {
    this._uiContext = uiContext;
  }

  constructor(
    definition: FormFlowDefinition,
    argumentStrategies: FormFlowVariableStrategy[],
    entityManagers: EntityManager[],
    entityManagersLock: AsyncLock,
    formFlowDefinitionProviderFn: FormFlowDefinitionProviderFn,
    uiContext: Context,
    sessionVariables: { [key: string]: FormFlowVariable } = {},
    abortToken: { isAborted: boolean },
    semaphoreTokens: Map<string, string>,
    rootSession?: FormFlowSession
  ) {
    this.variables = {};
    this.outParameterNames = [];

    if (definition.Parameters) {
      definition.Parameters.forEach((x) => {
        this.variables[x.Name] = x;
      });
    }

    if (definition.LocalVariables) {
      definition.LocalVariables.forEach((x) => {
        this.variables[x.Name] = x;
      });
    }
    if (definition.OutParameters) {
      definition.OutParameters.forEach((x) => {
        this.variables[x.Name] = x;
        this.outParameterNames.push(x.Name);
      });
    }

    this.variableStrategies = {};
    this.setArgumentStrategies(definition.Parameters || [], argumentStrategies);

    this.rootSession = rootSession;
    this.modelProvider = new ModelProvider(
      definition.DefaultModel,
      definition.EntityTypeName,
      rootSession && rootSession.modelProvider
    );
    this.entityManagers = entityManagers;
    this.entityManagersLock = entityManagersLock;

    this.activities = {};
    this.startActivityCount = 0;
    if (definition.Activities) {
      definition.Activities.forEach((x: FormFlowActivity) => {
        this.activities[x.Id] = x;
        if (x.Kind === "Start") {
          ++this.startActivityCount;
          this.startActivityId = x.Id;
        }
      });
    }
    this.definitionPK = definition.PK;

    this.formFlowDefinitionProviderFn = formFlowDefinitionProviderFn;
    this._uiContext = uiContext;
    this.sessionVariables = sessionVariables;
    this.abortToken = abortToken;
    this.semaphoreTokens = semaphoreTokens;

    this.saveListeners = [];
    this.variableListeners = [];
    this.getStateListeners = [];
  }

  static create(
    definition: FormFlowDefinition,
    argumentStrategies: FormFlowVariableStrategy[],
    formFlowDefinitionProviderFn: FormFlowDefinitionProviderFn,
    uiContext: Context,
    modalDialogOnlyEntityManager?: EntityManager
  ): FormFlowSession {
    return new FormFlowSession(
      definition,
      argumentStrategies,
      modalDialogOnlyEntityManager ? [modalDialogOnlyEntityManager] : [],
      new AsyncLock(),
      formFlowDefinitionProviderFn,
      uiContext,
      {},
      { isAborted: false },
      new Map()
    );
  }

  hasChanges(): boolean {
    return this.entityManagers.some((e) => observeHasChanges(e));
  }

  async getEntityManagerAsync<TOkIfNotFound extends boolean | undefined = undefined>(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: TOkIfNotFound
  ): Promise<OkIfNotFound<TOkIfNotFound, EntityManager>> {
    const { entityManagers: entityManagers, modelProvider } = this;

    return await this.entityManagersLock.doAsync(async () => {
      const parentType = getGenericTypeArgument(entityTypeName);
      entityTypeName = parentType ?? entityTypeName;
      let entityManager = getExistingEntityManager(entityTypeName);
      if (entityManager) {
        return entityManager;
      }

      try {
        entityManager = (await modelProvider.createEntityManagerAsync(
          entityTypeName,
          optionalRouteName,
          okIfNotFound
        )) as OkIfNotFound<TOkIfNotFound, EntityManager>;
      } catch (error) {
        if (error instanceof AmbiguousModelError) {
          throw new FormFlowError(error.message);
        }
        throw error;
      }
      if (entityManager) {
        setSessionVariables(entityManager, this.sessionVariables);
        entityManagers.push(entityManager);
      }
      return entityManager as OkIfNotFound<TOkIfNotFound, EntityManager>;
    });

    function getExistingEntityManager(entityTypeName: string): EntityManager | undefined {
      return entityManagers
        .filter((e) => e.metadataStore.getEntityType(entityTypeName, /*okIfNotFound:*/ true))
        .sort((a, b) => getPriority(a) - getPriority(b))[0];
    }

    function getPriority(entityManager: EntityManager): 0 | 1 | 2 | 3 {
      const { metadataStore } = entityManager;
      if (optionalRouteName && metadataStore.getRouteName() === optionalRouteName) {
        return 0;
      } else if (modelProvider.routeName && metadataStore.getRouteName() === modelProvider.routeName) {
        return 1;
      } else if (
        modelProvider.mainEntityTypeName &&
        metadataStore.getEntityType(modelProvider.mainEntityTypeName, /*okIfNotFound:*/ true)
      ) {
        return 2;
      } else {
        return 3;
      }
    }
  }

  async discardEntityManagersAsync(): Promise<void> {
    return await this.entityManagersLock.doAsync(() => {
      this.entityManagers.length = 0;
    });
  }

  hasEntityManager(entityManager: EntityManager): boolean {
    return this.entityManagers.includes(entityManager);
  }

  async saveAsync({
    shouldShowAlert = true,
    shouldRefresh = true,
    shouldReconcileConflicts = true,
  } = {}): Promise<EntitySaveResult> {
    return await this.saveCoreAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts);
  }

  private saveCoreAsync(
    shouldShowAlert: boolean,
    shouldRefresh: boolean,
    shouldReconcileConflicts: boolean
  ): Promise<EntitySaveResult> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    return this.entityManagersLock.doAsync(async () => {
      return await this.saveOneAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts, 0);
    });
  }

  private async saveOneAsync(
    shouldShowAlert: boolean,
    shouldRefresh: boolean,
    shouldReconcileConflicts: boolean,
    index: number
  ): Promise<EntitySaveResult> {
    if (index >= this.entityManagers.length) {
      return { isSaved: true, error: null };
    }

    const saveFunction = shouldShowAlert
      ? entitySaveService.saveWithAlertsAsync
      : entitySaveService.saveWithoutAlertsAsync;

    const entityManager = this.entityManagers[index];

    log.info(
      `[Form-flow] saving form-flow ${this.definitionPK} - route: ${entityManager.metadataStore.getRouteName()}`
    );

    let result: EntitySaveResult = { isSaved: false, error: null };
    try {
      result = await saveFunction.call(entitySaveService, entityManager, {
        shouldReconcileConflicts,
        shouldRefresh,
        shouldShowDialog: (errorType: SaveEntityErrorType) => knownSaveEntityErrorTypes.has(errorType),
      });
    } catch (error: unknown) {
      if (error instanceof connection.OfflineError) {
        throw error;
      } else if (error instanceof SaveEntityError) {
        handleUnknownSaveError(error);
      }
    }

    if (result.isSaved) {
      result = await this.notifySaveListenersAsync({ entityManager });
      if (result.isSaved) {
        // if should refresh, remove the head of the list, and continue to save the 0th item
        // else, keep the entity manager in the list, and continue to save the next item
        if (shouldRefresh) {
          this.entityManagers.shift();
        } else {
          ++index;
        }

        return this.saveOneAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts, index);
      }
    } else if (result?.error && !knownSaveEntityErrorTypes.has(result.error.type)) {
      handleUnknownSaveError(result.error);
    }

    return result;

    function handleUnknownSaveError(error: SaveEntityError): void {
      const friendlyCaption = captionService.getString("6bf8b145-403d-429c-ad3d-48e3073787c0", "Form-flow save error");
      let friendlyMessage, type;
      if (error?.type === SaveEntityErrorType.DatabaseIsUpgrading) {
        type = FormFlowErrorType.NonReportableRuntimeError;
        friendlyMessage = captionService.getString(
          "700b4235-b314-4b36-9f18-beb3caff4668",
          "The database is currently being upgraded. The result of the save is unknown, and so the form-flow will exit. Please try again later."
        );
      } else {
        type = FormFlowErrorType.ReportableRuntimeError;
        friendlyMessage = captionService.getString(
          "276ed5b4-2dbe-4dc2-a0b7-0b87c25f0239",
          "An unexpected error occurred while saving. The result of the save is unknown, and so the form-flow will exit. The error has been automatically reported."
        );
      }
      /*! SuppressStringValidation Error message */
      throw new FormFlowError("Unhandled error occurred while saving form-flow session.", {
        type,
        cause: error,
        friendlyCaption,
        friendlyMessage,
      });
    }
  }

  setVariableValue(name: string, value: unknown): void {
    this.setVariableStrategy(name, makeStrategy(value));
    this.notifyVariableListeners({ session: this, name, value });
  }

  setVariableStrategy(name: string, strategy: FormFlowVariableStrategy): void {
    this.variableStrategies[this.getVariable(name).Name] = strategy;
  }

  unsetVariable(name: string): void {
    delete this.variableStrategies[this.getVariable(name).Name];
  }

  async getVariableValueAsync<T = unknown>(name: string): Promise<T> {
    const variable = this.getVariable(name);
    const value = await this.getVariableStrategy(variable)(this, variable);
    this.notifyVariableListeners({ session: this, name, value });
    return value as T;
  }

  getVariableNames(): string[] {
    return Object.keys(this.variables);
  }

  getVariableTypeName(name: string): string | undefined {
    const variable = this.getVariable(name);
    return variable.VariableTypeName;
  }

  getVariableStrategies(names: string[]): FormFlowVariableStrategy[] {
    return names.map((name) => this.getVariableStrategy(this.getVariable(name)));
  }

  getDefinitionId(): string {
    return this.definitionPK;
  }

  private getVariable(name: string): FormFlowVariable {
    const variable = this.variables[name];
    if (variable) {
      return variable;
    } else {
      /*! SuppressStringValidation Error messages are not translatable */
      throw new FormFlowError(`Expected variable named ${name}, but did not exist.`);
    }
  }

  private getVariableStrategy(variable: FormFlowVariable): FormFlowVariableStrategy {
    const strategy = this.variableStrategies[variable.Name as keyof FormFlowVariableStrategy];
    if (strategy) {
      return strategy;
    } else {
      return makeDefaultValueStrategy();
    }
  }

  private setArgumentStrategies(parameters: FormFlowVariable[], strategies: FormFlowVariableStrategy[]): void {
    if (parameters.length !== strategies.length) {
      if (strategies.length > parameters.length) {
        strategies.slice(0, parameters.length);
      } else {
        // note that we could reach this point given a bad URI (#/formFlow/x/y where x is the id for a definition without a parameter)
        /*! SuppressStringValidation Error messages are not translatable */
        const message = `Expected matching parameter and argument counts, but was ${parameters.length} and ${strategies.length}, respectively.`;
        throw new FormFlowError(message, {
          type: FormFlowErrorType.NonReportableRuntimeError,
          friendlyMessage: captionService.getString(
            "c48f6c58-f3ec-4b77-8f97-05ec9c81c8c7",
            "An error occurred while running this form-flow. This may be due to an invalid URI."
          ),
        });
      }
    }

    for (let i = 0; i < parameters.length; ++i) {
      const parameterName = parameters[i].Name;
      const strategy = strategies[i];
      this.setVariableStrategy(parameterName, strategy);
    }
  }

  private setOutArgumentStrategies(outArgumentNames: string[], subSession: FormFlowSession): void {
    for (let i = 0; i < outArgumentNames.length; ++i) {
      const outParameterName = subSession.outParameterNames[i];
      const strategy = subSession.getVariableStrategy(subSession.getVariable(outParameterName));

      const outArgumentName = outArgumentNames[i];
      if (outArgumentName) {
        this.setVariableStrategy(outArgumentName, strategy);
      }
    }
  }

  private _getVariableStrategies(names?: string[]): FormFlowVariableStrategy[] {
    const strategies = names ? names.map((n) => this.getVariableStrategy(this.getVariable(n))) : [];
    return strategies;
  }

  async invokeStartActivityAsync(): Promise<ActivityResult | undefined> {
    log.info(`[Form-flow] starting form-flow ${this.definitionPK}`);
    if (this.startActivityCount === 1) {
      const result = await this.invokeActivityAsync(this.startActivityId);
      return result;
    } else {
      /*! SuppressStringValidation Error messages are not translatable */
      throw new FormFlowError("Expected exactly one start activity.");
    }
  }

  async invokeActivityAsync(activityId: string): Promise<ActivityResult | undefined> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    const activity = this.activities[activityId];
    if (activity) {
      const activityInvoker = await getActivityInvokerAsync<FormFlowActivity>(activity.Kind);
      if (activityInvoker) {
        log.info(`[Form-flow] invoking form-flow ${this.definitionPK} - activity ${activity.Id} (${activity.Kind})`);
        /*! SuppressStringValidation Stack frames are not translatable */
        const stackFrame = `in activity ${activity.Id} (${activity.Kind})`;
        return withFormFlowErrorStackAsync(activityInvoker.bind(null, this, activity), stackFrame);
      } else {
        /*! SuppressStringValidation Error messages are not translatable */
        throw new FormFlowError(`Unexpected activity kind ${activity.Kind}.`);
      }
    } else {
      /*! SuppressStringValidation Error messages are not translatable */
      const error = new FormFlowError(`Expected activity with ID ${activityId}, but did not exist.`);
      /*! StartNoStringValidationRegion No captions here! */
      error.getData = (): ErrorData[] => [
        { name: "definitionPK", value: this.definitionPK },
        { name: "activities", value: JSON.stringify(this.activities) },
      ];
      /*! EndNoStringValidationRegion */
      throw error;
    }
  }

  async invokeSubFormFlowAsync(
    formFlowPK: string,
    argumentNames?: string[],
    outArgumentNames?: string[],
    uiContext?: Context
  ): Promise<void> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }
    const definition = await this.formFlowDefinitionProviderFn(formFlowPK);
    const argumentStrategies = this._getVariableStrategies(argumentNames);
    // sub flow takes entity managers, etc by reference, such that this state and mutations are shared
    const subSession = new FormFlowSession(
      definition,
      argumentStrategies,
      this.entityManagers,
      this.entityManagersLock,
      this.formFlowDefinitionProviderFn,
      uiContext || this._uiContext,
      this.sessionVariables,
      this.abortToken,
      this.semaphoreTokens,
      this.rootSession || this
    );

    subSession.addVariableListener((event) => this.notifyVariableListeners(event));
    subSession.addSaveListener((event) => this.notifySaveListenersAsync(event));
    subSession.addGetStateListener((key) => this.getStateAsync(key));
    /*! SuppressStringValidation Stack frames are not translatable */
    const stackFrame = `in sub form-flow ${formFlowPK}`;
    return await withFormFlowErrorStackAsync(async () => {
      await subSession.invokeStartActivityAsync();
      this.setOutArgumentStrategies(outArgumentNames || [], subSession);
    }, stackFrame);
  }

  async withTemporaryVariableAsync<T extends ActivityResult | void>(callback: (key: string) => Promise<T>): Promise<T> {
    /*! SuppressStringValidation Temporary variable names are not translatable */
    const temporaryName = "temp" + newGuid();
    this.variables[temporaryName] = { Name: temporaryName };
    try {
      return await callback(temporaryName);
    } finally {
      delete this.variables[temporaryName];
      delete this.variableStrategies[temporaryName];
    }
  }

  async withTemporaryActivityAsync<T extends ActivityResult | void>(
    activity: FormFlowActivity,
    callback: (key: string) => Promise<T>
  ): Promise<T> {
    /*! SuppressStringValidation property */
    const temporaryId = (activity.Id = "temp" + newGuid());
    this.activities[temporaryId] = activity;
    try {
      return await callback(temporaryId);
    } finally {
      delete this.activities[temporaryId];
    }
  }

  addSaveListener(callback: SaveListener): void {
    this.saveListeners = addWithoutMutating(this.saveListeners, callback);
  }

  removeSaveListener(callback: SaveListener): void {
    this.saveListeners = removeWithoutMutating(this.saveListeners, callback);
  }

  private async notifySaveListenersAsync(event: { entityManager: EntityManager }): Promise<EntitySaveResult> {
    let errorResult;
    for (const listener of this.saveListeners) {
      if (errorResult) {
        return errorResult;
      }
      const saveResult = await listener(event);
      if (saveResult?.isSaved === false) {
        errorResult = saveResult;
      }
    }
    return errorResult ? errorResult : { isSaved: true, error: null };
  }

  addVariableListener(callback: VariableListener): void {
    this.variableListeners = addWithoutMutating(this.variableListeners, callback);
  }

  removeVariableListener(callback: VariableListener): void {
    this.variableListeners = removeWithoutMutating(this.variableListeners, callback);
  }

  private notifyVariableListeners(event: VariableChangeEvent): void {
    this.variableListeners.forEach((x) => x(event));
  }

  addGetStateListener(callback: StateListener): void {
    this.getStateListeners = addWithoutMutating(this.getStateListeners, callback);
  }

  removeGetStateListener(callback: StateListener): void {
    this.getStateListeners = removeWithoutMutating(this.getStateListeners, callback);
  }

  async getStateAsync<T>(key: string): Promise<T[]> {
    const states = await Promise.all(
      this.getStateListeners.map(async (listener) => {
        const state = await listener(key);
        return state as T;
      })
    );
    return states.flatMap((value) => value).filter((value) => value != null);
  }

  abort(): void {
    this._uiContext.abort();
    this.abortToken.isAborted = true;
  }

  async acquireSemaphoreHandleAsync(entityPK: string): Promise<SemaphoreUsage[]> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    if (this.semaphoreTokens.has(entityPK)) {
      return [];
    }

    const semaphoreHandle = await semaphoreProvider.acquireHandleAsync(entityPK);
    if (!semaphoreHandle) {
      return [];
    }

    if (semaphoreHandle.Token) {
      this.semaphoreTokens.set(entityPK, semaphoreHandle.Token);
    }

    return semaphoreHandle.Usages;
  }

  async importEntitiesAsync(entities: Entity[], optionalModelName?: string): Promise<void> {
    if (!Array.isArray(entities)) {
      throw new Error("entities must be an array.");
    }

    const unchangedEntities: Entity[] = [];
    let entityTypeName = "";
    entities.forEach((e) => {
      if (e && e.entityAspect) {
        if (!entityTypeName) {
          entityTypeName = getInterfaceName(e);
        } else if (entityTypeName !== getInterfaceName(e)) {
          throw new Error("Collection must not contain entities with different data types.");
        }
        if (e.entityAspect.entityState.isUnchanged()) {
          unchangedEntities.push(e);
        }
      } else {
        throw new Error("Collection must not contain non-entity objects.");
      }
    });

    if (unchangedEntities.length > 0) {
      const entityManager = await this.getEntityManagerAsync(entityTypeName, optionalModelName);
      await entityManager?.importEntitiesFromOtherAsync(unchangedEntities);
    }
  }

  async disposeAsync(): Promise<void> {
    if (this.semaphoreTokens.size === 0) {
      return await Promise.resolve();
    }

    const tokens = Array.from(this.semaphoreTokens.values());
    return await semaphoreProvider.releaseHandlesAsync(tokens);
  }
}

const knownSaveEntityErrorTypes = new Set([
  SaveEntityErrorType.Concurrency,
  SaveEntityErrorType.KnownRequestFailure,
  SaveEntityErrorType.None,
  SaveEntityErrorType.NotFound,
  SaveEntityErrorType.SaveValidation,
  SaveEntityErrorType.SecurityFailure,
  SaveEntityErrorType.Unauthorized,
]);

async function withFormFlowErrorStackAsync<T>(
  promise: (() => Promise<T>) | void,
  stackFrame: string
): Promise<T | undefined> {
  try {
    if (promise) {
      const result = await promise();
      return result;
    }
    return;
  } catch (error) {
    if (error instanceof FormFlowError) {
      const maxNoOfStackFrames = 10;
      if (!error.activityStack) {
        error.activityStack = [];
      }
      if (error.activityStack.length < maxNoOfStackFrames) {
        error.activityStack.push(stackFrame);
      }
      throw error;
    }
    throw error;
  }
}

export default FormFlowSession;
