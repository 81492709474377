import { getCachedPromise } from "CachedPromise";
import entityMappingService from "EntityMappingService";
import { getODataRoute } from "ODataUtils";
import breeze, { type MetadataStore } from "breeze-client";

export type MetadataFetcher = () => Promise<void>;
export type MetadataStoreWithFetcher = [metadataStore: MetadataStore, metadataFetcher: MetadataFetcher];

const metadataStoreCache: Map<string, MetadataStoreWithFetcher> = new Map();
const routeNameLookup: WeakMap<MetadataStore, string> = new WeakMap();

export async function getMetadataStoreForEntityTypeAsync(entityInterfaceName: string): Promise<MetadataStore>;
export async function getMetadataStoreForEntityTypeAsync(
  entityInterfaceName: string,
  okIfNotFound: boolean
): Promise<MetadataStore | undefined>;
export async function getMetadataStoreForEntityTypeAsync(
  entityInterfaceName: string,
  okIfNotFound: boolean = false
): Promise<MetadataStore | undefined> {
  const routeName = entityMappingService.getRouteName(entityInterfaceName, okIfNotFound);
  if (!routeName) {
    return undefined;
  }

  return await getMetadataStoreForRouteAsync(routeName);
}

export async function getMetadataStoreForRouteAsync(routeName: string): Promise<MetadataStore> {
  const [metadataStore, metadataFetcher] = getMetadataStoreWithFetcher(routeName);
  await metadataFetcher();
  return metadataStore;
}

export function clearMetadataStoreCache(): void {
  metadataStoreCache.clear();
}

/** Get route name for the metadata store. */
export function getRouteNameForMetadataStore(metadataStore: MetadataStore): string {
  const routeName = routeNameLookup.get(metadataStore);
  if (!routeName) {
    throw new Error("Route name is not set for this metadata store");
  }

  return routeName;
}

/**
 * For internal use only.
 * The metadata store will not have any types loaded as metadataFetcher function is not invoked yet.
 *
 * @returns Metadata store and function to load the metadata for this store.
 * */
export function getMetadataStoreWithFetcher(routeName: string): MetadataStoreWithFetcher {
  let result = metadataStoreCache.get(routeName);
  if (!result) {
    const metadataStore = new breeze.MetadataStore();
    const metadataFetcher = getCachedPromise(fetchMetadataAsync.bind(undefined, routeName, metadataStore));
    result = [metadataStore, metadataFetcher];
    metadataStoreCache.set(routeName, result);
    routeNameLookup.set(metadataStore, routeName);
  }

  return result;
}

async function fetchMetadataAsync(routeName: string, metadataStore: MetadataStore): Promise<void> {
  const serviceName = getODataRoute(routeName);
  if (!metadataStore.hasMetadataFor(serviceName)) {
    await metadataStore.fetchMetadata(serviceName);
  }
}
