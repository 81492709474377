import ajaxService, { type AjaxResponse } from "AjaxService";
import { AuthenticationClaimType } from "AuthenticationClaimType";
import { glowAuthenticationResultHeaderName } from "AuthenticationHeaders";
import { AuthenticationResult } from "AuthenticationResult";
import { session } from "AuthenticationService";
import type { AuthenticationSource } from "AuthenticationSource";
import { RenewSessionError } from "Errors";
import log from "Log";
import logonService from "LogonService";
import nativeBridge from "NativeBridge";
import { tryHandleSessionLimitErrorAsync } from "SessionLimitReachedHandler";
import userSession from "UserSession";

interface ErrorInfo {
  canHandle: boolean;
  canRenew?: boolean;
  wasEvicted?: boolean;
}

interface RetrieveHeader {
  (name: string): string | null;
}

export class SessionExpirationService {
  renewSessionPromise?: Promise<void>;

  getErrorHandler(httpError: number, retrieveHeader: RetrieveHeader): SessionExpirationErrorHandler {
    return new SessionExpirationErrorHandler(this, httpError, retrieveHeader);
  }

  async renewSessionAsync(): Promise<void> {
    log.withTag("UserSession").info("SessionExpirationService.renewSessionAsync");

    let promise = this.renewSessionPromise;
    if (!promise) {
      this.renewSessionPromise = promise = (async (): Promise<void> => {
        try {
          await renewSessionCoreAsync();
        } finally {
          this.renewSessionPromise = undefined;
        }
      })();
    }

    await promise;
  }

  setAjaxServiceErrorHandler(): void {
    ajaxService.errorHandler = <T>(
      jqXHR: JQuery.jqXHR,
      tryAgain: () => Promise<T | AjaxResponse<T>>
    ): Promise<T | AjaxResponse<T>> | undefined => {
      const handler = this.getErrorHandler(jqXHR.status, jqXHR.getResponseHeader);
      if (handler.canHandle()) {
        return (async (): Promise<T | AjaxResponse<T>> => {
          await handler.handleAsync();
          return tryAgain();
        })();
      } else {
        return undefined;
      }
    };
  }
}

async function renewSessionCoreAsync(): Promise<void> {
  const { authenticationSource, sessionId } = userSession.sessionData();

  try {
    // If there's no sessionId, the server will give us one.
    // If we supply one, we can continue with our current id.
    await tryBeginSessionAsync(sessionId, authenticationSource);
  } catch (error) {
    try {
      const isHandled = await tryHandleSessionLimitErrorAsync(error);
      if (isHandled) {
        await tryBeginSessionAsync(sessionId, authenticationSource);
      } else {
        throw error;
      }
    } catch (error) {
      throw new RenewSessionError(
        /*! SuppressStringValidation Exception message */
        "Session renewal failed.",
        AuthenticationResult.SessionExpired,
        error instanceof Error ? error : undefined
      );
    }
  }
}

async function tryBeginSessionAsync(
  sessionId: string | null | undefined,
  authenticationSource: AuthenticationSource | undefined
): Promise<void> {
  const result = await session.beginAsync(
    AuthenticationClaimType.LocalToken,
    await nativeBridge.getSessionTypeAsync(),
    sessionId
  );
  try {
    await logonService.logOnWithUserInfoAsync(result.userInfo, authenticationSource);
  } catch (error) {
    await destroySessionAsync();
    throw error;
  }
}

async function destroySessionAsync(): Promise<void> {
  try {
    await session.destroyAsync();
  } catch {
    /* empty */
  }
}

export class SessionExpirationErrorHandler {
  private readonly service: SessionExpirationService;
  private errorInfo: ErrorInfo;

  constructor(service: SessionExpirationService, httpError: number, retrieveHeader: RetrieveHeader) {
    this.service = service;
    this.errorInfo = getErrorInfo(httpError, retrieveHeader);
  }

  canHandle(): boolean {
    return this.errorInfo.canHandle;
  }

  async handleAsync(): Promise<void> {
    const { errorInfo } = this;
    if (!errorInfo.canHandle) {
      throw new Error("Cannot handle non-expired session.");
    }

    if (!errorInfo.canRenew) {
      throw new RenewSessionError(
        /*! SuppressStringValidation Exception message */
        "Session cannot be renewed.",
        errorInfo.wasEvicted ? AuthenticationResult.SessionEvicted : AuthenticationResult.SessionExpired
      );
    }

    await this.service.renewSessionAsync();
  }
}

function getErrorInfo(httpError: number, retrieveHeader: RetrieveHeader): ErrorInfo {
  if (httpError === 401 /* HTTP Unauthorized */) {
    const headerValue = retrieveHeader(glowAuthenticationResultHeaderName);

    if (headerValue) {
      const sessionExpiryIndicatorAuthenticationResults = [
        AuthenticationResult.SessionExpired,
        AuthenticationResult.SessionNotFound,
        AuthenticationResult.CredentialsNotProvided,
        AuthenticationResult.SessionLimitReached,
      ];
      const isSessionExpiry = sessionExpiryIndicatorAuthenticationResults.some(
        (x) => headerValue === AuthenticationResult[x]
      );
      if (isSessionExpiry) {
        return { canHandle: true, canRenew: true };
      }

      if (headerValue !== AuthenticationResult[AuthenticationResult.Success]) {
        const wasEvicted = headerValue === AuthenticationResult[AuthenticationResult.SessionEvicted];
        return { canHandle: true, canRenew: false, wasEvicted };
      }
    }
  }

  return { canHandle: false };
}

export default new SessionExpirationService();
