import global from 'Global';
import browser from 'Browser';
import log from 'Log';
import nativeBridge from 'NativeBridge';

class ErrorReportingHelper {
	getReportableErrors(error) {
		const result = [];

		while (error !== null && typeof error !== 'undefined') {
			const data = error.getData ? error.getData() : null;
			const errorInfo = {
				message: error.message || jsonStringifySafe(error),
				data: getErrorData(data || []),
				stack: error.stack || '',
			};

			errorInfo.data.push({
				name: 'ErrorType',
				value: error.name || error.constructor.name,
			});

			result.push(errorInfo);

			if (error.cause instanceof Error) {
				error = error.cause;
				continue;
			}

			if (error.cause) {
				errorInfo.data.push({
					name: 'ErrorCause',
					value: jsonStringifySafe(error.cause)
				});
			}

			break;
		}

		if (result.length) {
			addEnvironmentData(result[0]);
		}

		return result;
	}
}

function addEnvironmentData(error) {
	const data = error.data;
	const browserInfo = browser.getBrowserInfo();

	const additionalData = [
		{
			name: 'VersionNumber',
			value: global.versionNumber,
		},
		{
			name: 'BrowserName',
			value: browserInfo.name,
		},
		{
			name: 'BrowserVersion',
			value: browserInfo.version,
		},
		{
			name: 'UserAgent',
			value: global.userAgent,
		},
		...getLogs(),
		{
			name: 'BrowserTimeOfException',
			value: new Date().toISOString(),
		},
	];

	if (nativeBridge.nativeClientVersion) {
		additionalData.push({
			name: 'NativeClientVersion',
			value: nativeBridge.nativeClientVersion,
		});
	}

	if (Array.isArray(data)) {
		error.data = additionalData.concat(error.data);
	} else {
		error.data = additionalData;
	}
}

function getLogs() {
	return Object.entries(log.getLogs())
		.sort((a, b) => a[0].localeCompare(b[0]))
		.map(([tag, logs]) => ({
			name: `BrowserLogs_${tag}`,
			value: jsonStringifySafe(logs),
		}));
}

function jsonStringifySafe(obj) {
	try {
		return JSON.stringify(obj);
	} catch (err) {
		return null;
	}
}

function getErrorData(data) {
	return data.map((item) => ({ ...item, value: trimValue(item.value) }));
}

function trimValue(value) {
	const maxLength = 100000;
	if (value != null) {
		const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
		if (stringValue.length > maxLength) {
			value = stringValue.substring(0, maxLength) + `...more (${stringValue.length} chars)`;
		}
	}
	return value;
}

export default new ErrorReportingHelper();
