import captionService from "CaptionService";
import { NestedError } from "NestedError";
import type { Entity } from "breeze-client";

/*! StartNoStringValidationRegion enum values */
export enum SaveEntityErrorType {
  Concurrency = "Concurrency",
  DatabaseIsUpgrading = "DatabaseIsUpgrading",
  KnownRequestFailure = "KnownRequestFailure",
  NetworkFailure = "NetworkFailure",
  None = "None",
  NotFound = "NotFound",
  SaveValidation = "SaveValidation",
  SecurityFailure = "SecurityFailure",
  Unauthorized = "Unauthorized",
  UnknownRequestFailure = "UnknownRequestFailure",
}
/*! EndNoStringValidationRegion */

export interface SaveEntityErrorOptions {
  entity?: Entity;
  friendlyMessageOverride?: string;
  cause?: Error;
  message?: string;
}

export class SaveEntityError extends NestedError {
  override readonly name = "SaveEntityError";
  readonly entity?: Entity;
  readonly friendlyMessageOverride?: string;
  readonly type: SaveEntityErrorType;

  constructor(errorType: SaveEntityErrorType, options?: SaveEntityErrorOptions) {
    super(options?.message, options?.cause);

    this.type = errorType;
    this.entity = options?.entity;
    this.friendlyMessageOverride = options?.friendlyMessageOverride;
  }

  friendlyCaption(): string {
    return captionService.getString("254463cb-88b7-4561-8744-bbd503365acc", "Save Failed");
  }

  friendlyMessage(): string {
    if (this.friendlyMessageOverride) {
      return this.friendlyMessageOverride;
    }
    return friendlyMessageFromErrorType(this.type);
  }
}

export function friendlyMessageFromErrorType(errorType: SaveEntityErrorType): string {
  switch (errorType) {
    case SaveEntityErrorType.None:
      return "";

    case SaveEntityErrorType.Concurrency:
      return captionService.getString(
        "ef650e61-22b5-4b46-82ad-b73981aa710d",
        "The save failed because of changes made by another user while you were making changes."
      );

    case SaveEntityErrorType.DatabaseIsUpgrading:
      return captionService.getString(
        "75181fe2-3dca-47ae-9961-a5ea3aee8603",
        "The save failed because the database is upgrading. Please try again in a few minutes or contact your system administrator if this error persists."
      );

    case SaveEntityErrorType.NetworkFailure:
      return captionService.getString(
        "b265d7fe-a946-45e7-9227-095c24f5719d",
        "Unable to connect to the server. Please check your network connection and try again or contact your system administrator if the error persists."
      );

    case SaveEntityErrorType.NotFound:
      return captionService.getString(
        "94c81e0a-30bc-4286-aee8-49f816738db0",
        "The save failed because the record no longer exists. It may have been deleted."
      );

    case SaveEntityErrorType.KnownRequestFailure:
    case SaveEntityErrorType.UnknownRequestFailure:
      return captionService.getString(
        "f41b2df9-6a6a-4c65-8b13-9b790ac121fb",
        "An error occurred while processing this request. Please try again or contact your system administrator if the error persists."
      );

    case SaveEntityErrorType.SaveValidation:
      return captionService.getString(
        "27ea6f8c-6393-406f-8dae-04c41b092b5a",
        "The save failed due to validation errors."
      );

    case SaveEntityErrorType.SecurityFailure:
      return captionService.getString(
        "f74b5231-cdbd-4c3a-aca4-43e26c8a5e43",
        "You do not have permission to perform this action. Contact your system administrator to be granted appropriate permissions."
      );

    case SaveEntityErrorType.Unauthorized:
      return captionService.getString("1e4c66de-6590-4b17-b20a-8a70da944fd9", "Access is denied");
  }
}
