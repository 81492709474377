import global from 'Global';
import userSession from 'UserSession';
import { addressField } from 'VuePlugins/Captions/AddressFieldCaptions.ts';
import { approvalBanners } from 'VuePlugins/Captions/ApprovalBannersCaptions';
import { autoRefresh } from 'VuePlugins/Captions/AutoRefreshCaptions';
import { changeBranchDepartment } from 'VuePlugins/Captions/ChangeBranchDepartmentCaptions';
import { changePassword } from 'VuePlugins/Captions/ChangePasswordCaptions';
import { choose } from 'VuePlugins/Captions/ChooseCaptions';
import { colorScheme } from 'VuePlugins/Captions/ColorSchemeCaptions';
import { conversation } from 'VuePlugins/Captions/ConversationCaptions';
import { customFields } from 'VuePlugins/Captions/CustomFieldsCaptions';
import { dateField, dateOffset, dateTimeField, timeField } from 'VuePlugins/Captions/DateTimeFieldCaptions';
import { dialog } from 'VuePlugins/Captions/DialogCaptions';
import { edoc } from 'VuePlugins/Captions/EDocCaptions';
import { errorReporting } from 'VuePlugins/Captions/ErrorReportingCaptions.ts';
import { fileAttachment } from 'VuePlugins/Captions/FileAttachmentCaptions';
import { filters } from 'VuePlugins/Captions/FiltersCaptions.ts';
import { getNumberFormat } from 'NumericService';
import { headers } from 'VuePlugins/Captions/HeadersCaptions';
import { imagePicker } from 'VuePlugins/Captions/ImagePickerCaptions';
import { jobAddress } from 'VuePlugins/Captions/JobAddressCaptions.ts';
import { layouts } from 'VuePlugins/Captions/LayoutsCaptions.ts';
import { lookup } from 'VuePlugins/Captions/LookupCaptions';
import { map } from 'VuePlugins/Captions/MapCaptions.ts';
import { menu } from 'VuePlugins/Captions/MenuCaptions';
import { notes } from 'VuePlugins/Captions/NotesCaptions';
import { passwordField } from 'VuePlugins/Captions/PasswordFieldCaptions';
import { schedule, weekDay } from 'VuePlugins/Captions/ScheduleCaptions';
import { searchControl } from 'VuePlugins/Captions/SearchControlCaptions.ts';
import { searchDataTable } from 'VuePlugins/Captions/SearchDataTableCaptions';
import { searchList } from 'VuePlugins/Captions/SearchListCaptions';
import { themeConfiguration } from 'VuePlugins/Captions/ThemeConfigurationCaptions';
import { trackingTimeline } from 'VuePlugins/Captions/TrackingTimelineCaptions';
import { workflow } from 'VuePlugins/Captions/WorkflowCaptions';
import dateFormatter from 'VuePlugins/Formatters/DateFormatter';
import dateTimeFormatter from 'VuePlugins/Formatters/DateTimeFormatter';
import Vue from 'vue';
import WtgMaterialUi from 'wtg-material-ui/WtgMaterialUi';
import supplyTheme from 'wtg-material-ui/theme/presets/supply';
import cargoWiseOneTheme from 'wtg-material-ui/theme/presets/cargoWiseOne';

Vue.use(WtgMaterialUi);

if (!global.materialDesign) {
	supplyTheme.controls.input = { attach: true };
}

/* For adding a new caption property to the list below,
 * define it in a new file and import the file instead of defining the captions inline.
 * This helps adding some context (file name) for the translator to relate to the caption being translated.
 * */
const captions = {
	addressField,
	approvalBanners,
	autoRefresh,
	changePassword,
	changeBranchDepartment,
	choose,
	colorScheme,
	conversation,
	customFields,
	dateField,
	dateOffset,
	dateTimeField,
	dialog,
	edoc,
	errorReporting,
	fileAttachment,
	filters,
	headers,
	jobAddress,
	layouts,
	lookup,
	map,
	menu,
	notes,
	imagePicker,
	passwordField,
	schedule,
	searchControl,
	searchDataTable,
	searchList,
	themeConfiguration,
	timeField,
	trackingTimeline,
	weekDay,
	workflow,
};

function captionProvider(language, key, params = []) {
	let node;
	if (key) {
		const path = key.split('.');
		node = captions;
		while (node && (key = path.shift())) {
			node = node[key];
		}
	}
	return node && node(params);
}

const frameworkVersion = global.portalInfo?.frameworkVersion;
const theme = getThemeForFrameworkVersion();
/*! StartNoStringValidationRegion wtg library initialization */
const wtgMaterialUi = new WtgMaterialUi({
	dark: global.portalInfo.defaultThemeAppearance === 'Dark',
	language: {
		current: global.languageCode,
		captionProvider,
		dateFormatter,
		dateTimeFormatter,
	},
	storage: {
		namespace: global.moduleName,
	},
	theme,
	themeConfiguration: (frameworkVersion >= 4 && global.themeOptions && global.themeOptions.themeVersion === theme.themeVersion) ? global.themeOptions : {},
	frameworkVersion,
	supplyBetaComponents: global.featureFlags.supplyBetaComponents
});
/*! EndNoStringValidationRegion */

function getThemeForFrameworkVersion() {
	const frameworkVersion = global.portalInfo?.frameworkVersion;
	if (frameworkVersion >= 5) {
		const portalTheme = { ...supplyTheme };
		const portalThemeConfiguration = global.portalInfo.themeConfiguration;

		if (portalThemeConfiguration?.logoLight) {
			portalTheme.logoLightImage = portalThemeConfiguration.logoLight;
			portalTheme.logoLightImageFileType = portalThemeConfiguration.logoLightType;
		}

		if (portalThemeConfiguration?.logoDark) {
			portalTheme.logoDarkImage = portalThemeConfiguration.logoDark;
			portalTheme.logoDarkImageFileType = portalThemeConfiguration.logoDarkType;
		}

		if (portalThemeConfiguration?.brandColor) {
			portalTheme.colors = {
				...supplyTheme.colors,
				light: {
					...supplyTheme.colors.light,
					app: {
						...supplyTheme.colors.light.app,
						navigationDrawer: {
							background: portalThemeConfiguration.brandColor,
							dark: !!portalThemeConfiguration.brandColorDark,
						}
					}
				}
			};
		}

		return portalTheme;
	}
	else if (frameworkVersion >= 4) {
		return cargoWiseOneTheme;
	}
	else {
		return supplyTheme;
	}
}

function isLoggedOnHandler(newValue) {
	wtgMaterialUi.language.current = newValue ? userSession.sessionData().languageCode : global.languageCode;
	const numberFormat = getNumberFormat();
	wtgMaterialUi.language.numericLocale = {
		decimalCharacter: numberFormat.decimalSeparator,
		digitGroupSeparator: numberFormat.groupSeparator,
		digitalGroupSpacing: numberFormat.digitGrouping,
	};
}

userSession.onIsLoggedOnChanged(isLoggedOnHandler);
isLoggedOnHandler(userSession.isLoggedOn());

export default wtgMaterialUi;
