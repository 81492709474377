import type { AjaxError } from "AjaxService";
import captionService from "CaptionService";
import { type ErrorData, FormFlowErrors, FormFlowSerializationError } from "Errors";
import global from "Global";
import { NestedError } from "NestedError";

export enum FormFlowErrorType {
  ConfigurationError = "ConfigurationError",
  NonReportableRuntimeError = "NonReportableRuntimeError",
  ReportableRuntimeError = "ReportableRuntimeError",
}

type FormFlowErrorResponse = {
  ExceptionType: string;
  Message: string;
};

function isFormFlowSerializationError(errorResponse: FormFlowErrorResponse): boolean {
  if (errorResponse) {
    return errorResponse.ExceptionType === FormFlowErrors.SerializationError;
  }
  return false;
}

export function handleFormFlowSerializationError(error: AjaxError): void {
  if (error.status === 400) {
    const errorResponse = error.getErrorResponse() as FormFlowErrorResponse;
    if (isFormFlowSerializationError(errorResponse)) {
      let errorMessage: string;
      if (global.isPreviewMode()) {
        errorMessage = captionService.getString(
          "eacf27fd-6192-4fc6-a8c7-f012c962ac44",
          "An unexpected error occurred while serializing the form-flow infos. This may be due to invalid configuration.\r\n{0}",
          errorResponse.Message
        );
      } else {
        errorMessage = captionService.getString(
          "9946410c-1208-480c-a12f-1f9d708dfca3",
          "An unexpected error occurred while serializing the form-flow infos. The error has been automatically reported."
        );
      }
      throw new FormFlowSerializationError(errorMessage);
    }
  }
}

export interface FormFlowErrorOptions {
  friendlyCaption?: string;
  friendlyMessage?: string;
  cause?: Error;
  type?: FormFlowErrorType;
}

export class FormFlowError extends NestedError {
  override readonly name = "FormFlowError";
  readonly friendlyCaption?: string;
  readonly friendlyMessage: string;
  readonly type: FormFlowErrorType;
  activityStack?: string[];
  getData?: () => ErrorData[];

  constructor(message: string, options?: FormFlowErrorOptions) {
    super(message, options?.cause);

    this.friendlyCaption = options?.friendlyCaption;
    this.friendlyMessage = options?.friendlyMessage || message;
    this.type = options?.type ?? FormFlowErrorType.ConfigurationError;
  }

  get isConfigurationError(): boolean {
    return this.type === FormFlowErrorType.ConfigurationError;
  }

  get isReportable(): boolean {
    return this.type === FormFlowErrorType.ConfigurationError || this.type === FormFlowErrorType.ReportableRuntimeError;
  }
}
