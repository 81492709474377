import captionService from 'CaptionService';

export const themeConfiguration = {
	appBackground: () => captionService.getString('b50a1bae-6b35-482f-992b-a21ba3f0284f', 'Application background'),
	appBar: () => captionService.getString('4c582879-9a6d-4154-a4df-8a8b3532d345', 'App bar'),
	close: () => captionService.getString('e11f9410-ce63-4603-a4ca-1e3e1a781716', 'Close'),
	color: () => captionService.getString('57135ef4-7de6-49cc-a0f0-2c2e7652032d', 'Color'),
	colors: () => captionService.getString('f796dffb-d4b1-4298-92d3-cc098996780d', 'Colors'),
	contentBackground: () => captionService.getString('ad69163f-b1e7-4552-85d8-cd34d2a12083', 'Content background'),
	copyThemeFrom: () => captionService.getString('b72d209f-99ef-4009-aa6f-4cb2090ec905', 'Copy theme from'),
	dark: () => captionService.getString('31f1a5bd-9860-4023-b708-e9aa051fed3d', 'Dark'),
	darkAppBar: () => captionService.getString('685ef3f8-aa50-4958-8859-726078c3a1ff', 'Dark'),
	drawerColor: () => captionService.getString('f1024976-9e61-4871-9fd4-f9cfb2b88e08', 'Drawer background'),
	portal: () => captionService.getString('decb220b-eaeb-47e9-b7ae-db8f28c56c0e', 'Portal'),
	panelCaptionColor: () => captionService.getString('8e658e7c-998c-4987-ae48-b621992b7ab5', 'Panel caption'),
	primary: () => captionService.getString('4ac084c5-5a53-461c-a3a2-cc3f2e04b09e', 'Primary'),
	lightTheme: () => captionService.getString('c47491ed-9be6-48a6-afd1-f9baaada583f', 'Light theme'),
	logo: () => captionService.getString('989233b0-bb6c-423b-a066-7801ce0eafc4', 'Logo'),
	logoColor: () => captionService.getString('991f729f-395a-4fc9-93df-8e27bec046f5', 'Logo background'),
	logoDarkDescription: () => captionService.getString('0cbdde16-9080-438e-ac5f-1506c1e4cc24', 'Dark logo'),
	logoLightDescription: () => captionService.getString('c0759b34-d63a-45aa-9fb5-8431479296a2', 'Light logo'),
	logos: () => captionService.getString('41302576-58a7-41ec-af67-02f0bdb31d29', 'Logos'),
	navDrawer: () => captionService.getString('dcbfbc81-3ee0-4c19-9532-8f2010791393', 'Navigation drawer'),
	darkTheme: () => captionService.getString('ab421af6-d8c9-415a-a359-a204f9a2ad16', 'Dark theme'),
	reset: () => captionService.getString('7a3460f2-de9d-412f-833f-b0e5b88eec85', 'Reset'),
	save: () => captionService.getString('56bf844c-4fa8-4de8-bd85-a4a9b7ff989f', 'Save'),
	select: () => captionService.getString('fdf7b6e9-03c5-4c32-a284-9441294f73b4', 'Select logo'),
	title: () => captionService.getString('a0a8cf66-1b53-4521-be7e-ec04d84fbe70', 'Theme configuration'),
	presets: () => captionService.getString('9f2cc88a-66be-47a2-a133-81416e2c33e3', 'Themes'),
	secondary: () => captionService.getString('3b920ae9-bc81-47f7-be6a-e612ca7d46e8', 'Secondary'),
	themeColors: () => captionService.getString('a4f844d7-ed87-4787-8941-7b5f90d116f2', 'Theme colors'),
	fileSizeTooLarge: () => captionService.getString('5074930f-3edb-422d-ba95-ecda45566718', 'File size too large'),
};
