export enum AuthenticationResult {
  AbnormalFailure = 255,
  Success = 0,
  AccountLocked = 1,
  PasswordChangeRequired = 2,
  LogonDetailsIncorrect = 3,
  SessionExpired = 4,
  CredentialsNotProvided = 5,
  WebAccessNotEnabled = 6,
  SessionNotFound = 7,
  CouldNotCalculateEnterpriseContext = 10,
  ThirdPartyUserValidationRequired = 11,
  ThirdPartyUserValidationRejected = 12,
  ThirdPartyUserValidationUnavailable = 13,
  LoginDisabled = 14,
  UpstreamAuthenticationServerUnavailable = 15,
  LocalPasswordMustBeReset = 16,
  ContextChangeRequired = 17,
  ThirdPartyUserMissing = 18,
  ThirdPartyUserAmbiguous = 19,
  ContextInsufficientPrivileges = 20,
  LoginRateLimited = 21,
  SessionLimitReached = 22,
  SessionEvicted = 23,
}
