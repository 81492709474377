import bindingEvaluator from "BindingEvaluator";
import type { Entity } from "BreezeExtensions";
import { newGuid } from "GuidGenerator";
import readonlyEvaluator from "ReadOnlyEvaluator";
import type { PropertyReadOnly } from "RulesetMetadata";
import { useBindingContext } from "VueHooks/BindingContextHook";
import { useKnockoutContext } from "VueHooks/KnockoutContextHook";
import ko from "knockout";
import { inject, onUnmounted, provide, ref, watch, type Ref } from "vue";
import { toValue, type MaybeRefOrGetter } from "vue3-polyfill";

const readonlySymbol = newGuid();

export function useReadonly(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  value: PropertyReadOnly,
  allowUpdateOnReadonlyParent = false
): Ref<boolean> {
  const isReadonlyRef = ref(false);
  const koContext = useKnockoutContext();
  const isParentReadonlyRef = inject<Ref<boolean> | undefined>(readonlySymbol, undefined);
  const bindingContextRef = useBindingContext();

  const refs: Ref<boolean | Entity>[] = [];
  if (isParentReadonlyRef) {
    refs.push(isParentReadonlyRef);
  }

  if (bindingContextRef) {
    refs.push(bindingContextRef);
  }

  let computed: ko.Computed;
  watch(
    [(): string | undefined => toValue(bindingPath), (): (boolean | Entity)[] => refs.map((r) => r.value)],
    ([bindingPath]) => {
      computed && computed.dispose();
      if (!allowUpdateOnReadonlyParent && isParentReadonlyRef && isParentReadonlyRef.value === true) {
        isReadonlyRef.value = true;
        return;
      }

      if (!bindingContextRef) {
        isReadonlyRef.value = value !== false;
        return;
      }

      computed = ko.computed(() => {
        const dataItem = bindingPath
          ? bindingEvaluator.getUltimateDataItem<Entity>(koContext, bindingContextRef.value, bindingPath)
          : bindingContextRef.value;
        const propertyName = bindingPath && bindingEvaluator.getPropertyName(bindingPath);
        isReadonlyRef.value = readonlyEvaluator.isReadOnly(
          koContext,
          dataItem,
          value,
          propertyName,
          allowUpdateOnReadonlyParent
        );
      });
    },
    {
      immediate: true,
    }
  );

  onUnmounted(() => computed && computed.dispose());
  provide(readonlySymbol, isReadonlyRef);
  return isReadonlyRef;
}
