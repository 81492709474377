import Promise from 'bluebird';
import global from 'Global';
import $ from 'jquery';
import log from 'Log';
import { loadNativeBridgeAsync } from 'ModuleLoader';
import shortcutService from 'ShortcutService';

function NativeBridge() {
	this.commands = {
		selectPictureCommand: 'selectPicture',
		startTrackingCommand: 'startTracking',
		stopTrackingCommand: 'stopTracking',
		isScrollableCommand: 'nativeIsScrollable',
		webAppReadyCommand: 'WebAppReady',
		setLockTextZoomCommand: 'setLockTextZoom',
	};

	this.callbackDeferreds = {};
	this.defaultOptions = {
		callback: 'glowNativeBridgePerformCommandCallback',
		errorcallback: 'glowNativeBridgeErrorCallback',
		scanEventKey: 'glowScanEvent',

		setupKey: 'glowSetupNativeBridge', // This must match the code in the iOS, Android and Windows Phone clients
		initializedKey: 'glowNativeBridgeIsInitialized', // This must match the code in the Windows Phone client
		setupPromiseKey: 'glowNativeBridgeSetupPromise',
		setupPromiseResolveCallbackKey: 'glowNativeBridgeSetupPromiseResolveCallback',
	};

	this.providers = {
		Android: 'AndroidNativeBridgeProvider',
		IOS: 'IOSNativeBridgeProvider',
		Electron: 'ElectronNativeBridgeProvider',
		UWP: 'UWPNativeBridgeProvider'
	};

	this.provider = null;
	this.providerName = null;
	this.initializationObject = null;
	this._isLoadingNativeBridge = false;

	this._webAppIsReadyDeferred = Promise.deferred();

	this._nativeClientSessionType = null;

	this.nativeClientVersion = null;
	this.nativeClientOsBuildVersion = null;
	this.nativeClientSerialNumber = null;
	this.nativeClientDeviceType = null;
	this.nativeClientDeviceModel = null;
}

NativeBridge.prototype.isOpen = function () {
	return this.provider && this.provider.isOpen();
};

NativeBridge.prototype.isScanProvider = function () {
	return this.isOpen() && this.provider.isScanProvider && this.provider.isScanProvider();
};

function newDeferredForCommand(bridge, command) {
	const existingDeferred = bridge.callbackDeferreds[command];
	if (existingDeferred) {
		existingDeferred.reject(`Attempted to run ${command} twice`);
		bridge.callbackDeferreds[command] = null;
	}

	const deferred = Promise.deferred();
	bridge.callbackDeferreds[command] = deferred;
	return deferred;
}

NativeBridge.prototype.deferredForCommand = (bridge, command) => {
	return bridge.callbackDeferreds[command];
};

NativeBridge.prototype.clearDeferredForCommand = (bridge, command) => {
	bridge.callbackDeferreds[command] = null;
};

NativeBridge.prototype.performCommandAsync = function (command, parameters) {
	if (this.isOpen()) {
		const callbackDeferred = newDeferredForCommand(this, command);

		this.provider.pushMessage(command, parameters, this.defaultOptions);

		return callbackDeferred.promise();
	}

	return Promise.resolve();
};

NativeBridge.prototype.errorCallback = function (command, argument) {
	const callbackDeferred = this.deferredForCommand(this, command);
	if (callbackDeferred) {
		callbackDeferred.reject(argument);
		log.error(`An error occurred while running command "${command}" through native bridge: ${argument}`);
		this.clearDeferredForCommand(this, command);
	}
};

NativeBridge.prototype.performCommandCallback = function (command, argument) {
	const callbackDeferred = this.deferredForCommand(this, command);
	if (callbackDeferred) {
		callbackDeferred.resolve(argument);
		this.clearDeferredForCommand(this, command);
	}
};

NativeBridge.prototype.scanEvent = (data, type) => {
	const $focused = $(':focus');
	$focused.trigger('blur');

	let $element = shortcutService.trigger('ctrl+[');

	if (!$element) {
		if ($focused.length) {
			$element = $focused;
		}
	}

	if ($element && $element.length && !$element.attr('readonly')) {
		data = sanitize(data);

		if ($element.attr('data-settable')) {
			$element.component('setValue', data);
		} else {
			$element.val(data);
			$element.trigger('change');
		}
		setLastScannedElement($element);

		//We need to check for both because Zebra reports it as EAN128 but Datalogic reports it as GS1_128
		if (type === 'EAN128' || type === 'GS1_128') {
			shortcutService.trigger('ctrl+]');
		}
		else {
			shortcutService.trigger('ctrl+\\');
		}
	}
};

function sanitize(data) {
	return typeof data === 'string' ? data.replace(/\0/g, '') : data;
}

function setLastScannedElement($element) {
	$('[data-last-scanned]').removeAttr('data-last-scanned');
	$element.attr('data-last-scanned', true);
}

NativeBridge.prototype.onCommandCompleted = function (command, argument) {
	this.performCommandCallback(command, argument);
};

NativeBridge.prototype.onError = function (command, argument) {
	this.errorCallback(command, argument);
};

NativeBridge.prototype.setupForClient = function (client, version, osBuildVersion, serialNumber, deviceType, deviceModel, sessionType) {
	const providerName = this.providers[client];
	if (providerName) {
		if (isLoadingOrLoaded(this)) {
			return;
		}
		this._isLoadingNativeBridge = true;

		this._nativeClientSessionType = sessionType;

		this.nativeClientVersion = version;
		this.nativeClientOsBuildVersion = osBuildVersion;
		this.nativeClientSerialNumber = serialNumber;
		this.nativeClientDeviceType = deviceType;
		this.nativeClientDeviceModel = deviceModel;
		loadNativeBridgeAsync(providerName)
			.then((provider) => {
				if (this.initializationObject) {
					this.initializationObject[this.defaultOptions.initializedKey] = true;
				}
				this._isLoadingNativeBridge = false;
				this.provider = provider;
				this._webAppIsReadyDeferred.promise()
					.then(() => {
						if (provider.setupForClientAsync) {
							return provider.setupForClientAsync();
						}
					})
					.then(() => {
						return Promise.all([
							this.performCommandAsync(this.commands.webAppReadyCommand),
							this.performCommandAsync(this.commands.setLockTextZoomCommand, global.materialDesign)
						]);
					});
			}).finally(() => {
				this._isLoadingNativeBridge = false;
			});

		this.initializationObject[this.defaultOptions.setupPromiseResolveCallbackKey]?.();
	}
};

function isLoadingOrLoaded(self) {
	return self._isLoadingNativeBridge
		|| (self.initializationObject && self.initializationObject[self.defaultOptions.initializedKey]);
}

NativeBridge.prototype.getSessionTypeAsync = async function () {
	await this.initializationObject[this.defaultOptions.setupPromiseKey];

	return this._nativeClientSessionType;
};

NativeBridge.prototype.webAppIsReady = function () {
	this._webAppIsReadyDeferred.resolve();
};

NativeBridge.prototype.initialize = function (obj) {
	const setupKey = this.defaultOptions.setupKey;

	this.initializationObject = obj;
	this.initializationObject[setupKey] = this.setupForClient.bind(this);

	this.initializationObject[this.defaultOptions.callback] = this.performCommandCallback.bind(this);
	this.initializationObject[this.defaultOptions.errorcallback] = this.errorCallback.bind(this);
	this.initializationObject[this.defaultOptions.scanEventKey] = this.scanEvent.bind(this);

	this._isLoadingNativeBridge = false;
};

NativeBridge.prototype.isCurrentVersionSupported = function () {
	if (!this.provider) {
		return true;
	}

	return this.provider.isSupportedVersion(this.nativeClientVersion);
};

export default new NativeBridge();