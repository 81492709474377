import { inject, provide } from "vue";

export const formInfoInjectionKey = Symbol("formInfo");

export function useFormInfo(): FormInfo | undefined {
  return inject<FormInfo | undefined>(formInfoInjectionKey, undefined);
}

export function setFormInfo(value: FormInfo): void {
  provide(formInfoInjectionKey, value);
}

export type FormInfo = {
  id?: string;
  inDialog?: boolean;
};
