import captionPresentationService from "CaptionPresentationService";
import dialogServiceProvider from "DialogServiceProvider";
import documentWrapper from "DocumentWrapper";
import vueFactory from "VueFactory";
import widgetService from "WidgetService";
import ko from "knockout";
import type { Dialog, DialogOptions } from "./DialogServiceTypes";

/*
This module contains Dialog Service functions for setting up the UI.
These functions rely on a browser environment or the whole bundle of
widget and components to be available to function correctly.
Having a separate module helps us replace them with a mock for tests.
*/

export function blurActiveElement(): void {
  const activeElement = documentWrapper.getActiveElement();
  if (activeElement) {
    $(activeElement).trigger("blur");
  }
}

export async function setUpDialogUIAsync(dialog: Dialog, options: DialogOptions): Promise<void> {
  const pageExtensions = options.bindingContext?.$root.pageExtensions;
  const contentContainer = dialog.domElements.$body[0];
  const instance = await vueFactory.createVueInstanceAsync({
    contentContainer,
    validationRegistrar: pageExtensions && pageExtensions.validationRegistrar,
    knockoutContext: options.bindingContext,
    bindingContext: options.bindingContext?.$root,
    requiresParent: true,
    formInfo: { inDialog: true },
  });

  if (instance) {
    dialog.domElements.$body = dialog.$dialog.find(instance.$el);
  }

  await widgifyBodyAsync(dialog, options);

  captionPresentationService.resizeCaptions(dialog.domElements.$body);
  ko.applyBindings(options.bindingContext, dialog.domElements.$innerWrap[0]);

  setUpFormSubmit(dialog);

  if (dialog.domElements.$body.html().length) {
    dialog.domElements.$body.fadeTo(400, 1, () => widgetService.utils.setInitialFocus(dialog.$dialog));
  }
}

async function widgifyBodyAsync(dialog: Dialog, options: DialogOptions): Promise<void> {
  await Promise.all([options.bodyDeferred, options.footerDeferred, options.headerButtonsDeferred]);
  await dialogServiceProvider.createWidgetsAsync(dialog.$dialog);
}

function setUpFormSubmit(dialog: Dialog): void {
  const $form = dialog.$dialog.find("form");
  $form.on("submit", (e) => {
    e.preventDefault();
    e.stopPropagation();
  });
}
