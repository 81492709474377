import { DeferredPromise } from "DeferredPromise";
import { computed, ignoreDependencies, isObservable, observable, type Computed, type Observable } from "knockout.base";

export interface ExtendedComputed<T> extends Computed<T> {
  loaded: Observable<boolean | undefined>;
  refresh: () => void;
}

export function lazyObservable<TValue, TContext>(
  callback: (this: TContext | undefined, value?: TValue) => void,
  context?: TContext,
  initialValue?: TValue
): ExtendedComputed<TValue> {
  const value = observable(initialValue);
  return lazyComputed(callback, value, context);
}

export function lazyComputed<TValue, TContext>(
  callback: (this: TContext | undefined, value?: TValue) => void,
  value: Observable<TValue | undefined>,
  context?: TContext
): ExtendedComputed<TValue> {
  const result = computed({
    read() {
      //if it has not been loaded, execute the supplied function
      if (!result.loaded.peek()) {
        ignoreDependencies(() => {
          callback.call(context, value());
        });
      }
      //always return the current value
      return value();
    },
    write(newValue: TValue | undefined) {
      //indicate that the value is now loaded and set it
      result.loaded(true);
      value(newValue);
    },
    deferEvaluation: true, //do not evaluate immediately when created
  }) as ExtendedComputed<TValue>;

  //expose the current state, which can be bound against
  result.loaded = observable<boolean>();

  //load it again
  result.refresh = (): void => {
    result.loaded(false);
    callback.call(context);
  };

  return result;
}

export async function waitForValueAsync<TValue>(observable: Observable<TValue>, targetValue: TValue): Promise<TValue> {
  if (!isObservable(observable)) {
    return observable;
  }

  const value = observable.peek();
  const isTargetValueSpecified = typeof targetValue !== "undefined";
  if (value !== null && typeof value !== "undefined" && (!isTargetValueSpecified || value === targetValue)) {
    return value;
  }

  const deferred = new DeferredPromise<TValue>();
  const subscription = observable.subscribe((value: TValue) => {
    if (value !== null && typeof value !== "undefined" && (!isTargetValueSpecified || value === targetValue)) {
      deferred.resolve(value);
    }
  });

  try {
    return await deferred.promise;
  } finally {
    subscription.dispose();
  }
}

export function promiseObserver<T>(promise: Promise<T>): PromiseObserver<T> {
  return new PromiseObserver<T>(promise);
}

// A lightweight object that can be used to refresh a computed or binding when a given promise is resolved.
class PromiseObserver<T> {
  private error: unknown;
  private observable: Observable<T | undefined> | undefined;
  private value: T | undefined;
  private promise: Promise<T> | undefined;

  constructor(promise: Promise<T>) {
    this.error = undefined;
    this.observable = undefined;
    this.value = undefined;
    this.promise = this.setUpPromiseAsync(promise);
  }

  hasError(): boolean {
    return !!this.error;
  }

  read(): T | undefined {
    const value = this.value;
    const promise = this.promise;
    if (!promise) {
      return value;
    }

    let targetObservable = this.observable;
    if (!targetObservable) {
      this.observable = targetObservable = observable(value);
    }

    return targetObservable();
  }

  async getValueAsync(): Promise<T | undefined> {
    const promise = this.promise;
    if (promise) {
      return await promise;
    }

    const error = this.error;
    if (error) {
      throw error;
    }

    return this.value;
  }

  private async setUpPromiseAsync(promise: Promise<T>): Promise<T> {
    try {
      const value = await promise;

      this.promise = undefined;
      this.value = value;

      const observable = this.observable;
      if (observable) {
        this.observable = undefined;
        observable(value);
      }

      return value;
    } catch (error: unknown) {
      this.error = error;
      this.promise = undefined;
      throw error;
    }
  }
}
