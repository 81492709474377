/*! StartNoStringValidationRegion Suppression*/
import Promise from 'bluebird';
import breeze from 'breeze-client';
import { OData4DataService } from 'breeze-odata4';
import oData from 'OData';

class CustomOData4DataService extends OData4DataService {
	constructor() {
		super();
		this.metadataAcceptHeader = 'application/xml';
		this.innerAdapter = breeze.config.getAdapterInstance('dataService', 'webApi');
	}

	// WTG - Let glow data service to initialize the jsonResultsAdapter
	initialize() {}

	// eslint-disable-next-line rulesdir/async-function-suffix
	async executeQuery(mappingContext) {
		const result = await super.executeQuery(mappingContext);
		result.__next = result.httpResponse.data['@odata.nextLink'];
		return result;
	}

	addQueryString(url, parameters) {
		const entries = Object.entries(parameters);
		if (!entries.length) {
			return url;
		}

		const urlObj = new URL(url);
		const fnArgs = [];
		entries.forEach(([key, value]) => {
			fnArgs.push(`${key}=@${key}`);
			urlObj.searchParams.append(`@${key}`, value);
		});

		urlObj.pathname += `(${fnArgs.join(',')})`;
		return urlObj.toString();
	}

	saveChanges(saveContext, saveBundle) {
		saveContext.adapter = this;
		saveContext.routePrefix = this.getAbsoluteUrl(saveContext.dataService, '');
		const url = `${saveContext.routePrefix}$batch`;
		const requestData = this.createChangeRequests(saveContext, saveBundle);

		requestData.__batchRequests.forEach((data) => {
			data.__changeRequests.forEach((changeRequest) => {
				const headers = changeRequest.headers;

				headers.Accept = 'application/json;odata.metadata=full';

				if (changeRequest.method === 'PATCH' && !saveBundle.saveOptions.shouldRefresh) {
					const returnRepresentation = 'return=representation';
					headers.Prefer = headers.Prefer
						? `${headers.Prefer}, ${returnRepresentation}`
						: returnRepresentation;
				}
			});
		});

		const tempKeys = saveContext.tempKeys;
		const contentKeys = saveContext.contentKeys;
		return new Promise((resolve, reject) => {
			const request = {
				method: 'POST',
				requestUri: url,
				headers: { ...this.headers, Accept: 'multipart/mixed' },
				data: requestData,
			};
			oData.request(
				request,
				(data, response) => {
					const entities = [];
					const keyMappings = [];
					const saveResult = { entities, keyMappings, deletedKeys: null, XHR: null };
					data.__batchResponses.forEach((br) => {
						br.__changeResponses.forEach((cr) => {
							const chResponse = cr.response || cr;
							const statusCode = chResponse.statusCode;
							if (!statusCode || Number(statusCode) >= 400) {
								// WTG - Use createSaveError instead of createError for error handling
								const err = createSaveError(
									this,
									request,
									response,
									cr,
									contentKeys
								);
								reject(err);
								return;
							}
							const contentId = Number((chResponse.headers || {})['Content-ID'] ?? 0);
							const origEntity = contentKeys[contentId];
							const rawEntity = chResponse.data;
							if (rawEntity) {
								const tempKey = tempKeys[contentId];
								if (tempKey) {
									const entityType = tempKey.entityType;
									if (
										entityType.autoGeneratedKeyType !==
										breeze.AutoGeneratedKeyType.None
									) {
										const tempValue = tempKey.values[0];
										const realKey = entityType.getEntityKeyFromRawEntity(
											rawEntity,
											breeze.DataProperty.getRawValueFromServer
										);
										const keyMapping = {
											entityTypeName: entityType.name,
											tempValue,
											realValue: realKey.values[0],
										};
										keyMappings.push(keyMapping);
									}
								}
								entities.push(rawEntity);
							} else if (origEntity) {
								// WTG - Add dummy etag if necessary
								// This is to emulate the odata3 behavior when a change extender deletes a newly added entity.
								// In odata3, the response was as if the entity was successfully created;
								// if shouldRefresh is false further requests could attempt to update or delete the entity
								// which resulted in 404 responses that got handled in the usual way.
								// In odata4, the response is a 204 No Content with no etag.
								// For compatibility, we treat this as if the entity was successfully created,
								// setting the etag to W/"MA==" (the default etag for newly created entities in odata4).
								if (!origEntity.entityAspect.extraMetadata?.etag) {
									origEntity.entityAspect.extraMetadata ??= {};
									origEntity.entityAspect.extraMetadata.etag = 'W/"MA=="';
								}
								entities.push(origEntity);
							}
						});
					});
					resolve(saveResult);
				},
				(err) => {
					const error = this.createError(err, url);
					reject(error);
				},
				oData.batch.batchHandler,
				this.httpClient,
				this.metadata
			);
		});

		function createSaveError(dataService, request, response, cr, contentKeys) {
			const error = dataService.createError(cr, url, ODataSaveError);
			error.request = request;
			error.response = response;
			error.headers = cr.response.headers;

			if (cr.response.statusCode === '404') {
				const contentId = parseInt(cr.response.headers['Content-ID'], 10);
				const entity = contentKeys[contentId];
				error.entity = entity;
			}

			return error;
		}
	}

	createError(error, url, ErrorCtor = ODataError) {
		const response = error && error.response;
		const isErrorObject = error instanceof Error;
		if (!response) {
			return isErrorObject ? error : transformToODataError(error);
		}

		const result = transformToODataError(error);
		// WTG - Add cause error
		if (isErrorObject) {
			result.cause = error;
		}
		if (response.statusCode !== '200') {
			result.message = response.statusText;
			result.statusText = response.statusText;
			result.status = Number(response.statusCode);
		}
		if (url) {
			result.url = url;
		}

		result.body = response.body;
		if (response.body) {
			let nextErr;
			try {
				let body = JSON.parse(response.body);
				result.body = body;
				if (body['odata.error']) {
					body = body['odata.error'];
				}
				let msg = '';
				do {
					nextErr = body.error || body.innererror;
					if (!nextErr) {
						msg = msg + this.getMessage(body);
					}
					nextErr = nextErr || body.internalexception;
					body = nextErr || body;
				} while (nextErr);
				if (msg.length > 0) {
					result.message = msg;
				}
			} catch (e) {
				// eslint-disable-next-line no-empty
			}
		}
		this._catchNoConnectionError(result);

		return result;

		function transformToODataError(error) {
			const result = new ErrorCtor();
			result.message = error.message || error;
			result.statusText = error.message || error;
			return result;
		}
	}
}

class ODataError extends Error {
	constructor(message) {
		super(message);
		this.name = 'ODataError';
	}

	toString() {
		return `${this.name}: ${this.message}`;
	}
}

class ODataSaveError extends Error {
	constructor(message) {
		super(message);
		this.name = 'ODataSaveError';
	}

	getData() {
		const result = [
			{ name: 'RequestObject', value: JSON.stringify(this.request) },
			{ name: 'ResponseObject', value: JSON.stringify(this.response) },
			{ name: 'ChangesetStatus', value: this.status },
			{ name: 'ChangesetStatusText', value: this.statusText },
			{
				name: 'ChangesetResponseHeaders',
				value: JSON.stringify(this.headers),
			},
		];

		return result;
	}

	getResponseHeader(headerName) {
		return this.headers?.[headerName] ?? null;
	}
}

export { ODataSaveError };
export default CustomOData4DataService;
/*! EndNoStringValidationRegion */
