import ajaxService from "AjaxService";
import captionService from "CaptionService";
import { DeliveryErrorReason } from "Constants";
import type { ControllerDocumentInfo } from "Controllers/DocumentController";
import { DeferredPromise } from "DeferredPromise";
import * as dependency from "Dependency2";
import { getInterfaceName, getPrimaryKey, getTableCode, getTypeDescriptionAsync } from "EntityExtensions";
import global from "Global";
import navigationService from "NavigationService";
import RuleService from "RuleService";
import type { Entity } from "breeze-client";
import _ from "lodash-es";

export enum DeliveryMethod {
  PRN = "PRN",
  EML = "EML",
  FAX = "FAX",
}

// Referencing https://devops.wisetechglobal.com/wtg/CargoWise/_git/Dev?path=%2FEnterprise%2FProduct%2FDocuments%2FDocumentEngine%2FService%2FDocumentDeliveryService.cs&_a=contents&version=GBmaster
export interface DeliveryInstructions {
  Recipients: DeliveryRecipientDetail[];
  Documents: DeliveryDocumentDetail[];
  Printers: Printer[];
  PrinterPK: string;
}

// Referencing https://devops.wisetechglobal.com/wtg/CargoWise/_git/Dev?path=%2FEnterprise%2FProduct%2FDocuments%2FDocumentEngine%2FService%2FDocumentDeliveryService.cs&_a=contents&version=GBmaster
export interface DeliveryDocumentDetail {
  Id: string;
  Mode: string;
  Name: string;
  ShouldInclude: boolean;
}
// Referencing https://devops.wisetechglobal.com/wtg/CargoWise/_git/Dev?path=%2FEnterprise%2FProduct%2FDocuments%2FDocumentEngine%2FService%2FDocumentDeliveryService.cs&_a=contents&version=GBmaster
export interface DeliveryRecipientDetail {
  OrganizationID: string;
  Organization: string;
  Name: string;
  Address: string;
  CC: string;
  BCC: string;
  DeliveryMethod: DeliveryMethod;
  AttachmentType: string;
}

// Referencing https://devops.wisetechglobal.com/wtg/CargoWise/_git/Dev?path=%2FEnterprise%2FProduct%2FDocuments%2FDocumentEngine%2FService%2FDocumentDeliveryService.cs&_a=contents&version=GBmaster
export interface Printer {
  ID: string;
  Name: string;
  Location: string;
}

export interface DeliveryRecipientRequest {
  DeliveryMethod: DeliveryMethod;
  Email: string | null;
  CC: string | null;
  BCC: string | null;
  EmailAttachmentType: string | null;
  FaxNumber: string | null;
  Name: string;
  OrganizationId: string;
}

export interface DeliveryInstructionsRequest {
  Copies: number;
  CoverNote: string | null;
  IsDraft: boolean;
  PrinterId: string;
  Recipients: DeliveryRecipientRequest[];
}

export interface DeliveryDocumentRequest extends DeliveryDocumentDetail {}

export interface DeliveryRequest {
  DocumentCommandPk: string;
  BusinessObjectPk: string;
  TablePrefix: string;
  DeliveryInstructions: DeliveryInstructionsRequest;
  Documents: DeliveryDocumentRequest[];
}

export interface DocumentInfo {
  Id: string;
  Name: string;
  Summary: string;
  Path: string;
  Index: number;
  DownloadOnly: boolean;
  IsApplicable: boolean;
}

export interface DocumentWithEntityInfo extends DocumentInfo {
  entityPK: string;
  tableCode: string;
}

export interface CanDeliverResult {
  CanDeliver: boolean;
  ErrorMessage: string;
}

export interface DocumentsBySource {
  Name: string;
  items: DocumentWithEntityInfo[];
}

export interface DocumentGroupingsByStartingMenuPath {
  count: number;
  groups: { [key: string]: DocumentWithEntityInfo[] };
}

export type NestedDocumentsItem = DocumentsBySource | DocumentWithEntityInfo;

export async function getDocumentAsync(
  docPk: string,
  entityType: string,
  entityPk: string,
  entityTableCode: string
): Promise<DocumentInfo | undefined> {
  const documentContext = RuleService.get(entityType).documentContext() || "";

  const documents = await getDocumentsCoreAsync(documentContext, entityPk, entityTableCode);
  return documents.find((doc) => doc.Id === docPk);
}

export function getDocumentPreviewUrl(
  docId: string,
  entityPk: string,
  tableCode: string,
  contentDisposition: string
): string {
  return global.serviceUri + getPreviewUrl(docId, entityPk, tableCode, contentDisposition);
}

export async function getDocumentsAsync(entity: Entity | null): Promise<NestedDocumentsItem[]> {
  if (!entity) {
    return [];
  }

  let allSources = [entity];
  const documentSources = RuleService.get(getInterfaceName(entity)).documentSources();

  if (documentSources) {
    allSources = allSources.concat(
      await Promise.all(
        documentSources.map(async (path) => {
          const { value } = await dependency.getDependencyValueAsync<Entity>(entity, path);
          return value;
        })
      )
    );
  }
  const validSources = allSources.filter((source) => !!source).flat();
  const uniqueSources = _.uniqBy(validSources, (source) => getPrimaryKey(source));

  const documents = await Promise.all(uniqueSources.map(getDocumentsForEntityAsync));
  return mergeDocumentsAsync(entity, uniqueSources, documents);
}

export async function getDeliveryInstructionsAsync(
  docId: string,
  entityPk: string,
  tableCode: string
): Promise<DeliveryInstructions> {
  const uri = `${global.serviceUri}cw1api/documents/instructions`;
  const query = {
    documentCommandPk: docId,
    tablePrefix: tableCode,
    businessObjectPk: entityPk,
  };

  return await ajaxService.getAsync<DeliveryInstructions>(uri, query);
}

export async function deliverDocumentAsync(deliveryRequest: DeliveryRequest): Promise<void> {
  const uri = `${global.serviceUri}cw1api/documents/deliver`;

  return await ajaxService.postAsync(uri, deliveryRequest);
}

export async function canDeliverDocumentAsync(
  docId: string,
  entityPk: string,
  tableCode: string
): Promise<CanDeliverResult> {
  const uri = `${global.serviceUri}cw1api/documents/canDeliverDocument`;

  return await ajaxService.getAsync<CanDeliverResult>(uri, {
    documentCommandPK: docId,
    tablePrefix: tableCode,
    businessObjectPk: entityPk,
  });
}

/**
 * Starts the process of delivering a document.
 * Depending on the provided document, this function either initiates a download for the user or displays a dialog to queue the document for delivery.
 *
 * @param documentId Id of the document to be downloaded or queued for delivery.
 * @param entity Entity associated with the document that is to be delivered.
 * @param handleDeliveryError An optional callback function that is invoked when the document delivery fails.
 * @returns A promise that resolves to a boolean indicating the success (true) or failure (false) of the delivery process.
 */
export async function initiateDocumentDeliveryAsync(
  documentId: string,
  entity: Entity,
  handleDeliveryError?: (reason: string) => void
): Promise<boolean> {
  const deferred = new DeferredPromise<boolean>();
  const options: Partial<ControllerDocumentInfo> = {
    id: documentId,
    entityPk: getPrimaryKey(entity),
    entityType: getInterfaceName(entity),
    tableCode: getTableCode(entity),
    onDocumentDialogClosed: (reason: string) => {
      if (reason !== DeliveryErrorReason.Delivered) {
        if (handleDeliveryError) {
          handleDeliveryError(reason);
        }
        deferred.resolve(false);
      } else {
        deferred.resolve(true);
      }
    },
  };

  navigationService.post("#/document/showDocumentTask", options);

  return await deferred.promise;
}

const documentFormatter = {
  compare<T>(a: T, b: T): number {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }

    return 0;
  },
  getStartingMenuPath(menuPath: string): string {
    const indexOfSlash = menuPath.indexOf("/");
    const result = indexOfSlash > -1 ? menuPath.substring(0, indexOfSlash) : menuPath;
    return result;
  },
  groupByStartingMenuPath(documents: DocumentWithEntityInfo[]): DocumentGroupingsByStartingMenuPath {
    const result: DocumentGroupingsByStartingMenuPath = { count: 0, groups: {} };

    for (let i = 0; i < documents.length; i++) {
      const item = documents[i];
      const startingMenuPath = documentFormatter.getStartingMenuPath(item.Path);
      let group = result.groups[startingMenuPath];

      if (typeof group === "undefined") {
        result.groups[startingMenuPath] = group = [] as DocumentWithEntityInfo[];
        result.count++;
      }

      group.push(item);
    }

    return result;
  },
  nest(documents: NestedDocumentsItem[]): void {
    // Originally documents was a DocumentWithEntityInfo[] but it will become a NestedDocumentsItem[] later once we added the nested items
    const grouping = documentFormatter.groupByStartingMenuPath(documents as DocumentWithEntityInfo[]);

    if (grouping.count > 1) {
      documents.length = 0;
      for (const startingMenuPath in grouping.groups) {
        let documentsForGroup: NestedDocumentsItem[];
        const group = grouping.groups[startingMenuPath];

        if (startingMenuPath) {
          const header: DocumentsBySource = { Name: startingMenuPath, items: [] };
          documents.push(header);
          documentsForGroup = header.items;
        } else {
          documentsForGroup = documents;
        }

        for (let i = 0; i < group.length; i++) {
          const item = group[i];
          item.Path = item.Path.substring(startingMenuPath.length + 1);
          documentsForGroup.push(item);
        }

        if (startingMenuPath) {
          documentFormatter.nest(documentsForGroup);
        }
      }
    }
    documents.sort(documentFormatter.sort);
  },
  sort(a: NestedDocumentsItem, b: NestedDocumentsItem): number {
    if ("items" in a && !("items" in b)) {
      return 1;
    } else if (!("items" in a) && "items" in b) {
      return -1;
    } else if (!("items" in a) && !("items" in b) && a.Index !== b.Index) {
      return documentFormatter.compare(a.Index, b.Index);
    } else {
      return documentFormatter.compare(a.Name, b.Name);
    }
  },
};

async function getDocumentsForEntityAsync(entity: Entity): Promise<NestedDocumentsItem[]> {
  const documentContext = RuleService.get(getInterfaceName(entity)).documentContext();
  if (!documentContext) {
    return [];
  }

  const entityPK = getPrimaryKey(entity);
  const tableCode = getTableCode(entity);
  const results = await getDocumentsCoreAsync(documentContext, entityPK, tableCode);
  const extendedResults: DocumentWithEntityInfo[] = results.map((item) => {
    return {
      ...item,
      entityPK,
      tableCode,
    };
  });
  documentFormatter.nest(extendedResults);
  return extendedResults;
}

async function getDocumentsCoreAsync(
  businessContext: string,
  entityPK: string,
  entityTableCode: string
): Promise<DocumentInfo[]> {
  const uri = `${global.serviceUri}cw1api/documents`;

  const ajaxResults = await ajaxService.getAsync<DocumentInfo[]>(uri, { businessContext, entityPK, entityTableCode });
  return ajaxResults.filter((result) => result.IsApplicable);
}

function getPreviewUrl(docId: string, entityPk: string, tableCode: string, contentDisposition: string): string {
  let result = `cw1api/documents/preview?documentCommandPK=${docId}&tablePrefix=${tableCode}&businessObjectPk=${entityPk}`;

  if (contentDisposition) {
    result += `&disposition=${contentDisposition}`;
  }

  return result;
}

async function mergeDocumentsAsync(
  entity: Entity,
  sources: Entity[],
  documents: NestedDocumentsItem[][]
): Promise<NestedDocumentsItem[]> {
  if (sources.length === 1) {
    return documents[0];
  }

  const mergedPromises = documents.map(async (documentsForSource, i) => {
    if (documentsForSource.length > 0) {
      const name = await getGroupNameAsync(entity, sources[i]);
      return {
        Name: name,
        items: documentsForSource,
      };
    }
    return undefined;
  });

  return (await Promise.all(mergedPromises)).filter(Boolean) as NestedDocumentsItem[];
}

async function getGroupNameAsync(mainEntity: Entity, groupEntity: Entity): Promise<string> {
  let caption = await getTypeDescriptionAsync(groupEntity);
  if (groupEntity === mainEntity) {
    const prefix = captionService.getString("4f440387-c98d-41c7-823c-c7e29fc90ef0", "This");
    return prefix + " " + caption;
  }

  /*! SuppressStringValidation entityAspect code property */
  const { value: code } = await dependency.getDependencyValueAsync(groupEntity.entityAspect, "code");
  if (code) {
    caption += ": " + code;
  }
  return caption;
}
