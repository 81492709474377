/* eslint-disable require-await */
/* eslint-disable no-invalid-this */
const { delayAsync } = require('DelayedPromise');

/*
    DO NOT CONVERT TO TYPESCRIPT OR DEFINE A D.TS FOR THIS FILE

    This file is a replacement for bluebird functions implemented using the native Promise.
    This file maintains support for the bluebird functions in existing JS files even though bluebird is no longer a dependency in GLOW.
    These functions are used in old javascript files only and never to be used in typescript.
    For typescript follow the type script recommendation and use extension functions instead.
*/

function getDeprecatedMessage(functionName) {
	return `Bluebird function "${functionName}" is not supported since bluebird has been deprecated in GLOW. Use a native promise function instead.`;
}

function markAsDeprecated(functionName) {
	return async function () {
		throw new Error(getDeprecatedMessage(functionName));
	};
}

function markAsDeprecatedSync(functionName) {
	return function () {
		throw new Error(getDeprecatedMessage(functionName));
	};
}

function reportUnsupportedParameter(paramName) {
	throw new Error(
		`Bluebird function parameter "${paramName}" is not supported since bluebird has been deprecated in GLOW.`
	);
}

//! StartNoStringValidationRegion function names are not captioned
// Sections corresponding to http://bluebirdjs.com/docs/api-reference.html

// Core
Promise.join = async (...inputs) => {
	const hasHandler = typeof inputs.at(-1) === 'function';
	const promises = hasHandler ? inputs.slice(0, -1) : inputs;
	const results = await Promise.all(promises);
	return hasHandler ? await inputs.at(-1)(...results) : results;
};

Promise.try = async (func) => await func();

Promise.method = markAsDeprecated('Promise.method');

// Synchronous inspection
Promise.prototype.isFulfilled = markAsDeprecatedSync('.isFulfilled');
Promise.prototype.isRejected = markAsDeprecatedSync('.isRejected');
Promise.prototype.isPending = markAsDeprecatedSync('.isPending');
Promise.prototype.isCancelled = markAsDeprecatedSync('.isCancelled');
Promise.prototype.value = markAsDeprecatedSync('.value');
Promise.prototype.reason = markAsDeprecatedSync('.reason');

// Collections
Promise.props = markAsDeprecated('Promise.props');
Promise.some = markAsDeprecated('Promise.some');

Promise.map = async (inputs, handler) => {
	const iterable = await inputs;
	if (!(Symbol.iterator in Object(iterable))) {
		return [];
	}
	const array = Array.from(iterable);
	return Promise.all(array.map(async (input, index) => {
		return await handler(await input, index, inputs.length);
	}));
};

Promise.reduce = async (inputs, reducer, initialValue) => {
	return (await inputs).reduce(async (accumulator, input, index) => {
		return await reducer(await accumulator, await input, index, inputs.length);
	}, initialValue);
};

Promise.filter = async (inputs, filterer, options) => {
	if (options) {
		reportUnsupportedParameter('options');
	}

	return inputs.reduce(async (accumulator, input, index) => {
		const value = await input;
		const results = await accumulator;
		if (await filterer(value, index, inputs.length)) {
			results.push(value);
		}
		return results;
	}, []);
};

Promise.each = async (inputs, iterator) => {
	const actualInputs = await inputs;
	for (let i = 0; i < actualInputs.length; i++) {
		const actualInput = await actualInputs[i];
		await iterator(actualInput, i, actualInputs.length);
	}
};

Promise.mapSeries = markAsDeprecated('Promise.mapSeries'); // existing usages to be removed in WI00717658
Promise.prototype.props = markAsDeprecated('.props');
Promise.prototype.some = markAsDeprecated('.some');

Promise.prototype.map = async function (handler) {
	return Promise.map(await this, handler);
};

Promise.prototype.reduce = async function (reducer, initialValue) {
	return Promise.reduce(await this, reducer, initialValue);
};

Promise.prototype.filter = async function (filterer, options) {
	return Promise.filter(await this, filterer, options);
};

Promise.prototype.each = async function (iterator) {
	await Promise.each(await this, iterator);
};

Promise.prototype.mapSeries = markAsDeprecated('.mapSeries'); // existing usages to be removed in WI00717658

// Resource management
Promise.using = markAsDeprecated('Promise.using');
Promise.prototype.disposer = markAsDeprecated('.disposer');

// Promisification
Promise.promisify = markAsDeprecated('Promise.promisify');
Promise.promisifyAll = markAsDeprecated('Promise.promisifyAll');
Promise.fromCallback = markAsDeprecated('Promise.fromCallback');
Promise.prototype.asCallback = markAsDeprecated('.asCallback');

// Timers
Promise.delay = async (ms, ...unsupported) => {
	if (unsupported.length > 0) {
		reportUnsupportedParameter('value');
	}

	return await delayAsync(ms);
};

Promise.prototype.delay = async function (ms) {
    const result = await this;
    await delayAsync(ms);
    return result;
};

Promise.prototype.timeout = markAsDeprecated('.timeout');

// Cancellation
Promise.prototype.cancel = markAsDeprecated('.cancel');

// Generators
Promise.coroutine = markAsDeprecated('Promise.coroutine');
Promise.coroutine.addYieldHandler = markAsDeprecated('Promise.coroutine.addYieldHandler');

// Utility
Promise.prototype.tap = async function (handler) {
	const result = await this;
	await handler(result);
	return result;
};

Promise.prototype.tapCatch = async function (handler) {
	let result;
	try {
		result = await this;
	}
	catch (error) {
		await handler(error);
		throw error;
	}
	return result;
};

Promise.prototype.get = async function (propertyOrIndex) {
	const result = await this;
	return typeof propertyOrIndex === 'number' ? result.at(propertyOrIndex) : result[propertyOrIndex];
};

Promise.prototype.return = Promise.prototype.thenReturn = async function (value) {
	await this;
	return value;
};

Promise.prototype.throw = Promise.prototype.thenThrow = async function (reason) {
	await this;
	throw reason;
};

Promise.prototype.catchReturn = markAsDeprecated('.catchReturn');
Promise.prototype.catchThrow = markAsDeprecated('.catchThrow');
Promise.prototype.reflect = markAsDeprecated('.reflect');
Promise.getNewLibraryCopy = markAsDeprecated('Promise.getNewLibraryCopy');
Promise.noConflict = markAsDeprecated('Promise.noConflict');
Promise.setScheduler = markAsDeprecated('Promise.setScheduler');

// Configuration
Promise.config = markAsDeprecatedSync('Promise.config');
Promise.prototype.suppressUnhandledRejections = markAsDeprecatedSync('.suppressUnhandledRejections');
Promise.prototype.done = markAsDeprecatedSync('.done');
//! EndNoStringValidationRegion

module.exports = Promise;