import Promise from 'bluebird';
import captionService from 'CaptionService';
import { createDisposable } from 'Disposable';
import errors from 'Errors';
import global from 'Global';
import log from 'Log';
import Notifications from 'Notifications';
import notificationType from 'NotificationType';
import PropertyVertex from 'PropertyVertex';
import RuleService from 'RuleService';
import RuleValidationResult from 'RuleValidationResult';

function ValidationEngine() {
	this._suspendedMap = new WeakMap();
	this._versionMap = new WeakMap();
}

ValidationEngine.prototype.suspendValidation = function (entities) {
	const suspendedMap = this._suspendedMap;
	entities.forEach((entity) => {
		const semaphore = suspendedMap.get(entity) || 0;
		suspendedMap.set(entity, semaphore + 1);
	});

	return createDisposable(() => {
		entities.forEach((entity) => {
			const semaphore = suspendedMap.get(entity);
			if (semaphore > 1) {
				suspendedMap.set(entity, semaphore - 1);
			} else {
				suspendedMap.delete(entity);
			}
		});
	});
};

ValidationEngine.prototype.suspendValidationEntityManager = function (entityManager) {
	const suspendedMap = this._suspendedMap;
	const semaphore = suspendedMap.get(entityManager) || 0;
	suspendedMap.set(entityManager, semaphore + 1);

	return createDisposable(() => {
		const semaphore = suspendedMap.get(entityManager);
		if (semaphore > 1) {
			suspendedMap.set(entityManager, semaphore - 1);
		} else {
			suspendedMap.delete(entityManager);
		}
	});
};

ValidationEngine.prototype.isSuspended = function (entity) {
	return (
		this._suspendedMap.has(entity) || this._suspendedMap.has(entity.entityAspect.entityManager)
	);
};

ValidationEngine.prototype.validateEntityAsync = function (entity, propertyNames, levels) {
	if (this.isSuspended(entity)) {
		return Promise.resolve(true);
	}

	const versionTable = getVersionTable(this, entity);
	const version = propertyNames ? versionTable.incrementVersionForProperties(propertyNames) : versionTable.incrementVersionForEntity();
	const rules = getAllRules(entity, propertyNames, levels);

	const filters = [];
	if (propertyNames) {
		filters.unshift((a) => propertyNames.includes(a.propertyName));
	}
	if (levels) {
		filters.push((a) => levels.includes(a.Level));
	}
	if (!supportsAcknowledgements(entity)) {
		filters.push((a) => !isWarning(a));
	}

	return addOperationPromise(entity, runRulesAsync(versionTable, version, entity, rules, filters)
		.then(() => {
			const alerts = Notifications.get(entity).alerts().filter((a) => filters.every((f) => f(a)));
			return notificationType.compare(Notifications.getLevel(alerts), notificationType.Information) < 1;
		}));
};

ValidationEngine.prototype.validatePropertyAndDependentsAsync = function (entity, propertyName) {
	if (this.isSuspended(entity)) {
		return Promise.resolve();
	}
	log.info(`[ValidationEngine] starting validation on property and dependents, ${entity.entityType.name}.${propertyName}`);
	return addOperationPromise(
		entity,
		Promise.try(() => {
			const dependencyGraph = entity.entityAspect.entityManager?.dependencyGraph;
			const versionTable = getVersionTable(this, entity);
			const allRules = RuleService.get(entity.entityType.interfaceName).validationRules();

			return validatePropertyAsync(versionTable, entity, allRules, propertyName)
				.then(() => {
					const validationNodes = new Set();
					dependencyGraph?.visitDependents(
						new PropertyVertex(entity, propertyName),
						(d) => {
							if (d.isValidation && !d.isVertexFor(entity, propertyName)) {
								validationNodes.add(d);
							}
						}
					);

					return Promise.map(validationNodes, (v) => v.validateAsync());
				})
				.return(undefined);
		})
	);
};

ValidationEngine.prototype.validateRuleAsync = function (entity, rule) {
	if (this.isSuspended(entity)) {
		return Promise.resolve();
	}

	return addOperationPromise(
		entity,
		Promise.try(() => {
			const versionTable = getVersionTable(this, entity);
			const rules = [rule];
			const version = versionTable.incrementVersionForRules(rules);
			const filters = [(a) => a.ruleId === rule.ruleId];
			return runRulesAsync(versionTable, version, entity, rules, filters).return(undefined);
		})
	);
};

function getVersionTable(self, entity) {
	let versionTable = self._versionMap.get(entity);
	if (!versionTable) {
		versionTable = new VersionTable();
		self._versionMap.set(entity, versionTable);
	}

	return versionTable;
}

function getAllRules(entity, propertyNames, levels) {
	let result = [];
	const rules = RuleService.get(entity.entityType.interfaceName).validationRules();

	for (const propertyName in rules) {
		if (!propertyNames || propertyNames.indexOf(propertyName) > -1) {
			result = result.concat(rules[propertyName]);
		}
	}

	if (levels) {
		result = result.filter((rule) => {
			return rule.notificationType === null || levels.indexOf(rule.notificationType) > -1;
		});
	}

	return result;
}

function addOperationPromise(entity, promise) {
	if (entity.entityAspect && entity.entityAspect.entityManager) {
		return entity.entityAspect.entityManager.addPromise(promise);
	}
	return promise;
}

function runRuleAsync(entity, rule, result) {
	return rule.processAsync(entity)
		.then((ruleResult) => {
			if (ruleResult.isSuccess) {
				const item = ruleResult.value;
				if (item && item.Level !== notificationType.None && item.Level !== notificationType.Success) {
					item.ruleId = rule.ruleId;
					RuleValidationResult.prototype.setEntityAndPropertyName.call(item, entity, rule.property);
					result.push(item);
				}
			}
		})
		.catch((error) => {
			if (errors.findError(error, errors.UnavailableArgumentsOrSecurityError)) {
				return;
			}

			if (error instanceof errors.RuleInvocationException && global.isPreviewMode()) {
				throw error;
			}
			const item = new RuleValidationResult(captionService.getString('06445a07-1175-49e3-b77e-f1e10dc9fd84', 'An error occurred while validating this property.'), notificationType.Error);
			item.ruleId = rule.ruleId;
			item.setEntityAndPropertyName(entity, rule.property);
			result.push(item);
		});
}

function getRuleIdFromAlert(alert) {
	return alert.ruleId;
}

function getRuleIdFromAcknowledgment(ack) {
	return ack.XK_RuleID();
}

function isNonCancelledAcknowledgment(ack) {
	return !ack.XK_IsCancelled();
}

function supportsAcknowledgements(entity) {
	return !!entity.Acknowledgements;
}

function runRulesAsync(versionTable, version, entity, rules, baseFilters) {
	let result = [];
	const notifications = Notifications.get(entity);

	return Promise
		.map(rules, (rule) => {
			return runRuleAsync(entity, rule, result);
		})
		.then(() => {
			if (supportsAcknowledgements(entity) && result.some(isWarning)) {
				let acknowledgedRuleIds = [];
				acknowledgedRuleIds = notifications.acknowledgedAlerts.map(getRuleIdFromAlert);
				return entity.Acknowledgements.loadAsync().then((acknowledgements) => {
					const previouslyAcknowledgedRuleIds = acknowledgements.filter(isNonCancelledAcknowledgment).map(getRuleIdFromAcknowledgment);
					return previouslyAcknowledgedRuleIds.concat(acknowledgedRuleIds);
				});
			}
		})
		.then((acknowledgedRuleIds) => {
			if (acknowledgedRuleIds && acknowledgedRuleIds.length) {
				result = result.filter((alert) => {
					return !isPriorAcknowledgement(alert, acknowledgedRuleIds);
				});
			}

			const filters = [(a) => versionTable.getVersion(a) <= version, ...baseFilters];
			result = result.filter((a) => filters.every((f) => f(a)));

			result.forEach((a) => {
				log.info(`[ValidationEngine] Alert: ${a.entityName}.${a.propertyName}: ${a.Text}`);
			});

			if (!supportsAcknowledgements(entity)) {
				result.forEach((alert, i) => {
					if (isWarning(alert)) {
						result[i] = Object.assign({ requiresAcknowledgement: false }, alert);
					}
				});
			}

			clearNotifications(notifications, rules, filters);

			if (result.length > 0) {
				notifications.pushAll(result);
			}
		});
}

function validatePropertyAsync(versionTable, entity, allRules, propertyName) {
	return Promise.try(() => {
		const version = versionTable.incrementVersionForProperties([propertyName]);
		const propertyRules = allRules[propertyName] || [];
		const filters = [(a) => a.propertyName === propertyName];
		return runRulesAsync(versionTable, version, entity, propertyRules, filters).return(propertyRules);
	});
}

function isWarning(alert) {
	return alert.Level === notificationType.Warning;
}

function isPriorAcknowledgement(alert, acknowledgedRuleIds) {
	return alert.Level === notificationType.Warning && acknowledgedRuleIds.some((ruleId) => {
		return alert.ruleId === ruleId;
	});
}

function clearNotifications(notifications, rules, filters) {
	const matchedAlert = (alert) => {
		return rules.some((r) => r.ruleid === alert.ruleid);
	};
	notifications.removeAll((alert) => {
		return filters.every((f) => f(alert)) &&
			(alert.isServerNotification || alert.ruleId && matchedAlert(alert));
	});
}

class VersionTable {
	constructor() {
		this._properties = new Map();
		this._nextVersion = 1;
		this._globalVersion = 0;
	}

	getVersion(alert) {
		let version;
		const property = this._properties.get(alert.propertyName);
		if (property) {
			version = (property.rules && property.rules.get(alert.ruleId)) || property.version;
		}

		return version || this._globalVersion;
	}

	incrementVersionForEntity() {
		const version = this._nextVersion++;
		this._globalVersion = version;
		this._properties.clear();

		return version;
	}

	incrementVersionForProperties(propertyNames) {
		const version = this._nextVersion++;
		propertyNames.forEach((name) => {
			this._properties.set(name, { version, rules: undefined });
		});

		return version;
	}

	incrementVersionForRules(rules) {
		const version = this._nextVersion++;
		rules.forEach((rule) => {
			let property = this._properties.get(rule.property);
			if (!property) {
				property = { version: 0, rules: undefined };
				this._properties.set(rule.property);
			}
			if (!property.rules) {
				property.rules = new Map();
			}

			property.rules.set(rule.ruleId, version);
		});

		return version;
	}
}

export default new ValidationEngine();
