import alertResultsDeserializer from 'AlertResultsDeserializer';
import captionService from 'CaptionService';
import dialogService from 'DialogService';
import errorDialogService from 'ErrorDialogService';
import errorHandler from 'ErrorHandler';
import errors from 'Errors';
import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import NotificationSummary from 'NotificationSummary';
import notificationType from 'NotificationType';
import Notifications from 'Notifications';
import { isODataValidationError, getODataErrorMessage } from 'ODataUtils';
import { SaveEntityError, SaveEntityErrorType } from 'SaveEntityError';
import handleJobDocAddressDuplicateUniqueIndexConflictAsync from 'Shared/JobDocAddress/JobDocAddressDuplicateUniqueIndexConflictHandler';
import systemAuditPropertiesHelper from 'SystemAuditPropertiesHelper';
import validationEngine from 'ValidationEngine';
import breeze from 'breeze-client';
import $ from 'jquery';
import ko from 'knockout';
import { isEqual, notifyChangedFieldValuesAsync } from './EntitySaveServiceConcurrencyMergeHelper';

const MaxRecursionDepth = 3;

export class EntitySaveService {
	/*
	interface SaveOptions {
		shouldReconcileConflicts?: boolean;
		shouldRefresh?: boolean;
	}
	*/
	saveAsync(entityManager, options) {
		const promise = saveCoreAsync(entityManager, options);
		return trackBusyStateAsync(promise);
	}

	async saveWithoutAlertsAsync(entityManager, options) {
		try {
			await this.saveAsync(entityManager, options);
			return { isSaved: true, error: null };
		} catch (error) {
			if (error instanceof SaveEntityError) {
				return { isSaved: false, error };
			}
			throw error;
		}
	}

	/*
	interface SaveWithAlertsOptions extends SaveOptions {
		ignoreWarnings?: boolean;
		ignoreWarningsProperties?: string[];
		shouldShowDialog(saveEntityErrorType: SaveEntityErrorType)?: boolean;
	}
	*/
	async saveWithAlertsAsync(entityManager, options) {
		try {
			await this.saveAsync(entityManager, options);
			return { isSaved: true, error: null };
		} catch (error) {
			if (!(error instanceof SaveEntityError)) {
				throw error;
			}

			const shouldShowDialog = options?.shouldShowDialog ?? (() => true);

			if (error.type === SaveEntityErrorType.SaveValidation) {
				const ignoreWarnings = options?.ignoreWarnings;
				if (ignoreWarnings) {
					removeWarningsForProperties(entityManager, options.ignoreWarningsProperties);
				}

				const entities = ko.observable(entityManager.getEntities());
				const notificationSummary = new NotificationSummary(null, entities);
				if (ignoreWarnings && notificationSummary.all().length === 0) {
					return this.saveWithAlertsAsync(entityManager);
				}

				if (shouldShowDialog(error.type)) {
					const message = error.friendlyMessage();
					const title = error.friendlyCaption();

					await errorDialogService.showValidationResultsAsync(
						message,
						title,
						notificationSummary
					);
				}

				// given SaveValidation error, with only warning alerts, and all are acknowledged, should reattempt saving
				const isWarningOrLess = notificationType.compare(notificationSummary.level(), notificationType.Warning) < 1;
				const areAllWarningsAcknowledged = notificationSummary.warnings().filter((x) => x.acknowledged()).length ===
					notificationSummary.warnings().length;
				if (isWarningOrLess && areAllWarningsAcknowledged) {
					return this.saveWithAlertsAsync(entityManager);
				}
			} else if (shouldShowDialog(error.type)) {
				await dialogService.showAsync(
					notificationType.Error,
					error.friendlyMessage(),
					error.friendlyCaption()
				);
			}

			return { isSaved: false, error };
		}
	}
}

async function saveCoreAsync(entityManager, options) {
	await entityManager.waitOperationsAsync();

	const entities = entityManager.getEntities();
	const isValid = await validateUnsavedEntitiesAsync(entityManager);
	if (!isValid) {
		throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
	}

	const acknowledgements = await acknowledgeAndClearNotificationsAsync(entities);
	const suspension = validationEngine.suspendValidation(entities);

	try {
		try {
			await entityManager.saveChanges(null, constructSaveOptions(entityManager, options));
		} catch (error) {
			const handledError = await handleErrorAsync(entityManager, error, options);
			if (handledError.type !== SaveEntityErrorType.None) {
				await deleteWarningAcknowledgementsAsync(acknowledgements);
				throw handledError;
			}
		}

		clearAcknowledgedAlerts(entities);

		try {
			await entityManager.resourceStreams.saveChangesAsync();
		} catch (e) {
			if (e instanceof errors.ResourceStreamError) {
				const error = e.cause;
				const entity = e.entity;
				entity.entityAspect.setEntityState(breeze.EntityState.Modified);

				if (error.status === 400 || error.status === 403) {
					if (handleResourceStreamError(entityManager, error)) {
						throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
					}
				} else if (error.status === 413) {
					throw new SaveEntityError(SaveEntityErrorType.KnownRequestFailure, {
						friendlyMessageOverride: captionService.getString(
							'9a93b251-4df5-4a10-970b-bcfcdf6449c0',
							'The amount of data you are saving is too large. Please check if you have any files, images or rich text content and reduce their size.'
						),
					});
				}
				throw new SaveEntityError(SaveEntityErrorType.UnknownRequestFailure, {
					cause: e,
				});
			}
			throw e;
		}
	} finally {
		suspension.dispose();
	}

	function constructSaveOptions(entityManager, options) {
		if (!options || !options.shouldRefresh) {
			return null;
		}

		const baseOptions = entityManager.saveOptions
			? entityManager.saveOptions
			: breeze.SaveOptions.defaultInstance;
		return new breeze.SaveOptions({ ...baseOptions, shouldRefresh: options.shouldRefresh });
	}
}

function removeWarningsForProperties(entityManager, ignoreWarningsProperties) {
	entityManager.getEntities().map((entity) => {
		const notifications = entity.entityAspect
			? entity.entityAspect.notifications
			: entity.notifications;
		notifications
			.alerts()
			.filter((alert) => isAlertToIgnore(alert, ignoreWarningsProperties))
			.forEach((alert) => alert.acknowledged(true));
		notifications.removeAll((alert) => isAlertToIgnore(alert, ignoreWarningsProperties));
	});
}

function isAlertToIgnore(alert, ignoreWarningsProperties) {
	return (
		alert.isWarning() &&
		(ignoreWarningsProperties === null || ignoreWarningsProperties.includes(alert.propertyName))
	);
}

async function validateUnsavedEntitiesAsync(entityManager) {
	const entities = entityManager.getEntities(null, [
		breeze.EntityState.Modified,
		breeze.EntityState.Added,
	]);
	const results = await Promise.all(
		entities.map((entity) => {
			return validationEngine.validateEntityAsync(entity, null, [
				notificationType.Error,
				notificationType.Warning,
			]);
		})
	);

	return results.every(Boolean);
}

function clearAcknowledgedAlerts(entities) {
	for (let i = 0; i < entities.length; i++) {
		Notifications.get(entities[i]).clearAcknowledgedAlerts();
	}
}

async function createSingleWarningAcknowledgementAsync(entity, alert) {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	const acknowledgment = await entity.entityAspect.addNewRelatedAsync('Acknowledgements');
	acknowledgment.XK_ParentID(alert.entityPK);
	acknowledgment.XK_RuleID(alert.ruleId);
	return acknowledgment;
}

function createWarningAcknowledgementsAsync(entity) {
	const notifications = Notifications.get(entity);
	return notifications.acknowledgedAlerts.map((alert) => {
		return createSingleWarningAcknowledgementAsync(entity, alert);
	});
}

function acknowledgeAndClearNotificationsAsync(entities) {
	const promises = [];
	const removeServerAndUnacknowledgedWarnings = (alert) => {
		return (
			alert.isServerNotification &&
			!(alert.Level === notificationType.Warning && alert.acknowledged())
		);
	};

	for (let i = 0; i < entities.length; i++) {
		const entity = entities[i];
		if (entity.Acknowledgements && !entity.entityAspect.entityState.isDeleted()) {
			const acknowledgementPromises = createWarningAcknowledgementsAsync(entity);
			promises.push.apply(promises, acknowledgementPromises);
		}
		Notifications.get(entity).removeAll(removeServerAndUnacknowledgedWarnings);
	}

	return Promise.all(promises);
}

function deleteWarningAcknowledgementsAsync(acknowledgements) {
	Promise.all(
		acknowledgements.map((acknowledgement) => {
			return acknowledgement.entityAspect.deleteAsync();
		})
	);
}

function getChangedProperties(entity) {
	const values = {};
	const tableCode = entity.entityType.tableCode;

	for (const propertyName in entity.entityAspect.originalValues) {
		const isSystemManaged = systemAuditPropertiesHelper.isSystemAuditProperty(
			propertyName,
			tableCode
		);

		if (!isSystemManaged) {
			values[propertyName] = {
				original: entity.entityAspect.originalValues[propertyName],
				changed: entity[propertyName](),
			};
		}
	}

	return values;
}

async function handleConcurrencyDeleteErrorAsync(entity) {
	const query = breeze.EntityQuery.fromEntityKey(entity.entityAspect.getKey());
	const entityManager = breeze.EntityManager.withMetadataStore(entity.entityType.metadataStore);

	const data = await entityManager.executeQuery(query);
	const reloadedEntity = data.results[0];
	if (reloadedEntity) {
		entity.entityAspect.extraMetadata.etag = reloadedEntity.entityAspect.extraMetadata.etag;
	}
	return !!reloadedEntity;
}

async function handleConcurrencyErrorAsync(entityManager, error, options, recursionDepth) {
	const message = getODataErrorMessage(error.body);
	const info = message && tryParseJson(message);
	if (!info) {
		return;
	}

	let entity;
	try {
		entity = entityManager.getEntityByKey(info.entityTypeName, info.entityKey);
	} catch (e) {
		const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
			message,
			cause: e,
		});
		errorHandler.reportError(
			concurrencyError,
			'Attempted call on entityManager.getEntityByKey with invalid info object: ' +
				JSON.stringify(info)
		);

		return concurrencyError;
	}

	let handler;
	if (info.conflictType) {
		if (info.conflictType === 'JobDocAddressDuplicateUniqueIndexConflict') {
			handler = handleJobDocAddressDuplicateUniqueIndexConflictAsync(
				entityManager,
				entity,
				error,
				options
			);
		} else {
			const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
				entity,
				message,
				cause: error,
			});
			errorHandler.reportError(
				concurrencyError,
				`Unknown conflictType ${JSON.stringify(
					info.conflictType
				)} in info object: ${message}`
			);
			return concurrencyError;
		}
	} else {
		const entityState = entity && entity.entityAspect.entityState;
		const shouldReconcileConflicts = !options || options.shouldReconcileConflicts !== false;
		if (
			!shouldReconcileConflicts ||
			!entityState ||
			entityState.isAdded() ||
			entityState.isUnchanged()
		) {
			return new SaveEntityError(SaveEntityErrorType.Concurrency, { entity });
		} else if (entity.entityAspect.entityState.isDeleted()) {
			handler = handleConcurrencyDeleteErrorAsync(entity);
		} else {
			handler = handleConcurrencyModifyErrorAsync(entity);
		}
	}

	try {
		const canSave = await handler;
		if (canSave && recursionDepth < MaxRecursionDepth) {
			await entityManager.saveChanges();
			return new SaveEntityError(SaveEntityErrorType.None);
		} else {
			return new SaveEntityError(SaveEntityErrorType.Concurrency, { entity });
		}
	} catch (error) {
		return handleErrorAsync(entityManager, error, options, recursionDepth + 1);
	}
}

async function handleConcurrencyModifyErrorAsync(entity) {
	const valuesBeforeReload = getChangedProperties(entity);
	const query = breeze.EntityQuery.fromEntityKey(entity.entityAspect.getKey()).using(
		breeze.MergeStrategy.OverwriteChanges
	);

	await entity.entityAspect.entityManager.executeQuery(query);

	let mergedSuccessfully = true;
	await Promise.all(
		Object.entries(valuesBeforeReload).map(([propertyName, valueBeforeReload]) => {
			const reloadedValue = entity[propertyName]();

			if (
				isEqual(
					reloadedValue,
					valueBeforeReload.original,
					entity.entityType.getDataProperty(propertyName)
				)
			) {
				entity[propertyName](valueBeforeReload.changed);
			} else if (reloadedValue !== valueBeforeReload.changed) {
				mergedSuccessfully = false;
				return notifyChangedFieldValuesAsync(
					entity,
					propertyName,
					valueBeforeReload.changed,
					reloadedValue
				);
			}
		})
	);

	return mergedSuccessfully;
}

async function handleErrorAsync(entityManager, error, options, recursionDepth) {
	let result;
	recursionDepth = recursionDepth || 0;

	switch (error.status) {
		case 0:
			result = new SaveEntityError(SaveEntityErrorType.NetworkFailure, { cause: error });
			break;

		case 400:
			if (handleValidationError(entityManager, error)) {
				result = new SaveEntityError(SaveEntityErrorType.SaveValidation);
			}
			break;

		case 401:
			result = new SaveEntityError(SaveEntityErrorType.Unauthorized);
			break;

		case 403:
			result = new SaveEntityError(SaveEntityErrorType.SecurityFailure);
			break;

		case 404:
			result = await handleNotFoundErrorAsync(entityManager, error.entity, options);
			break;

		case 412:
			result = await handleConcurrencyErrorAsync(
				entityManager,
				error,
				options,
				recursionDepth
			);
			break;

		case 503:
			if (errors.isDbUpgradeError(error)) {
				result = new SaveEntityError(SaveEntityErrorType.DatabaseIsUpgrading, {
					cause: error,
				});
			}
			break;

		case undefined:
			result = error;
	}

	return result || handleGenericError(error);
}

function handleGenericError(error) {
	const isClientError = error.status && error.status >= 400 && error.status < 500;
	const friendlyMessageOverride =
		isClientError && !isODataValidationError(error.body) && getODataErrorMessage(error.body);
	return friendlyMessageOverride
		? new SaveEntityError(SaveEntityErrorType.KnownRequestFailure, { friendlyMessageOverride })
		: new SaveEntityError(SaveEntityErrorType.UnknownRequestFailure, { cause: error });
}

async function handleNotFoundErrorAsync(entityManager, entity, options) {
	if (!entity) {
		return;
	}

	if (!entity.entityAspect.entityState.isDeleted()) {
		return new SaveEntityError(SaveEntityErrorType.NotFound, { entity });
	}

	entity.entityAspect.setDetached();

	try {
		await entityManager.saveChanges();
		return new SaveEntityError(SaveEntityErrorType.None);
	} catch (error) {
		return handleErrorAsync(entityManager, error, options);
	}
}

function handleValidationError(entityManager, error) {
	const alerts = alertResultsDeserializer.fromError(error);
	return setNotificationAlerts(entityManager, alerts);
}

function handleResourceStreamError(entityManager, error) {
	const errorXmlMessage = $($.parseXML(error.responseText));
	const alerts = alertResultsDeserializer.fromErrorMessage(errorXmlMessage.text());
	return setNotificationAlerts(entityManager, alerts);
}

function setNotificationAlerts(entityManager, alerts) {
	let hasAlert = false;
	for (const bucketKey in alerts) {
		const bucket = alerts[bucketKey];
		const alert = bucket[0];
		let entity;
		try {
			entity = entityManager.getEntityByKey(alert.entityName, alert.entityPK);
		} catch (e) {
			// Don't care.
		}

		if (!entity) {
			continue;
		}

		Notifications.get(entity).pushAll(bucket);
		hasAlert = true;
	}

	return hasAlert;
}

function tryParseJson(value) {
	try {
		return JSON.parse(value);
	} catch (e) {
		// Don't care.
	}
}

export default new EntitySaveService();
