import ajaxService, { AjaxError, type GlowUrlAjaxSettings } from "AjaxService";
import type { AuthenticationClaimType } from "AuthenticationClaimType";
import {
  AuthenticationError,
  AuthenticationErrorWithBeginSessionResponse,
  AuthenticationErrorWithClaimResponse,
} from "AuthenticationError";
import { getAuthErrorMessageForHttpStatus } from "AuthenticationErrorMessages";
import { glowAuthenticationResultHeaderName } from "AuthenticationHeaders";
import type {
  BeginSessionResponse,
  ClaimResponse,
  PartiallySuccessfulClaimResponse,
  SuccessfulClaimResponse,
  UserInfo,
} from "AuthenticationResponse";
import { AuthenticationResult } from "AuthenticationResult";
import type { AuthenticationSource } from "AuthenticationSource";
import captionService from "CaptionService";
import { DropDownDisplayMode } from "Constants";
import contextChangeService from "ContextChangeService";
import { DeferredPromise } from "DeferredPromise";
import dialogService, { type Dialog } from "DialogService";
import { isDbUpgradeError } from "Errors";
import global from "Global";
import { LogonProviderType } from "LogonProviderType";
import materialDesignDialogService from "MaterialDesignDialogService";
import { loadTemplateAsync } from "ModuleLoader";
import navigationService from "NavigationService";
import NotificationSummary from "NotificationSummary";
import { NotificationType } from "NotificationType";
import Notifications from "Notifications";
import { SessionType } from "SessionType";
import { UserType } from "UserTypeConstants";
import windowManager from "WindowManager";
import ko, { type Observable, type PureComputed } from "knockout";
import type { AuthenticationContextListResponse, AuthenticationLocationInfo } from "./AuthenticationContext.ts";

export interface CheckSessionResponse {
  userInfo: UserInfo;
}

export interface ContextChangeSessionData {
  branchPK?: string;
  departmentPK?: string;
  userPK: string;
}

export interface SuccessfulBeginSessionResult {
  userInfo: UserInfo;
}

async function ajaxAsync<T>(request: GlowUrlAjaxSettings): Promise<T> {
  try {
    return await ajaxService.rawAjaxAsync(request);
  } catch (error) {
    handleAjaxError(error);
  }
}

function authAjaxAsync<T>(uri: string, data: object): Promise<T> {
  /*! StartNoStringValidationRegion Suppressed in initial refactor */
  const request = {
    url: global.baseServiceUri + "auth/v2/" + uri,
    type: "post",
    dataType: "json",
    contentType: "application/json",
    data: JSON.stringify(data),
    isSensitive: true,
  };
  /*! EndNoStringValidationRegion */
  return ajaxAsync(request);
}

function handleAjaxError(error: unknown): never {
  if (error instanceof AjaxError) {
    let errorMessage: string | undefined;
    let authenticationResult: AuthenticationResult | undefined;
    if (error.status) {
      if (isDbUpgradeError(error)) {
        errorMessage = captionService.getString(
          "9e930565-85fa-4f61-873a-d66793b2ebee",
          "The application is currently being upgraded, please try again later."
        );
      } else {
        const authenticationResultName = error.getResponseHeader(glowAuthenticationResultHeaderName);
        if (authenticationResultName) {
          authenticationResult = AuthenticationResult[authenticationResultName as keyof typeof AuthenticationResult];
        } else {
          errorMessage = getAuthErrorMessageForHttpStatus(error.status);
        }
      }
    }

    if (authenticationResult === undefined) {
      authenticationResult = AuthenticationResult.AbnormalFailure;
    }

    throw new AuthenticationError(authenticationResult, errorMessage, error);
  } else {
    throw error;
  }
}

class CredentialAuthenticationService {
  async querySsoTokenAsync(ssoToken: string): Promise<SuccessfulClaimResponse | undefined> {
    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/sso/info", {
      authenticationToken: ssoToken,
    });
    if (response.result === AuthenticationResult.Success) {
      if (!isSuccesfulClaimResponse(response)) {
        /*! SuppressStringValidation internal error message */
        throwInvalidClaimResponseError(response, "Response does not contain one or more required properties.");
      }
      return response;
    } else {
      return undefined;
    }
  }

  async claimSsoTokenAsync(ssoToken: string): Promise<SuccessfulClaimResponse | undefined> {
    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/sso", {
      authenticationToken: ssoToken,
      setTokenCookie: true,
    });
    if (response.result === AuthenticationResult.Success) {
      if (!isSuccesfulClaimResponse(response)) {
        /*! SuppressStringValidation internal error message */
        throwInvalidClaimResponseError(response, "Response does not contain one or more required properties.");
      }
      return response;
    } else {
      return undefined;
    }
  }

  async claimAsync(
    logonProviderType: LogonProviderType,
    userName: string,
    password: string,
    serialNumber?: string | null,
    deviceType?: string | null
  ): Promise<PartiallySuccessfulClaimResponse> {
    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/claim/" + logonProviderType, {
      userName,
      password,
      serialNumber,
      deviceType,
      setTokenCookie: true,
    });
    if (
      response.result === AuthenticationResult.Success ||
      response.result === AuthenticationResult.PasswordChangeRequired ||
      response.result === AuthenticationResult.ContextChangeRequired
    ) {
      if (!isPartiallySuccesfulClaimResponse(response)) {
        /*! SuppressStringValidation internal error message */
        throwInvalidClaimResponseError(response, "Response does not contain one or more required properties.");
      }
      return response;
    } else {
      throw new AuthenticationErrorWithClaimResponse(response);
    }
  }

  async selectUserContextAsync(userKey: string, branchKey?: string, departmentKey?: string): Promise<void> {
    const data = {
      logonProviderType: global.portalInfo.userType,
      userKey,
      branchKey,
      departmentKey,
      useAndSetTokenCookie: true,
    };

    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/context/select", data);
    if (response.result !== AuthenticationResult.Success) {
      throw new AuthenticationErrorWithClaimResponse(response);
    }
  }

  async externalAsync(
    authenticationSource: AuthenticationSource,
    userKey: string,
    keysToReport: Record<string, string | null>
  ): Promise<ClaimResponse> {
    const data = {
      authenticationSource,
      logonProviderType: global.portalInfo.userType,
      userKey,
      externalLogonValues: keysToReport,
      setTokenCookie: true,
    };

    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/external", data);
    if (response.result === AuthenticationResult.Success) {
      return response;
    } else {
      throw new AuthenticationErrorWithClaimResponse(response);
    }
  }

  async revokeAsync(): Promise<void> {
    /*! SuppressStringValidation - part of URI path */
    await authAjaxAsync("credential/revoke", { useAndSetTokenCookie: true });
  }

  async revokeSsoTokenAsync(authenticationToken: string): Promise<void> {
    /*! SuppressStringValidation - part of URI path */
    await authAjaxAsync("credential/revoke", { authenticationToken });
  }

  async challengeAsync(authenticationSource: AuthenticationSource): Promise<ClaimResponse> {
    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<ClaimResponse>("credential/challenge", { authenticationSource });
    if (
      response.result === AuthenticationResult.Success ||
      response.result === AuthenticationResult.ThirdPartyUserValidationRequired
    ) {
      return response;
    } else {
      throw new AuthenticationErrorWithClaimResponse(response);
    }
  }

  async listContextsAsync(userKey?: string): Promise<AuthenticationContextListResponse> {
    const logonProviderType = global.portalInfo.userType;

    /*! SuppressStringValidation - part of URI path */
    return await authAjaxAsync("credential/context/list", {
      logonProviderType,
      userKey,
      useTokenCookie: true,
    });
  }
}

function isPartiallySuccesfulClaimResponse(response: ClaimResponse): response is PartiallySuccessfulClaimResponse {
  return !!(response.identityProvider && response.userKey);
}

function isSuccesfulClaimResponse(response: ClaimResponse): response is SuccessfulClaimResponse {
  return isPartiallySuccesfulClaimResponse(response) && !!(response.branchKey && response.departmentKey);
}

function throwInvalidClaimResponseError(response: ClaimResponse, message: string): never {
  throw new AuthenticationError(
    AuthenticationResult.AbnormalFailure,
    undefined,
    new AuthenticationErrorWithClaimResponse(response, message)
  );
}

class SessionAuthenticationService {
  async beginAsync(
    tokenType: AuthenticationClaimType,
    sessionType: SessionType | null | undefined,
    existingSession?: string | null
  ): Promise<SuccessfulBeginSessionResult> {
    const request = {
      tokenType,
      sessionType: resolveSessionType(sessionType),
      existingSession: existingSession || undefined,
      useAndSetTokenCookie: true,
    };

    /*! SuppressStringValidation - part of URI path */
    const response = await authAjaxAsync<BeginSessionResponse>("session/begin", request);
    const getUserInfo = (): UserInfo => {
      const result = response.userInfo;
      if (!result) {
        throw new AuthenticationError(
          AuthenticationResult.AbnormalFailure,
          undefined,
          /*! SuppressStringValidation internal error message */
          new AuthenticationErrorWithBeginSessionResponse(response, "Response does not contain user info.")
        );
      }

      return result;
    };

    if (response.result === AuthenticationResult.Success) {
      const userInfo = getUserInfo();
      if (userInfo.identityProvider === UserType.Person && global.portalInfo.userType !== LogonProviderType.Person) {
        if (await this.changeUserAsync()) {
          return this.beginAsync(tokenType, sessionType, existingSession);
        } else {
          throw new AuthenticationErrorWithBeginSessionResponse(
            response,
            captionService.getString("b73fba63-a1dc-4192-bdf8-b34df587a4fa", "You must select a user to log in.")
          );
        }
      }

      return { userInfo };
    } else if (response.result === AuthenticationResult.ContextChangeRequired) {
      await this.changeContextAsync({ userPK: getUserInfo().identityKey });
      return this.beginAsync(tokenType, sessionType, existingSession);
    }

    throw new AuthenticationErrorWithBeginSessionResponse(response);
  }

  async checkAsync(): Promise<CheckSessionResponse> {
    /*! StartNoStringValidationRegion - part of URI path */
    const request = {
      url: global.baseServiceUri + "auth/v2/session/check",
      type: "get",
      dataType: "json",
    };
    /*! EndNoStringValidationRegion */
    return await ajaxAsync(request);
  }

  async destroyAsync(): Promise<void> {
    /*! StartNoStringValidationRegion - part of URI path */
    const request = {
      url: global.baseServiceUri + "auth/v2/session/destroy",
      type: "post",
    };
    /*! EndNoStringValidationRegion */
    await ajaxAsync(request);
  }

  async destroyAllAsync(sessionType: SessionType | null | undefined): Promise<void> {
    /*! StartNoStringValidationRegion - part of URI path */
    const request = {
      url: global.baseServiceUri + "auth/v2/session/destroyAll",
      type: "post",
      contentType: "application/json",
      data: JSON.stringify({ sessionType: resolveSessionType(sessionType) }),
    };
    /*! EndNoStringValidationRegion */
    await ajaxAsync(request);
  }

  async changeContextAsync(sessionData: ContextChangeSessionData, canSkip: boolean = false): Promise<boolean> {
    const contextData = await credentials.listContextsAsync(sessionData.userPK);
    const result = await new ContextChangeViewModel(contextData, sessionData, canSkip).changeContextAsync();
    if (!result && !canSkip) {
      throw new Error("Did not expect result to be false when can skip is false.");
    }

    return result;
  }

  async changeUserAsync(): Promise<boolean> {
    const { contexts } = await credentials.listContextsAsync();
    const context = await contextChangeService.selectUserAsync(contexts);

    if (context) {
      await credentials.selectUserContextAsync(context.userKey);
      return true;
    } else {
      return false;
    }
  }
}

function resolveSessionType(sessionType: SessionType | null | undefined): SessionType {
  if (!sessionType) {
    return SessionType.General;
  }

  if (!Object.values(SessionType).includes(sessionType)) {
    throw new AuthenticationError(AuthenticationResult.AbnormalFailure, `Unsupported SessionType: [${sessionType}]`);
  }

  return sessionType;
}

export class ContextChangeViewModel {
  private readonly canSkip?: boolean;
  private readonly originalBranchKey?: string;
  private readonly originalDepartmentKey?: string;
  private readonly userPK: string;

  private deferred?: DeferredPromise<boolean>;
  private dialogInfo?: Dialog;

  readonly DropDownDisplayMode = DropDownDisplayMode;

  readonly branchKey: Observable<string | undefined>;
  readonly branches: Observable<AuthenticationLocationInfo[]>;
  readonly canSave: PureComputed<boolean>;
  readonly departmentKey: Observable<string | undefined>;
  readonly departments: PureComputed<AuthenticationLocationInfo[]>;
  readonly hasChanged: PureComputed<boolean>;
  readonly notifications: Notifications;
  readonly notificationSummary: NotificationSummary;

  constructor(
    contextData: AuthenticationContextListResponse,
    { userPK, branchPK, departmentPK }: ContextChangeSessionData,
    canSkip?: boolean
  ) {
    this.userPK = userPK;
    this.originalBranchKey = branchPK;
    this.originalDepartmentKey = departmentPK;
    this.canSkip = canSkip;

    this.branchKey = ko.observable(this.originalBranchKey);
    this.departmentKey = ko.observable(this.originalDepartmentKey);

    const sorter = (a: AuthenticationLocationInfo, b: AuthenticationLocationInfo): number =>
      a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    this.branches = ko.observableArray(contextData.branchInfos.sort(sorter));
    this.departments = ko.pureComputed(() =>
      contextData.contexts
        .reduce<AuthenticationLocationInfo[]>((result, context) => {
          if (context.branchKey === this.branchKey()) {
            const matchedDepartment = contextData.departmentInfos.find((d) => d.key === context.departmentKey);
            if (matchedDepartment) {
              result.push(matchedDepartment);
            }
          }
          return result;
        }, [])
        .sort(sorter)
    );

    this.notifications = new Notifications();
    this.notificationSummary = new NotificationSummary(this);

    this.hasChanged = ko.pureComputed(
      () => this.originalBranchKey !== this.branchKey() || this.originalDepartmentKey !== this.departmentKey()
    );
    this.canSave = ko.pureComputed(() => this.hasChanged() && !this.notifications.hasAlerts());

    this.branchKey.subscribe(() => this.notifications.removeAll());
    this.departmentKey.subscribe(() => this.notifications.removeAll());
  }

  async changeContextAsync(): Promise<boolean> {
    this.deferred = new DeferredPromise();
    /*! SuppressStringValidation CSS */
    const resultSave = "save";
    const buttonOptions = [
      {
        caption: dialogService.buttonTypes.Cancel().text,
        result: dialogService.buttonTypes.Cancel().value,
        bindingString: "click: cancel.bind($data)",
        isDismiss: true,
      },
      {
        caption: captionService.getString("4946F7A3-CD91-46F1-9C5D-9320349940F0", "Save"),
        result: resultSave,
        bindingString: "asyncClick: saveAsync.bind($data), css: { disabled: !canSave }, enable: canSave",
        isPrimary: true,
      },
    ];

    if (materialDesignDialogService.canShowChangeBranchDepartmentDialog()) {
      materialDesignDialogService.showChangeBranchDepartmentDialogAsync(this);
    } else {
      /*! SuppressStringValidation CSS */
      const dialogCss = "g-dialog-change-branch-department";
      this.dialogInfo = await dialogService.showDialogAsync({
        viewModel: this,
        title: captionService.getString("62D6A0E5-09E8-4C1B-ABF0-ABDFA62C1E06", "Select Branch and Department"),
        bodyAllowHtml: true,
        bodyDeferred: loadTemplateAsync("ChangeBranchAndDepartment.html"),

        dialogCss,
        closeOnDismissOnly: true,
        buttonOptions,
        includeValidationSummary: !!this.notificationSummary,
      });
    }

    return await this.deferred.promise;
  }

  async saveAsync(): Promise<void> {
    if (!this.canSave.peek()) {
      return;
    }
    try {
      await credentials.selectUserContextAsync(this.userPK, this.branchKey.peek(), this.departmentKey.peek());
      // dialog hidden on successful save,
      // if the new token fails context security check (in the same promise chain),
      // we can show a new dialog again without overlapping the one shown before.
      dialogService.hide(this.dialogInfo);
      this.deferred?.resolve(true);
    } catch (error) {
      if (error instanceof AuthenticationError) {
        if (error.authenticationResult === AuthenticationResult.ContextInsufficientPrivileges) {
          this.notifications.push({
            Level: NotificationType.Error,
            propertyName: ".",
            Text: captionService.getString(
              "c881635e-b016-4ffd-8c65-52ce1eee9351",
              "You do not have the security right to log in to this branch or department.\nYou may ask your system administrator to grant you this security right.\n\nThis particular security right is called 'Login Branches and Departments', and is found at the bottom of the Security Tree View on the Staff form and Group form."
            ),
            caption: captionService.getString("094c93df-f155-4179-9e86-833314aa3228", "Security Rights Denied"),
          });
          return;
        } else {
          await dialogService.alertAsync(
            NotificationType.Error,
            captionService.getString(
              "e4e307b8-8a19-44aa-9430-eaabe8cf34e9",
              "An unexpected error occurred while changing branch and department. Please try again. You may need to reenter your credentials."
            ),
            captionService.getString("ee11b39a-e5dd-4a36-b48a-53a333467f5e", "Error Changing Branch and Department")
          );
          this.reloadPage();
        }
      } else {
        throw error;
      }
    }
  }

  cancel(): void {
    this.canSkip ? this.deferred?.resolve(false) : this.reloadPage();
  }

  reloadPage(): void {
    windowManager.clearListeners();
    windowManager.closeChildWindows();
    navigationService.reloadPage();
  }
}

export const credentials = new CredentialAuthenticationService();
export const session = new SessionAuthenticationService();
