import captionService from 'CaptionService';
import constants from 'Constants';
import materialDesignVueDialogService from 'MaterialDesignVueDialogService';
import {
	loadWorkflowAuditLogsViewModelAsync,
	loadWorkflowEventsViewModelAsync,
	loadWorkflowMilestonesViewModelAsync,
	loadWorkflowTriggersViewModelAsync,
} from 'ModuleLoader';

class MaterialDesignWorkflowDialogService {
	constructor() {}

	showAuditLogsAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowAuditLogsViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowAuditLogs.Shell,
			captionService.getString('f243cbba-f45b-4ce2-9b1e-be51ff13c14f', 'Change Logs')
		);
	}

	showEventsAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowEventsViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowEvents.Shell,
			captionService.getString('ca84aa56-3611-48b3-a084-b94269025838', 'Events')
		);
	}

	showMilestonesAsync(entity, saveableViewModel) {
		const captions = {
			lastEventLabel: captionService.getString(
				'4c29e36d-3437-498c-890b-0a26e0c53ceb',
				'Last Event'
			),
			eventAt: captionService.getString('bc0c5abd-18cd-4e57-a2ae-26e62fb6463c', ' at '),
		};
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowMilestonesViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowMilestones.Shell,
			captionService.getString('180d2e9f-4687-4dc2-aefb-7e675bd6dae3', 'Milestones'),
			captions
		);
	}

	showWorkflowTriggersAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowTriggersViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowTriggers.Shell,
			captionService.getString('0bfc24de-d96e-4db0-b762-942b94a0a247', 'Triggers')
		);
	}

	canShowDialog() {
		return materialDesignVueDialogService.canShowDialog();
	}
}

async function showWorkflowEntityDialogAsync(
	entity,
	saveableViewModel,
	loadWorkflowEntityAsync,
	template,
	title,
	captions
) {
	const state = {};
	const result = await trySaveIfRequired(saveableViewModel);
	if (result) {
		const viewModelLoader = await loadWorkflowEntityAsync();
		const viewModel = createViewModel(entity, viewModelLoader, state);
		return await materialDesignVueDialogService.showDialogAsync(
			entity,
			state,
			template,
			viewModel,
			title,
			captions
		);
	}
}

function trySaveIfRequired(saveableViewModel) {
	if (saveableViewModel) {
		return saveableViewModel.saveWithPromptAsync();
	}
	return Promise.resolve(true);
}

function createViewModel(entity, ViewModelCtor, state) {
	return new ViewModelCtor(entity.entityType, entity.entityAspect.getPrimaryKey(), {
		onCloseCallback() {
			materialDesignVueDialogService.hideDialogVue(state);
		},
	});
}

export default new MaterialDesignWorkflowDialogService();
