export function joinUri(...values: string[]): string {
  let result = values[0] || "";

  for (let i = 1; i < values.length; i++) {
    const right = values[i];
    if (right) {
      const leftHasSlash = result.endsWith("/");
      const rightHasSlash = right.startsWith("/");

      if (leftHasSlash && rightHasSlash) {
        result += right.substring(1);
      } else if (leftHasSlash || rightHasSlash) {
        result += right;
      } else {
        result += "/" + right;
      }
    }
  }

  return result;
}

export function isAbsoluteUri(uri: string): boolean {
  const r = /^(?:[a-z]+:)?\/\//i;
  return r.test(uri);
}

export function getQueryStringValue(url: string, paramName: string): string | null {
  const validUrl = getValidUrl(url);

  let result = validUrl?.searchParams.get(paramName) || null;
  if (!result) {
    const partOfUrl = validUrl ? validUrl.hash : url;
    const decodePartOfUrl = decodeURIComponent(partOfUrl);
    const index = decodePartOfUrl.indexOf("?");
    if (index > -1) {
      const searchParams = new URLSearchParams(decodePartOfUrl.substring(index + 1));
      result = searchParams.get(paramName);
    }
  }

  return result;
}

export function getQueryStringValueInHash(url: string, paramName: string): string | null {
  const urlObj = getValidUrl(url);

  if (urlObj) {
    const { searchParams } = getSearchParamsInHash(urlObj);
    return searchParams.get(paramName);
  }
  return urlObj;
}

export function setQueryStringValueInHash(url: string, paramName: string, paramValue: string): URL | null {
  const urlObj = getValidUrl(url);

  if (urlObj) {
    const { index, searchParams } = getSearchParamsInHash(urlObj);
    searchParams.set(paramName, paramValue);

    if (index > -1) {
      urlObj.hash = urlObj.hash.substring(0, index);
    }

    urlObj.hash = urlObj.hash + "?" + searchParams.toString();
  }
  return urlObj;
}

function getSearchParamsInHash(url: URL): { searchParams: URLSearchParams; index: number } {
  const index = url.hash.indexOf("?");
  const searchParams = new URLSearchParams(index > -1 ? decodeURIComponent(url.hash.substring(index + 1)) : undefined);
  return { searchParams, index };
}

function getValidUrl(url: string): URL | null {
  if (!url) {
    return null;
  }

  try {
    return new URL(url);
  } catch (error) {
    return null;
  }
}

export function getRouteName(serviceUri: string): string {
  const match = serviceUri.match(/([^/]+)(\/)*$/);
  const routeName = match && match[1];
  if (!routeName) {
    throw new Error('Could not determine route for service "' + serviceUri + '".');
  }

  return routeName;
}

type UriPathAndParams = {
  path: string;
  params: Record<string, string>;
};

export function getUriPathAndParams(uri: string, base: string): UriPathAndParams {
  const url = new URL(uri, base);
  const params: Record<string, string> = {};
  const queryInHashIndex = url.hash ? url.hash.indexOf("?") : -1;
  let hash = "";
  if (queryInHashIndex > -1) {
    const searchParams = new URLSearchParams(url.hash.substring(queryInHashIndex + 1));
    searchParams.forEach((value, key) => (params[key] = value));
    hash = url.hash.substring(0, queryInHashIndex);
  } else {
    hash = url.hash;
    url.searchParams.forEach((value, key) => (params[key] = value));
  }

  return { path: url.origin + url.pathname + hash, params };
}
