import captionService from "CaptionService";

export const jobAddress = {
  additionalInfoLabel: (): string =>
    captionService.getString("50fef19c-65b6-45c4-a88e-5d61d038a22b", "Additional information"),
  address2FieldLabel: (): string => captionService.getString("633eddb8-5f92-4dcb-9ba5-aee1e821383f", "Address 2"),
  addressFieldLabel: (): string => captionService.getString("94e22d00-b8a0-4f44-97d0-b0324e78e12d", "Address"),
  addressTab: (): string => captionService.getString("20dd3a36-51ab-42a2-b7b6-e4d238d807f0", "Address"),
  cityFieldLabel: (): string => captionService.getString("7b568394-19cf-4777-94f5-8c588ac60bf0", "City"),
  companyNameLabel: (): string => captionService.getString("33bd4ac7-4854-424a-a69c-67ff28fc3685", "Company"),
  contactFieldLabel: (): string => captionService.getString("a8daa02c-11c2-4ff3-8079-5d017993ff54", "Name"),
  contactTab: (): string => captionService.getString("645f1388-c99e-496a-8cab-3b94d180f8fb", "Contact"),
  countryFieldLabel: (): string => captionService.getString("15d682bc-27b9-49f1-a3ff-45890aaa1d01", "Country/Region"),
  edit: (): string => captionService.getString("b1e7f181-448c-49a2-97ff-205f9bb06c34", "Edit"),
  emailFieldLabel: (): string => captionService.getString("47851faf-b720-4115-971e-fa1b066d3f77", "Email"),
  mobileFieldLabel: (): string => captionService.getString("cecf165d-d971-4669-a0b1-9ad02cd39a82", "Mobile"),
  phoneFieldLabel: (): string => captionService.getString("acb559e2-659c-4c51-850c-4f2f21dee08d", "Phone"),
  noAddress: (): string => captionService.getString("4646575b-6276-43b8-ae08-23e3ade64d51", "No address supplied."),
  noContactDetails: (): string =>
    captionService.getString("137f91fe-8f64-472b-a2f1-a15f010e0b20", "No contact details supplied."),
  postcodeFieldLabel: (): string => captionService.getString("b19b355e-0b3f-468c-95ca-1628424acd8e", "Postcode"),
  stateFieldLabel: (): string => captionService.getString("e4b118a5-a8d3-467a-bf1a-87fc73f45f73", "State"),
  streetAltFieldLabel: (): string => captionService.getString("d26f32fa-5293-4ea3-a804-0e0fd46fcdd6", "Street (alt)"),
  streetFieldLabel: (): string => captionService.getString("3516e2f3-6799-43b8-b5bc-6ed9739cd6ac", "Street"),
};
