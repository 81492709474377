import captionService from 'CaptionService';

export const searchList = {
	addRow: () => captionService.getString('b2db7584-dead-45db-a68e-d7a4f154e468', 'Add row'),
	availableHeaders: () => captionService.getString('0480766d-2564-479a-92bf-a709bf6d2859', 'Available columns'),
	cancel: () => captionService.getString('dd7af1a0-fede-48e9-9596-5dd96b2c7189', 'Cancel'),
	closeGroup: () => captionService.getString('ed69d31d-be93-456a-b617-752351b2b161', 'Close Group'),
	configureHeaders: () => captionService.getString('d8aadcc8-bdfd-44d4-a832-3d3ffabd6f77', 'Configure Columns'),
	count: (params) => captionService.getString('2fc20985-5cc4-4dc0-82db-f7c4474ffaa7', 'Count: {0}', ...params),
	delete: () => captionService.getString('def97dff-fc88-4c1b-b9a5-3696bb455f38', 'Delete'),
	empty: () => captionService.getString('ee919c85-df97-450a-a7a8-c39cccb75570', '(empty)'),
	enableHeaderSizing: () => captionService.getString('e88ff2be-e397-4605-a65f-2aa718c62e3e', 'Enable header sizing'),
	firstUse: {
		header: () => captionService.getString('5b3e7d33-94d9-4b2b-8795-4033a4b19829', 'Nothing to show yet'),
		bodycopy: () => captionService.getString('db7d314b-f6f2-48ba-b395-e8cddf0fef0a', 'Add a row to start populating the table'),
	},
	groupBy: () => captionService.getString('25a6b781-eb49-4713-aed6-a2b145b0bb48', 'Group By'),
	headers: () => captionService.getString('f8962bf8-2705-43c5-8946-10f55f069ef7', 'Columns'),
	hideAggregateAria: () => captionService.getString('9870c5ed-356c-471f-9cf0-9e9c349da719', 'Hide Aggregations'),
	item: () => captionService.getString('ef0ea901-c10f-45da-82bf-353f58436f25', '1 item'),
	itemActions: () => captionService.getString('0f86fa9f-6955-40e1-a9d5-f5b520513b2d', 'Item Actions'),
	items: (params) => captionService.getString('b143b582-258e-4028-834c-977cf856b2b4', '{0} items', ...params),
	lock: () => captionService.getString('edc226a2-6b8c-4e3e-8cd6-a7abd327b9e0', 'Lock'),
	new: () => captionService.getString('619ba5c7-6f84-44e4-8e95-6d9ca24726f6', 'New'),
	noData: {
		header: () => captionService.getString('a43345aa-5515-468d-9c1a-34b380c1681d', 'Your search returned nothing'),
		bodycopy: () => captionService.getString('c316323f-d5c4-43ae-b589-971571d16ad5', 'Your search result found no data, try widening your search parameters'),
		buttonLabel: () => captionService.getString('54cde92a-45ac-4a19-93a5-57ed5d1ff4c5', 'Search'),
	},
	noItemsFound: () => captionService.getString('9386f1cf-20e6-4180-ab16-727cadfb9f83', 'No items found'),
	refresh: () => captionService.getString('23799a5c-9d3f-4e15-8263-d3364da48712', 'Refresh'),
	save: () => captionService.getString('196c5dba-f4f0-430f-94eb-bb99de79f33c', 'Save'),
	search: () => captionService.getString('ced1ccd9-7c0b-4886-9d10-2fd0b090cd04', 'Search'),
	selectedHeaders: () => captionService.getString('98e95f3a-4d82-4603-8d04-d51620971bd9', 'Selected columns'),
	settings: () => captionService.getString('8becc071-d81c-453a-b6a3-7f10984a92c2', 'Settings'),
	showAggregateAria: () => captionService.getString('2d895d5d-1388-47b0-9761-d2065416a59c', 'Show Aggregations'),
	sort: () => captionService.getString('66d23192-1fce-49c4-a51b-8144cd49dd15', 'Sort'),
	stopGrouping: () => captionService.getString('16a2a087-f397-4878-bbdd-570238093ee6', 'Stop Grouping'),
	toggleSort: () => captionService.getString('b2f458c1-3b09-4720-a76c-1f92304e88aa', 'Toggle sort'),
	openGroup: () => captionService.getString('4acd8ae7-70f1-4b3e-b493-cd63944bbe97', 'Open Group'),
	toggleFilterAria: () => captionService.getString('bbb090ec-834e-4ae4-aa0a-7c7db606c05a', 'Toggle filter'),
	unlock: () => captionService.getString('d373d2a5-9489-45aa-9a5a-4c0939c63ddf', 'Unlock'),
	wrap: () => captionService.getString('fecc4465-b7f0-400b-a1d1-febc75508c36', 'Wrap'),
};
