import ajaxService, { AjaxError } from "AjaxService";
import { AuthenticationClaimType } from "AuthenticationClaimType";
import { AuthenticationError, AuthenticationErrorWithClaimResponse } from "AuthenticationError";
import { type PartiallySuccessfulClaimResponse, type UserInfo } from "AuthenticationResponse";
import { AuthenticationResult } from "AuthenticationResult";
import { credentials, session, type SuccessfulBeginSessionResult } from "AuthenticationService";
import { type AuthenticationSource } from "AuthenticationSource";
import captionService from "CaptionService";
import connection from "Connection";
import { WellknownType } from "Constants";
import dataService from "DataService";
import dialogService from "DialogService";
import { createEntityManagerForRouteAsync } from "EntityManagerExtensions";
import { createQuery, type GlowEntityQuery } from "EntityQueryExtensions";
import entitySetRightsProvider, { type EntitySetRightsResponse } from "EntitySetRightsProvider";
import errorReportingService from "ErrorReportingService";
import { tryCompleteExternalLogonAsync, tryHandleThirdPartyLogon } from "ExternalLogonHandler";
import global from "Global";
import log from "Log";
import type { LogonProviderType } from "LogonProviderType";
import nativeBridge from "NativeBridge";
import { NotificationType } from "NotificationType";
import resStringRepository from "ResStringRepository";
import serverMessageQueue from "ServerMessageQueue";
import { isSessionLimitError, tryHandleSessionLimitErrorAsync } from "SessionLimitReachedHandler";
import userSession, { type LatLng, type SessionData, type User } from "UserSession";
import { UserType } from "UserTypeConstants";
import windowManager from "WindowManager";
import wellknown from "wellknown";
import {
  typeOfIGlbBranchInfo,
  typeOfIGlbDepartmentInfo,
  typeOfIGlbPerson,
  typeOfIGlbStaff,
  typeOfIOrgContactInfo,
  type IRefUNLOCOInfo,
} from "wtg-entity-type-definitions";

interface QueryableUserInfo {
  identityKey: string;
  identityProvider: string;
  userContextBranchKey: string;
  userContextDepartmentKey: string;
}

interface UserDetails {
  code: string;
  details: User;
}

class LogonService {
  async logOffAsync(): Promise<void> {
    log.withTag("UserSession").info("LogonService.logOffAsync");
    const { isAuthenticated, sessionId } = userSession.sessionData();

    try {
      if (sessionId) {
        await session.destroyAsync();
      }
      if (isAuthenticated) {
        await credentials.revokeAsync();
      }
      stopNativeAppGPSTracking();
    } catch (error) {
      // ignore
    } finally {
      await userSession.clearSessionAsync();
    }
  }

  async tryResumeAsync(): Promise<void> {
    log.withTag("UserSession").info("LogonService.tryResumeAsync");

    try {
      await this.tryResumeExistingSessionOrStartSSOSessionAsync();
    } catch (error) {
      if (isOfflineError(error)) {
        throw error;
      }
      const authenticationInfo = await tryCompleteExternalLogonAsync();
      try {
        if (!authenticationInfo) {
          return;
        }

        const result = await beginSessionWithRetryAsync();
        try {
          await this.logOnWithUserInfoAsync(result.userInfo, authenticationInfo.authenticationSource);
        } catch (e) {
          await revokeTokenAsync();
          await destroySessionAsync();
          throw e;
        }
      } catch (e) {
        await clearSessionAsync();
        throw e;
      } finally {
        const url = new URL(global.windowLocation.href);
        /*! SuppressStringValidation state is a query key */
        const state = url.searchParams.get("state");
        const stateObject = state ? (JSON.parse(state) as { hash: string }) : undefined;
        const hash = stateObject?.hash ?? "";
        global.windowLocation.href = global.getModuleUrl() + hash;
      }
    }
  }

  async changeUserContextAsync(canSkip: boolean): Promise<boolean> {
    log.withTag("UserSession").info("LogonService.changeUserContextAsync");

    const sessionData = userSession.sessionData();
    const { sessionId } = sessionData;
    if (!sessionData.userPK) {
      throw new Error("Cannot change user because the session data does not contain a user key.");
    }

    const contextChanged = await session.changeContextAsync(
      {
        branchPK: sessionData.branchPK ?? undefined,
        departmentPK: sessionData.departmentPK ?? undefined,
        userPK: sessionData.userPK,
      },
      canSkip
    );
    if (!contextChanged) {
      return false;
    }

    await destroySessionAsync();
    await clearSessionAsync();

    try {
      const result = await session.beginAsync(
        AuthenticationClaimType.LocalToken,
        await nativeBridge.getSessionTypeAsync(),
        sessionId
      );

      try {
        await this.logOnWithUserInfoAsync(result.userInfo, sessionData.authenticationSource);
      } catch (e) {
        await destroySessionAsync();
        throw e;
      }
    } catch (e) {
      await revokeTokenAsync();
      await clearSessionAsync();
      throw e;
    }

    return true;
  }

  async logOnWithUserInfoAsync(
    userInfo: UserInfo,
    authenticationSource: AuthenticationSource | undefined
  ): Promise<void> {
    log.withTag("UserSession").info("LogonService.logOnWithUserInfoAsync");
    const [licenceCode, userDetails, moduleAccess, entitySetRights] = await Promise.all([
      getLicenceCodeAsync(userInfo.identityProvider),
      getUserDetailsAsync(userInfo),
      getModuleAccessAsync(),
      getEntitySetRightsAsync(),
    ]);
    await loadResourceStringsAsync(userDetails.details.languageCode);
    storeSessionDetails({
      userInfo,
      licenceCode,
      userDetails,
      moduleAccess,
      entitySetRights,
      authenticationSource,
    });
    startNativeAppGPSTracking(userInfo);

    await Promise.all([(serverMessageQueue.resumeAsync(), errorReportingService.sendPendingErrorReportsAsync())]);
  }

  async logOnAsync(logonProviderType: LogonProviderType, userName: string, password: string): Promise<void> {
    log.withTag("UserSession").info("LogonService.logOnAsync");

    const serialNumber = nativeBridge.nativeClientSerialNumber;
    const deviceType = nativeBridge.nativeClientDeviceType;
    let authenticationInfo = null;

    if (global.featureFlags.forceAndroidAppUpgradetoLatest && !nativeBridge.isCurrentVersionSupported()) {
      return;
    }

    try {
      authenticationInfo = await credentials.claimAsync(
        logonProviderType,
        userName,
        password,
        serialNumber,
        deviceType
      );
    } catch (error) {
      if (
        error instanceof AuthenticationErrorWithClaimResponse &&
        error.authenticationResult === AuthenticationResult.ThirdPartyUserValidationRequired
      ) {
        tryHandleThirdPartyLogon(error.response);
      }
      throw error;
    }

    try {
      const result = await session.beginAsync(
        AuthenticationClaimType.LocalToken,
        await nativeBridge.getSessionTypeAsync()
      );
      try {
        await this.logOnWithUserInfoAsync(result.userInfo, authenticationInfo.authenticationSource);
      } catch (e) {
        await destroySessionAsync();
        throw e;
      }
    } catch (error) {
      await revokeTokenAsync();
      await clearSessionAsync();
      throw error;
    }
  }

  async challengeAsync(authenticationSource: AuthenticationSource): Promise<void> {
    log.withTag("UserSession").info("LogonService.challengeAsync");

    const claimDetails = await credentials.challengeAsync(authenticationSource);
    tryHandleThirdPartyLogon(claimDetails);
  }

  private async tryResumeExistingSessionOrStartSSOSessionAsync(): Promise<void> {
    try {
      const sessionData = userSession.sessionData();
      const ssoTokenInfo = await this.checkAmbiguousLoginAsync(sessionData);
      const isNewUser = ssoTokenInfo?.isNewUser;
      const ssoToken = ssoTokenInfo?.ssoToken;

      let authenticationSource: AuthenticationSource | undefined;
      let result: SuccessfulBeginSessionResult | undefined;
      if (sessionData.isAuthenticated && !isNewUser) {
        if (shouldResumeSession(sessionData) && (await resumeSessionAsync(sessionData))) {
          if (ssoToken) {
            await revokeSingleSignOnTokenAsync(ssoToken);
          }
          return;
        }

        const { sessionId } = sessionData;
        const ignoreError = true;
        result = await beginSessionWithRetryAsync(sessionId, ignoreError);
        if (result) {
          ({ authenticationSource } = sessionData);
          if (ssoToken) {
            await revokeSingleSignOnTokenAsync(ssoToken);
          }
        } else if (ssoToken) {
          ({ authenticationSource, result } = await beginSingleSignOnSessionAsync(ssoToken, sessionId));
        }
      } else if (ssoToken) {
        ({ authenticationSource, result } = await beginSingleSignOnSessionAsync(ssoToken));
      }

      if (result) {
        try {
          await this.logOnWithUserInfoAsync(result.userInfo, authenticationSource);
        } catch (error) {
          await destroySessionAsync();
          throw error;
        }
      } else {
        throw new Error("Did not manage to begin session.");
      }
    } catch (error) {
      if (!isOfflineError(error)) {
        await clearSessionAsync();
      }
      throw error;
    }

    function shouldResumeSession(sessionData: SessionData): boolean {
      return !!(sessionData.sessionId && sessionData.entitySetRights);
    }

    async function resumeSessionAsync(sessionData: SessionData): Promise<boolean> {
      try {
        const userInfo = (await session.checkAsync()).userInfo;
        if (!userInfo || userInfo.identityKey !== sessionData.userPK) {
          throw new Error("Cannot resume session because the resumed identity key does not match session data.");
        }
        return true;
      } catch (error) {
        const isPotentialSessionExpiry =
          error instanceof AuthenticationError &&
          error.authenticationResult !== AuthenticationResult.SessionEvicted &&
          error.cause instanceof AjaxError &&
          error.cause.status === 401;

        if (isPotentialSessionExpiry) {
          return false;
        } else {
          throw error;
        }
      }
    }

    async function beginSingleSignOnSessionAsync(
      ssoToken: string,
      sessionId?: string | null
    ): Promise<{
      authenticationSource?: AuthenticationSource;
      result?: SuccessfulBeginSessionResult;
    }> {
      const ssoResult = await credentials.claimSsoTokenAsync(ssoToken);
      if (ssoResult) {
        const { authenticationSource } = ssoResult;
        const result = await beginSessionWithRetryAsync(sessionId, false);
        return { authenticationSource, result };
      }

      return {};
    }
  }

  private async checkAmbiguousLoginAsync(
    sessionData: SessionData
  ): Promise<{ isNewUser?: boolean; ssoToken?: string } | undefined> {
    const ssoToken = getSingleSignOnToken();
    if (!ssoToken) {
      return;
    }

    if (!sessionData.isAuthenticated || !sessionData.branchPK || !sessionData.departmentPK) {
      return { ssoToken };
    }

    const tokenUserInfo = await credentials.querySsoTokenAsync(ssoToken);
    if (!tokenUserInfo) {
      return;
    }

    if (!tokenUserInfo.branchKey || !tokenUserInfo.departmentKey || !hasUserInfoChanged(tokenUserInfo)) {
      return { ssoToken };
    }

    const { userDisplayName: newUserName, branchKey: newBranchKey, departmentKey: newDepartmentKey } = tokenUserInfo;

    const { userName, departmentCode, branchCode } = sessionData;

    const { departmentCode: newDepartmentCode, branchCode: newBranchCode } = await getBranchAndDepartmentCodesAsync(
      newBranchKey,
      newDepartmentKey
    );

    const loggedInUserInfo = `${
      userName || captionService.getString("68911c32-b5a2-3c3f-e713-d70b2a1d14ce", "another user")
    } (${departmentCode}/${branchCode})`;
    const newLoginUserInfo = `${newUserName} (${newDepartmentCode}/${newBranchCode})`;

    const message = captionService.getString(
      "17dcac98-3bc2-4f31-8535-6b21524ba148",
      "You are currently logged on as {0}. Do you want to log out from this session and log in as {1}?\nPlease note that should you choose to logout you will lose all your current work in other open tabs.",
      loggedInUserInfo,
      newLoginUserInfo
    );
    const title = captionService.getString("cd2ccdd9-ee90-4b03-80ce-d168d38eba26", "Ambiguous login");

    const confirmAsyncResult = await dialogService.yesNoConfirmAsync(message, title, {
      notificationType: NotificationType.Warning,
    });
    const shouldLogout = confirmAsyncResult === dialogService.buttonTypes.Yes().value;
    if (shouldLogout) {
      await this.logOffAsync();
      return { isNewUser: true, ssoToken };
    }

    await revokeSingleSignOnTokenAsync(ssoToken);
    return;

    function hasUserInfoChanged(tokenUserInfo: PartiallySuccessfulClaimResponse): boolean {
      return (
        tokenUserInfo.userKey !== sessionData.userPK ||
        tokenUserInfo.departmentKey !== sessionData.departmentPK ||
        tokenUserInfo.branchKey !== sessionData.branchPK
      );
    }

    async function getBranchAndDepartmentCodesAsync(
      branchKey: string,
      departmentKey: string
    ): Promise<{ branchCode: string | undefined; departmentCode: string | undefined }> {
      /*! SuppressStringValidation Global is a route name */
      const entityManager = await createEntityManagerForRouteAsync("Global");

      const branchQuery = createQuery(entityManager, typeOfIGlbBranchInfo)
        .where((x) => x.GB_PK, "==", branchKey)
        .select((x) => [x.GB_Code])
        .executeWithNoTrackingAsync();

      const departmentQuery = createQuery(entityManager, typeOfIGlbDepartmentInfo)
        .where((x) => x.GE_PK, "==", departmentKey)
        .select((x) => [x.GE_Code])
        .executeWithNoTrackingAsync();

      const [branchResult, departmentResult] = await Promise.all([branchQuery, departmentQuery]);
      return { branchCode: branchResult.results[0]?.GB_Code, departmentCode: departmentResult.results[0]?.GE_Code };
    }
  }
}

async function beginSessionWithRetryAsync(sessionId?: string | null): Promise<SuccessfulBeginSessionResult>;
async function beginSessionWithRetryAsync(
  sessionId: string | null | undefined,
  ignoreError: boolean
): Promise<SuccessfulBeginSessionResult | undefined>;
async function beginSessionWithRetryAsync(
  sessionId?: string | null,
  ignoreError: boolean = false
): Promise<SuccessfulBeginSessionResult | undefined> {
  const sessionType = await nativeBridge.getSessionTypeAsync();
  try {
    try {
      return await beginSessionAsync(sessionId);
    } catch (error) {
      if (isSessionLimitError(error)) {
        if (await tryHandleSessionLimitErrorAsync(error)) {
          return await beginSessionAsync(sessionId);
        } else {
          throw error;
        }
      } else if (!ignoreError || error instanceof connection.OfflineError) {
        throw error;
      } else {
        return undefined;
      }
    }
  } catch (error) {
    await revokeTokenAsync();
    throw error;
  }

  async function beginSessionAsync(sessionId: string | null | undefined): Promise<SuccessfulBeginSessionResult> {
    return await session.beginAsync(AuthenticationClaimType.LocalToken, sessionType, sessionId);
  }
}

async function destroySessionAsync(): Promise<void> {
  try {
    await session.destroyAsync();
  } catch (error) {
    // ignore
  }
}

async function clearSessionAsync(): Promise<void> {
  try {
    await userSession.clearSessionAsync();
  } catch (error) {
    // ignore
  }
}

async function revokeTokenAsync(): Promise<void> {
  try {
    await credentials.revokeAsync();
  } catch (error) {
    // ignore
  }
}

async function revokeSingleSignOnTokenAsync(ssoToken: string): Promise<void> {
  try {
    await credentials.revokeSsoTokenAsync(ssoToken);
  } catch (error) {
    // ignore
  }
}

function getSingleSignOnToken(): string | undefined {
  const window = global.getWindow();
  const url = new URL(window.location.href);

  let ssoToken = getAndRemoveToken(url.searchParams);
  if (!ssoToken) {
    ssoToken = getAndRemoveTokenFromHash(url);
  }

  if (ssoToken) {
    const { href } = url;
    windowManager.historyPushState({ href }, "", href);
  }

  return ssoToken ?? undefined;

  function getAndRemoveToken(searchParams: URLSearchParams): string | null {
    const paramName = "sso_otp";
    const token = searchParams.get(paramName);
    if (token) {
      searchParams.delete(paramName);
    }

    return token;
  }

  function getAndRemoveTokenFromHash(url: URL): string | null {
    const index = url.hash.indexOf("?");
    if (index > 0) {
      const searchParams = new URLSearchParams(url.hash.substring(index + 1));
      const token = getAndRemoveToken(searchParams);
      if (token) {
        const search = searchParams.toString();
        let hash = url.hash.substring(0, index);
        if (search) {
          hash += "?" + search;
        }
        url.hash = hash;
      }

      return token;
    }

    return null;
  }
}

async function loadResourceStringsAsync(languageCode: string | undefined): Promise<void> {
  if (languageCode) {
    await resStringRepository.loadStringsAsync(languageCode);
  }
}

function startNativeAppGPSTracking(userInfo: UserInfo): void {
  if (userInfo.identityProvider === UserType.Staff) {
    nativeBridge.performCommandAsync("startTracking");
  }
}

function stopNativeAppGPSTracking(): void {
  nativeBridge.performCommandAsync("stopTracking");
}

async function getLicenceCodeAsync(identityProvider: string): Promise<string> {
  if (identityProvider === UserType.Person) {
    return "";
  }
  const result = await dataService.getAsync<{ value: string }>("System/GetCurrentLicenceCode");

  if (result.value) {
    return result.value;
  } else {
    throw new AuthenticationError(
      AuthenticationResult.AbnormalFailure,
      captionService.getString(
        "6e977cfe-06a0-49db-a6ec-f5514678407f",
        "Login failed. Please check that your profile has an active Home Branch set, then try again."
      )
    );
  }
}

async function getModuleAccessAsync(): Promise<string[]> {
  /*! SuppressStringValidation API call */
  return await ajaxService.getAsync<string[]>(global.serviceUri + "api/modules/authorized");
}

async function getSingleAsync<T>(query: GlowEntityQuery<T>): Promise<T> {
  const { results } = await query.executeWithNoTrackingAsync();
  if (results.length !== 1) {
    throw new Error("Single record could not be loaded.");
  }
  return results[0];
}

async function getUserDetailsForStaffAsync(userInfo: QueryableUserInfo): Promise<UserDetails> {
  /*! SuppressStringValidation Global is a route name */
  const entityManager = await createEntityManagerForRouteAsync("Global");
  const staffQuery = createQuery(entityManager, typeOfIGlbStaff)
    .where((x) => x.GS_PK, "==", userInfo.identityKey)
    .select((x) => [
      x.GS_Code,
      x.GS_LoginName,
      x.GS_FullName,
      x.GS_FriendlyName,
      x.GS_EmailAddress,
      x.GS_Title,
      x.GS_WorkingLanguage,
      x.GS_IsController,
    ]);
  const branchQuery = createQuery(entityManager, typeOfIGlbBranchInfo)
    .where((x) => x.GB_PK, "==", userInfo.userContextBranchKey)
    .expand((x) => [x.HomePort])
    .select((x) => [x.GB_PK, x.GB_Code, x.GB_GC, x.HomePort.RL_RN_NKCountryCode, x.HomePort.RL_GeoLocation]);
  const departmentQuery = createQuery(entityManager, typeOfIGlbDepartmentInfo)
    .where((x) => x.GE_PK, "==", userInfo.userContextDepartmentKey)
    .select((x) => [x.GE_PK, x.GE_Code]);

  const [staff, branch, department] = await Promise.all([
    getSingleAsync(staffQuery),
    getSingleAsync(branchQuery),
    getSingleAsync(departmentQuery),
  ]);
  const countryCode = branch.HomePort?.RL_RN_NKCountryCode ?? "";
  const homeLocation = parseGeoLocation(branch.HomePort);

  return {
    code: staff.GS_Code,
    details: {
      userName: staff.GS_LoginName,
      friendlyName: staff.GS_FriendlyName,
      fullName: staff.GS_FullName,
      email: staff.GS_EmailAddress,
      title: staff.GS_Title,
      languageCode: staff.GS_WorkingLanguage,
      countryCode,
      isController: staff.GS_IsController,
      companyPK: branch.GB_GC,
      branchPK: branch.GB_PK,
      branchCode: branch.GB_Code,
      departmentPK: department.GE_PK,
      departmentCode: department.GE_Code,
      homeLocation,
    },
  };
}

async function getUserDetailsForContactAsync(userInfo: QueryableUserInfo): Promise<UserDetails> {
  /*! SuppressStringValidation Global is a route name */
  const entityManager = await createEntityManagerForRouteAsync("Global");
  const contactQuery = createQuery(entityManager, typeOfIOrgContactInfo)
    .expand((x) => [x.OrgHeader.ClosestPort])
    .where((x) => x.OC_PK, "==", userInfo.identityKey)
    .select((x) => [
      x.OC_ContactName,
      x.OC_Email,
      x.OC_Title,
      x.OC_OH,
      x.OC_Language,
      x.OrgHeader.OH_Code,
      x.OrgHeader.OH_FullName,
      x.OrgHeader.ClosestPort.RL_RN_NKCountryCode,
      x.OrgHeader.ClosestPort.RL_GeoLocation,
    ]);
  const branchQuery = createQuery(entityManager, typeOfIGlbBranchInfo)
    .where((x) => x.GB_PK, "==", userInfo.userContextBranchKey)
    .select((x) => [x.GB_PK, x.GB_Code]);
  const departmentQuery = createQuery(entityManager, typeOfIGlbDepartmentInfo)
    .where((x) => x.GE_PK, "==", userInfo.userContextDepartmentKey)
    .select((x) => [x.GE_PK, x.GE_Code]);

  const [contact, branch, department] = await Promise.all([
    getSingleAsync(contactQuery),
    getSingleAsync(branchQuery),
    getSingleAsync(departmentQuery),
  ]);
  const orgCode = contact.OrgHeader?.OH_Code.trim(); // For some reason OH_Code comes back with a bunch of spaces.
  const countryCode = contact.OrgHeader?.ClosestPort?.RL_RN_NKCountryCode ?? "";
  const homeLocation = parseGeoLocation(contact.OrgHeader?.ClosestPort);
  return {
    code: "ZZ", // Remove this when BPM types work without a GS reference.
    details: {
      userName: orgCode + "/" + contact.OC_Email,
      fullName: contact.OC_ContactName,
      email: contact.OC_Email,
      title: contact.OC_Title,
      orgPK: contact.OC_OH,
      orgCode,
      orgName: contact.OrgHeader?.OH_FullName,
      languageCode: contact.OC_Language,
      countryCode,
      branchPK: branch.GB_PK,
      branchCode: branch.GB_Code,
      departmentPK: department.GE_PK,
      departmentCode: department.GE_Code,
      homeLocation,
    },
  };
}

async function getUserDetailsForPersonAsync(userInfo: QueryableUserInfo): Promise<UserDetails> {
  /*! SuppressStringValidation Global is a route name */
  const entityManager = await createEntityManagerForRouteAsync("Global");
  const personQuery = createQuery(entityManager, typeOfIGlbPerson)
    .where((x) => x.PER_PK, "==", userInfo.identityKey)
    .select((x) => [x.PER_EmailAddress, x.PER_FullName]);
  const person = await getSingleAsync(personQuery);

  return {
    code: "ZZ",
    details: {
      userName: person.PER_EmailAddress,
      fullName: person.PER_FullName,
      email: person.PER_EmailAddress,
    },
  };
}

async function getUserDetailsAsync(userInfo: QueryableUserInfo): Promise<UserDetails> {
  let result;
  if (userInfo.identityProvider === UserType.Staff) {
    result = await getUserDetailsForStaffAsync(userInfo);
  } else if (userInfo.identityProvider === UserType.Person) {
    result = await getUserDetailsForPersonAsync(userInfo);
  } else {
    result = await getUserDetailsForContactAsync(userInfo);
  }
  return result;
}

async function getEntitySetRightsAsync(): Promise<EntitySetRightsResponse> {
  return await entitySetRightsProvider.loadAsync();
}

function storeSessionDetails({
  userInfo,
  licenceCode,
  userDetails,
  moduleAccess,
  entitySetRights,
  authenticationSource,
}: {
  userInfo: UserInfo;
  licenceCode: string;
  userDetails: UserDetails;
  moduleAccess: string[];
  entitySetRights: EntitySetRightsResponse;
  authenticationSource: AuthenticationSource | undefined;
}): void {
  const sessionData = {
    sessionId: userInfo.sessionId,
    userType: userInfo.identityProvider,
    userPK: userInfo.identityKey,
    userCode: userDetails.code,
    userName: userDetails.details.userName,
    userEmailAddress: userDetails.details.email,
    licenceCode,
    loggedOnUser: userDetails.details,
    moduleAccess,
    isAuthenticated: true,
    authenticationSource,
    languageCode: userDetails.details.languageCode || global.languageCode,
    entitySetRights,
    branchPK: userDetails.details.branchPK,
    branchCode: userDetails.details.branchCode ?? "",
    departmentPK: userDetails.details.departmentPK,
    departmentCode: userDetails.details.departmentCode ?? "",
    countryCode: userDetails.details.countryCode,
    homeLocation: userDetails.details.homeLocation,
  };

  userSession.saveSession(sessionData);
}

function isOfflineError(error: unknown): boolean {
  return error instanceof connection.OfflineError;
}

function parseGeoLocation(port: IRefUNLOCOInfo | null | undefined): LatLng | undefined {
  if (!port?.RL_GeoLocation) {
    return undefined;
  }

  const point = wellknown.parse(port.RL_GeoLocation);
  if (!point || point.type !== WellknownType.Point || point.coordinates.length !== 2) {
    return undefined;
  }

  return { lat: point.coordinates[1], lng: point.coordinates[0] };
}

export default new LogonService();
