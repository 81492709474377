import {
  EntityQuery,
  Predicate,
  type Entity as BreezeEntity,
  type EntityManager,
  type EntityType,
  type FilterQueryOpSymbol,
} from "breeze-client";
import { type DataPropertyType, type EntityTypeInfo } from "wtg-entity-type-definitions";
import { type EntityAdapter } from "./EntityAdapter.ts";

/*! StartNoStringValidationRegion Not user visible */
export function createPredicate<T>(
  pathSelector: (path: PathWithFunctions<T>) => TerminalPath,
  operator: BreezeLiteralOperator | FilterQueryOpSymbol,
  valueSelector: (path: PathWithFunctions<T>) => TerminalPath
): Predicate;
export function createPredicate<T>(
  pathSelector: (path: PathWithFunctions<T>) => TerminalPath,
  operator: BreezeLiteralOperator | FilterQueryOpSymbol,
  value: unknown
): Predicate;

export function createPredicate<T>(
  pathSelector: (path: PathWithFunctions<T>) => TerminalPath,
  operator: (string & BreezeLiteralOperator) | FilterQueryOpSymbol,
  valueOrSelector: unknown
): Predicate {
  const path = getPath(pathSelector);
  const value =
    typeof valueOrSelector === "function"
      ? getPath(valueOrSelector as (path: PathWithFunctions<T>) => TerminalPath)
      : valueOrSelector;

  if (typeof operator === "string") {
    return Predicate.create(path, operator, value);
  } else {
    return Predicate.create(path, operator, value);
  }
}

export function createBreezeQuery(entityManager: EntityManager, entityName: string): EntityQuery {
  const entityType = entityManager.metadataStore.getEntityType(entityName) as EntityType;
  return new EntityQuery(entityType.defaultResourceName).using(entityManager);
}

export function createQuery<T>(entityManager: EntityManager, entityTypeInfo: EntityTypeInfo<T>): GlowEntityQuery<T> {
  const entityType = entityManager.metadataStore.getEntityType(entityTypeInfo.name) as EntityType;
  return new GlowEntityQuery<T>(new EntityQuery(entityType.defaultResourceName).using(entityManager));
}

export class GlowEntityQuery<T> {
  private readonly query: EntityQuery;

  constructor(query: EntityQuery) {
    this.query = query;
  }

  toBreezeQuery(): EntityQuery {
    return this.query;
  }

  executeAsync(): Promise<QueryResult<Entity<T>>> {
    return this.query.execute() as unknown as Promise<QueryResult<Entity<T>>>;
  }

  executeLocally(): Entity<T>[] {
    return this.query.executeLocally() as unknown as Entity<T>[];
  }

  executeWithNoTrackingAsync(): Promise<QueryResult<T>> {
    return this.query.noTracking().execute() as unknown as Promise<QueryResult<T>>;
  }

  expand(pathSelector: (path: NavigationPath<T>) => TerminalPath[]): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.expand(getPaths(pathSelector)));
  }

  inlineCount(enabled?: boolean): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.inlineCount(enabled));
  }

  orderBy(pathSelector: (path: Path<T>) => TerminalPath[]): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.orderBy(getPaths(pathSelector)));
  }

  orderByDesc(pathSelector: (path: Path<T>) => TerminalPath[]): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.orderByDesc(getPaths(pathSelector)));
  }

  select(pathSelector: (path: Path<T>) => TerminalPath[]): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.select(getPaths(pathSelector)));
  }

  skip(count: number): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.skip(count));
  }

  take(count: number): GlowEntityQuery<T> {
    return new GlowEntityQuery(this.query.take(count));
  }

  where(predicate: Predicate): GlowEntityQuery<T>;
  where(
    pathSelector: (path: PathWithFunctions<T>) => TerminalPath,
    operator: BreezeLiteralOperator | FilterQueryOpSymbol,
    valueSelector: (path: PathWithFunctions<T>) => TerminalPath
  ): GlowEntityQuery<T>;
  where(
    pathSelector: (path: PathWithFunctions<T>) => TerminalPath,
    operator: BreezeLiteralOperator | FilterQueryOpSymbol,
    value: unknown
  ): GlowEntityQuery<T>;

  where(
    predicateOrPathSelector: Predicate | ((path: PathWithFunctions<T>) => TerminalPath),
    operator?: BreezeLiteralOperator | FilterQueryOpSymbol,
    valueOrSelector?: unknown
  ): GlowEntityQuery<T> {
    if (predicateOrPathSelector instanceof Predicate) {
      return new GlowEntityQuery(this.query.where(predicateOrPathSelector));
    } else {
      const path = getPath(predicateOrPathSelector);
      const value =
        typeof valueOrSelector === "function"
          ? getPath(valueOrSelector as (path: Path<T>) => TerminalPath)
          : valueOrSelector;

      if (typeof operator === "string") {
        return new GlowEntityQuery(this.query.where(path, operator, value));
      } else {
        return new GlowEntityQuery(this.query.where(path, operator!, value));
      }
    }
  }
}

function getPath<T>(pathSelector: (path: PathWithFunctions<T>) => TerminalPath): string {
  const paths = pathSelector(createPath<T>());
  return paths.toString();
}

function getPaths<T>(
  pathSelector: ((path: NavigationPath<T>) => TerminalPath[]) | ((path: Path<T>) => TerminalPath[])
): string[] {
  const paths = pathSelector(createPath<T>());
  return paths.map((p) => p.toString());
}

function createPath<T>(): NavigationPath<T> & Path<T> & PathWithFunctions<T> {
  return createPathCore("") as NavigationPath<T> & Path<T> & PathWithFunctions<T>;
}

function createPathCore(path: string): NavigationPath & Path & PathWithFunctions {
  return new Proxy(new PathProxyTarget(path), {
    get: getPropertyPath,
  });
}

function getPropertyPath(obj: PathProxyTarget, prop: string | symbol): unknown {
  if (prop in obj) {
    return (obj as unknown as Record<string | symbol, unknown>)[prop];
  }

  let path = obj.toString();
  if (path) {
    path += ".";
  }
  path += prop.toString();
  return createPathCore(path);
}

type QueryResult<T> = {
  results: T[];
  inlineCount?: number;
};

export type Entity<T = unknown> = EntityAdapter<BreezeEntity, T>;

type ElementType<T> = T extends (infer E)[] ? E : T;

type NavigationPath<T = unknown> = {
  readonly [K in keyof NavigationProperties<T>]-?: NavigationPath<ElementType<NonNullable<T[K]>>>;
} & TerminalPath;

type NavigationProperties<T> = Pick<T, NavigationPropertyKeys<T>>;

type NavigationPropertyKeys<T> = {
  [K in keyof T]: NonNullable<T[K]> extends DataPropertyType
    ? never
    : NonNullable<T[K]> extends Record<string, never>
      ? never
      : K;
}[keyof T];

type Path<T = unknown> = {
  readonly [K in keyof T]-?: PathReturnType<ElementType<NonNullable<T[K]>>>;
} & TerminalPath;

type PathReturnType<T> = T extends DataPropertyType ? TerminalPath : Path<T>;

type PathWithFunctions<T = unknown> = {
  readonly [K in keyof T]-?: PathWithFunctionsReturnType<ElementType<NonNullable<T[K]>>>;
} & TerminalPath;

type PathWithFunctionsReturnType<T> = T extends DataPropertyType
  ? T extends number
    ? TerminalPath & typeof numberFunctions
    : T extends Date
      ? TerminalPath & typeof dateFunctions
      : T extends string
        ? TerminalPath & typeof stringFunctions
        : TerminalPath
  : Path<T>;

type TerminalPath = {
  readonly toString: () => string;
};

type StringFunctions = TerminalPath & {
  indexOf(key: string): StringFunctions;
  length(): StringFunctions;
  substring(start: number, end?: number): StringFunctions;
  toLower(): StringFunctions;
  toUpper(): StringFunctions;
  trim(): StringFunctions;
};

const dateFunctions = {
  day(): TerminalPath {
    return `day(${this.toString()})`;
  },

  hour(): TerminalPath {
    return `hour(${this.toString()})`;
  },

  minute(): TerminalPath {
    return `minute(${this.toString()})`;
  },

  month(): TerminalPath {
    return `month(${this.toString()})`;
  },

  second(): TerminalPath {
    return `second(${this.toString()})`;
  },

  year(): TerminalPath {
    return `year(${this.toString()})`;
  },
};

const numberFunctions = {
  ceiling(): TerminalPath {
    return `ceiling(${this.toString()})`;
  },

  floor(): TerminalPath {
    return `floor(${this.toString()})`;
  },

  round(): TerminalPath {
    return `round(${this.toString()})`;
  },
};

const stringFunctions = {
  indexOf(key: string): StringFunctions {
    return new Proxy(new PathProxyTarget(`indexof(${this.toString()}, '${key}')`), {
      get: getPropertyPath,
    }) as unknown as StringFunctions;
  },

  length(): StringFunctions {
    return new Proxy(new PathProxyTarget(`length(${this.toString()})`), {
      get: getPropertyPath,
    }) as unknown as StringFunctions;
  },

  substring(start: number, end?: number): StringFunctions {
    const path = this.toString();
    return new Proxy(
      new PathProxyTarget(end ? `substring(${path}, ${start}, ${end})` : `substring(${path}, ${start})`),
      {
        get: getPropertyPath,
      }
    ) as unknown as StringFunctions;
  },

  toLower(): StringFunctions {
    return new Proxy(new PathProxyTarget(`tolower(${this.toString()})`), {
      get: getPropertyPath,
    }) as unknown as StringFunctions;
  },

  toUpper(): StringFunctions {
    return new Proxy(new PathProxyTarget(`toupper(${this.toString()})`), {
      get: getPropertyPath,
    }) as unknown as StringFunctions;
  },

  trim(): StringFunctions {
    return new Proxy(new PathProxyTarget(`trim(${this.toString()})`), {
      get: getPropertyPath,
    }) as unknown as StringFunctions;
  },
} as StringFunctions;

class PathProxyTarget {
  constructor(private readonly path: string) {}

  toString(): string {
    return this.path;
  }
}

Object.assign(PathProxyTarget.prototype, dateFunctions, numberFunctions, stringFunctions);

export type BreezeLiteralOperator =
  | "=="
  | "!="
  | ">"
  | "<"
  | ">="
  | "<="
  | "contains"
  | "substringof"
  | "startswith"
  | "endswith"
  | "any"
  | "some"
  | "all"
  | "every"
  | "in"
  | "isof";
/*! EndNoStringValidationRegion */
