import { NotificationType, compareNotificationTypes } from "NotificationType";
import { Notifications, type Alert } from "Notifications";
import { type Entity } from "breeze-client";
import ko, { type Computed, type Observable } from "knockout";

interface NotificationProvider {
  notifications: Notifications;
}

type RootEntityType =
  | Entity
  | NotificationProvider
  | Observable<Entity | undefined>
  | Observable<NotificationProvider | undefined>;

type EntitiesType =
  | Observable<Entity[]>
  | Observable<NotificationProvider[]>
  | Computed<Entity[]>
  | Computed<NotificationProvider[]>;

class NotificationSummary {
  readonly rootEntity: RootEntityType | null;
  readonly entities: EntitiesType;
  readonly all: Computed<Alert[]>;
  readonly errors: Computed<Alert[]>;
  readonly infos: Computed<Alert[]>;
  readonly warnings: Computed<Alert[]>;
  readonly hasAlerts: Computed<boolean>;
  readonly level: Computed<NotificationType>;
  private readonly entitiesDisposable?: Computed<Entity[]> | Computed<NotificationProvider[]>;

  constructor(rootEntity: RootEntityType);
  constructor(rootEntity: RootEntityType | null, entities: EntitiesType);
  constructor(rootEntity: RootEntityType | null, entities?: EntitiesType) {
    this.rootEntity = rootEntity;

    if (entities) {
      this.entities = entities;
    } else {
      const entitiesDisposable = ko.pureComputed(() => {
        const result = ko.unwrap<Entity | NotificationProvider | null | undefined>(rootEntity);
        return result ? [result] : [];
      }) as Computed<Entity[]> | Computed<NotificationProvider[]>;
      this.entities = entitiesDisposable;
      this.entitiesDisposable = entitiesDisposable;
    }

    this.all = ko.computed({ owner: this, read: this.getAll, deferEvaluation: true });
    this.errors = ko.computed({ owner: this, read: this.getErrors, deferEvaluation: true });
    this.infos = ko.computed({ owner: this, read: this.getInfos, deferEvaluation: true });
    this.warnings = ko.computed({
      owner: this,
      read: this.getWarnings,
      deferEvaluation: true,
    });
    this.hasAlerts = ko.pureComputed(this.getHasAlerts, this);
    this.level = ko.computed({ owner: this, read: this.getLevel, deferEvaluation: true });
  }

  dispose(): void {
    this.all.dispose();
    this.errors.dispose();
    this.infos.dispose();
    this.warnings.dispose();
    this.hasAlerts.dispose();
    this.level.dispose();
    this.entitiesDisposable?.dispose();
  }

  private getAll(): Alert[] {
    let result: Alert[] = [];
    const entities = this.entities() || [];

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const notifications = Notifications.get(entity);
      result = result.concat(notifications ? notifications.alerts() : new Notifications().alerts());
    }

    return result;
  }

  private getErrors(): Alert[] {
    return filter(this.all(), NotificationType.Error);
  }

  private getHasAlerts(): boolean {
    return this.all().length > 0;
  }

  private getInfos(): Alert[] {
    return filter(this.all(), NotificationType.Information);
  }

  private getLevel(): NotificationType {
    let result = NotificationType.None;
    const alerts = this.all();

    for (let i = 0; i < alerts.length; i++) {
      const alert = alerts[i];
      if (compareNotificationTypes(alert.Level, result) === 1) {
        result = alert.Level;
      }
    }

    return result;
  }

  private getWarnings(): Alert[] {
    return filter(this.all(), NotificationType.Warning);
  }
}

function filter(alerts: Alert[], notificationType: NotificationType): Alert[] {
  const result: Alert[] = [];

  for (let i = 0; i < alerts.length; i++) {
    const alert = alerts[i];
    if (alert.Level === notificationType) {
      result.push(alert);
    }
  }

  return result;
}

export default NotificationSummary;
