import Promise from 'bluebird';
import ko from 'knockout';
import breeze from 'breeze-client';

function EntityFunctionLookupProvider(lookupFunc, valuePath) {
	this.lookupFunc = lookupFunc;
	this.valuePath = valuePath;
}

EntityFunctionLookupProvider.prototype.isDataServiceQuery = true;

EntityFunctionLookupProvider.prototype.getLookupRecordsAsync = function (entity, queryDecorator, searchKeyword) {
	return getLookupRecordsCoreAsync(this, entity, searchKeyword).then((items) => {
		if (items.length && queryDecorator && items.some((e) => { return e.entityType; })) {
			return applyQueryDecoratorAsync(items, queryDecorator);
		}

		return { inlineCount: items.length, results: items };
	});
};

async function applyQueryDecoratorAsync(items, queryDecorator) {
	const entityType = getEntityType(items);
	const query = breeze.EntityQuery.fromEntities(items);

	const finalQuery = await queryDecorator(query, entityType);
	const results = finalQuery.entityManager.executeQueryLocally(finalQuery);

	if (!finalQuery.orderByClause && results.length > 1) {
		const orderMap = new Map(items.map((item, index) => [item, index]));
		results.sort((a, b) => orderMap.get(a) - orderMap.get(b));
	}

	return { inlineCount: results.length, results };
}

function getEntityType(items) {
	const result = items[0].entityType;
	for (let i = 1; i < items.length; i++) {
		if (items[i].entityType !== result) {
			throw new Error('Expected items in lookup func to have the same entity type.');
		}
	}

	return result;
}

EntityFunctionLookupProvider.prototype.getRecordAsync = function (entity, value) {
	const valuePath = this.valuePath;
	return getLookupRecordsCoreAsync(this, entity).then((items) => {
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (ko.unwrap(item[valuePath]) === value) {
				return item;
			}
		}
		return null;
	});
};

function getLookupRecordsCoreAsync(self, entity, searchKeyword) {
	return Promise.resolve(self.lookupFunc(entity, searchKeyword)).then((result) => result || []);
}

export default EntityFunctionLookupProvider;
