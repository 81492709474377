import bindingEvaluator from "BindingEvaluator";
import type { Entity } from "BreezeExtensions";
import type { DependencyResult } from "DependencyResult";
import { useBindingContext } from "VueHooks/BindingContextHook";
import { useKnockoutContext } from "VueHooks/KnockoutContextHook";
import ko from "knockout";
import { onUnmounted, ref, shallowRef, watch, type Ref } from "vue";
import { toValue, type MaybeRefOrGetter } from "vue3-polyfill";

type DataItemRef = Ref<Entity | null>;
type DataItemAndStateRef = Ref<DependencyResult<Entity | null> | null>;
type DataItemAndMaybeStateRef = DataItemRef | DataItemAndStateRef;
export type DataItemReturnType<B> = B extends true ? DataItemAndStateRef : DataItemRef;

export function useUltimateDataItem(bindingPath: MaybeRefOrGetter<string> | undefined): DataItemRef;
export function useUltimateDataItem<B extends boolean>(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  includeState?: B
): DataItemReturnType<B>;
export function useUltimateDataItem(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  includeState?: boolean
): DataItemRef | DataItemAndStateRef {
  const bindingContext = useBindingContext();
  const koContext = useKnockoutContext();

  const [ultimateDataItemRef, dispose] = getUltimateDataItemRef(bindingPath, bindingContext, koContext, includeState);

  onUnmounted(() => dispose());

  return ultimateDataItemRef;
}

export function getUltimateDataItemRef(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: ko.BindingContext | undefined
): [DataItemRef, () => void];
export function getUltimateDataItemRef<B extends boolean>(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: ko.BindingContext | undefined,
  includeState?: B
): [DataItemReturnType<B>, () => void];
export function getUltimateDataItemRef(
  bindingPath: MaybeRefOrGetter<string> | undefined,
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: ko.BindingContext | undefined,
  includeState?: boolean
): [DataItemAndMaybeStateRef, () => void] {
  if (!bindingContext || !bindingPath) {
    return [ref(null), (): void => {}];
  }

  const ultimateDataItemRef: DataItemAndMaybeStateRef = shallowRef(null);

  let computed: ko.Computed;
  const unwatch = watch(
    [(): MaybeRefOrGetter<Entity | null> | undefined => toValue(bindingContext), (): string => toValue(bindingPath)],
    ([bindingContext, bindingPath]) => {
      computed?.dispose();

      if (!bindingPath) {
        ultimateDataItemRef.value = null;
        return;
      }

      computed = ko.computed(() => {
        const dataItemWithState = bindingEvaluator.getUltimateDataItemWithState<Entity>(
          koContext,
          bindingContext,
          bindingPath
        );
        if (dataItemWithState != null) {
          ultimateDataItemRef.value = includeState ? dataItemWithState : dataItemWithState.value;
        }
      });
    },
    { immediate: true }
  );

  return [
    ultimateDataItemRef,
    (): void => {
      computed?.dispose();
      unwatch();
    },
  ];
}
