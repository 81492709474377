export class StorageError extends Error {
  override readonly name = "StorageError";
  readonly type: StorageErrorType;

  constructor(message: string, cause: unknown, type = StorageErrorType.Unknown) {
    super(message, { cause });
    this.type = type;
  }
}

export enum StorageErrorType {
  Unknown,
  Write,
}
