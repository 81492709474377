import { getAuthErrorMessageForResult, getDefaultAuthErrorMessage } from "AuthenticationErrorMessages";
import type { AuthenticationResult } from "AuthenticationResult";
import type { ErrorData } from "Errors";
import global from "Global";
import { NestedError } from "NestedError";
import type { BeginSessionResponse, ClaimResponse } from "./AuthenticationResponse.ts";

export class AuthenticationError extends NestedError {
  override name = "AuthenticationError";
  readonly authenticationResult: AuthenticationResult;

  constructor(authenticationResult: AuthenticationResult, message?: string, cause?: Error) {
    if (!message) {
      if (authenticationResult) {
        message = getAuthErrorMessageForResult(authenticationResult, global.appName);
      } else {
        message = getDefaultAuthErrorMessage();
      }
    }

    super(message, cause);
    this.authenticationResult = authenticationResult;
  }

  getData(): ErrorData[] {
    return [{ name: "AuthenticationResult", value: this.authenticationResult }];
  }
}

export class AuthenticationErrorWithBeginSessionResponse extends AuthenticationError {
  override readonly name = "AuthenticationErrorWithBeginSessionResponse";
  readonly response: BeginSessionResponse;

  constructor(response: BeginSessionResponse, message?: string, cause?: Error) {
    super(response.result, message, cause);
    this.response = response;
  }
}

export class AuthenticationErrorWithClaimResponse extends AuthenticationError {
  override readonly name = "AuthenticationErrorWithClaimResponse";
  readonly response: ClaimResponse;

  constructor(response: ClaimResponse, message?: string, cause?: Error) {
    super(response.result, message, cause);
    this.response = response;
  }
}
