import ruleFunction from "RuleFunction";
import ko, { type Subscription } from "knockout";
import { inject, onMounted, onUnmounted, provide } from "vue";

export type GetRootDataItem = () => unknown;

export type GetMessageBus = () => unknown;

export type ExtendComponents = (
  controlIds: ComponentExtenderControlIds,
  callback: ComponentExtenderCallback,
  dispose: ComponentExtenderDispose
) => void;

export type ComponentProxy = {
  invoke: (methodName: string, ...args: []) => unknown;
} | null;

type ComponentExtenderControlIds = (string | { id: string; optional: boolean })[];
type ComponentExtenderCallback = (...args: []) => Promise<void>;
type ComponentExtenderDispose = (...args: []) => Promise<void>;

type ComponentPrepare = () => void;
type ComponentMethod = (...args: never[]) => void;
export type ComponentMethods = Record<string, ComponentMethod>;

type Component = {
  methods: ComponentMethods;
  prepare?: ComponentPrepare;
};

enum ComponentExtenderState {
  Active = "ACTIVE",
  Pending = "PENDING",
}

type ComponentExtender = {
  controlIds: ComponentExtenderControlIds;
  callback: ComponentExtenderCallback;
  dispose: ComponentExtenderDispose;
  state: ComponentExtenderState;
  controlProxies?: ComponentProxy[];
};

export const formExtenderInjectionKey = Symbol("formExtender");

export function setFormExtender(extender: string, rootDataItem: unknown, messageBus: unknown): void {
  const extenderFn = ruleFunction(extender);

  const context = new FormExtenderContext();
  provide(formExtenderInjectionKey, context);

  const extendComponents: ExtendComponents = (controlIds, callback, dispose) => {
    context.extendComponents(controlIds, callback, dispose);
  };

  const getMessageBus: GetMessageBus = () => {
    return messageBus;
  };

  const initExtendersAsync = async (): Promise<void> => {
    const dataItem = ko.unwrap(rootDataItem);

    const getRootDataItem: GetRootDataItem = () => {
      return dataItem;
    };

    await extenderFn({
      extendComponents,
      getRootDataItem,
      getMessageBus,
    });
    context.activate();
  };

  let subscription: Subscription | null = null;

  onMounted(initExtendersAsync);

  onUnmounted(() => {
    subscription?.dispose();
    context.deactivate();
  });

  if (ko.isObservable(rootDataItem)) {
    subscription = rootDataItem.subscribe(() => {
      context.deactivate();
      initExtendersAsync();
    });
  }
}

export function useFormExtender(id: string, component: Component): void {
  const context = inject<FormExtenderContext | undefined>(formExtenderInjectionKey, undefined);
  if (context) {
    onMounted(() => context.registerComponent(id, component));
    onUnmounted(() => context.unregisterComponent(id));
  }
}

class FormExtenderContext {
  private component = new Map<string, Component>();
  private componentExtenders: ComponentExtender[] = [];
  private isActive = false;

  activate(): void {
    this.initExtenders();
    this.isActive = true;
  }

  deactivate(): void {
    this.disposeExtenders();
    this.componentExtenders = [];
    this.isActive = false;
  }

  registerComponent(id: string, component: Component): void {
    this.component.set(id, component);

    if (this.isActive) {
      this.initExtenders();
    }
  }

  unregisterComponent(id: string): void {
    this.disposeExtenders(id);
    this.component.delete(id);
  }

  extendComponents(
    controlIds: ComponentExtenderControlIds,
    callback: ComponentExtenderCallback,
    dispose: ComponentExtenderDispose
  ): void {
    const extender = {
      controlIds,
      callback,
      dispose,
      state: ComponentExtenderState.Pending,
    };
    this.componentExtenders.push(extender);

    if (this.isActive) {
      this.initExtenders();
    }
  }

  private initExtenders(): void {
    for (const extender of this.componentExtenders) {
      if (extender.state === ComponentExtenderState.Pending) {
        this.initExtenderAsync(extender);
      }
    }
  }

  private async initExtenderAsync(extender: ComponentExtender): Promise<void> {
    const controlProxies: ComponentProxy[] = [];

    for (const controlId of extender.controlIds) {
      let id: string,
        optional = false;
      if (typeof controlId === "string") {
        id = controlId;
      } else {
        ({ id, optional } = controlId);
      }
      const component = this.component.get(id);
      if (component) {
        const controlProxy = this.createProxy(component);
        controlProxies.push(controlProxy);
      } else if (optional) {
        controlProxies.push(null);
      } else {
        // We'll wait
        return;
      }
    }

    extender.controlProxies = controlProxies;
    extender.state = ComponentExtenderState.Active;
    await extender.callback(...(controlProxies as []));
  }

  private disposeExtenders(id?: string): void {
    for (const extender of this.componentExtenders) {
      if (extender.state === ComponentExtenderState.Active && (!id || extender.controlIds.includes(id))) {
        this.disposeExtenderAsync(extender);
      }
    }
  }

  private async disposeExtenderAsync(extender: ComponentExtender): Promise<void> {
    await extender.dispose(...(extender.controlProxies as []));
    extender.state = ComponentExtenderState.Pending;
  }

  private createProxy(component?: Component): ComponentProxy {
    let prepare = component?.prepare;

    function invoke(methodName: string, ...args: []): unknown {
      if (prepare) {
        prepare();
        prepare = undefined;
      }
      if (component?.methods[methodName]) {
        return component.methods[methodName](...args);
      }
      throw new Error(`No such method '${methodName}'.`);
    }

    return { invoke };
  }
}
