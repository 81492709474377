import captionService, { type ResourceInfo } from "CaptionService";
import { canShowWorkflow } from "EntityActionsChecker";
import { getPrimaryKey } from "EntityExtensions";
import { observeHasChanges } from "EntityManagerExtensions";
import type { EntitySaveResult } from "EntitySaveService";
import FooterTransitionMenuViewModel from "FooterTransitionMenuViewModel";
import type { ExpressionData } from "FormFlowExpressionDataBuilder";
import type { FooterTransition, FormFlowAction } from "FormFlowTypes";
import { lazyObservable, type ExtendedComputed } from "KnockoutExtensions";
import macroUtils from "MacroUtils";
import type NotificationSummary from "NotificationSummary";
import type { PageExtensions } from "PageExtensions";
import type { BasePageViewModel } from "PageViewModel";
import type { MessageBar, default as RunAsClientMessageBarProvider } from "RunAsClientMessageBarProvider";
import { getTransformedTitle } from "TitleService";
import WorkflowMenuViewModel from "WorkflowMenuViewModel";
import type { Entity } from "breeze-client";
import ko, { type Observable, type PureComputed } from "knockout";
import { type WtgFrameworkActionMenuItem } from "wtg-material-ui";

export class TaskViewModel implements BasePageViewModel {
  private hideAlertsSection: Observable<boolean>;
  private cancelEnabled: boolean;
  hasChanges: PureComputed<boolean>;
  cancelText: PureComputed<string>;
  showAdditionalInfo: boolean;
  showAlertsSection: PureComputed<boolean>;
  showSidePanel: PureComputed<boolean>;
  showFooter: () => boolean;
  showSaveButtons: () => boolean;
  showCloseButtons: () => boolean;
  workflowMenuViewModel: PureComputed<WorkflowMenuViewModel | null>;
  messageBar: ExtendedComputed<MessageBar | undefined>;
  pageExtensions?: PageExtensions;
  private cancelFn: (() => Promise<void>) | (() => void);
  private _title: ko.Subscribable<string>;
  statusBindingPath: string;

  constructor(
    private entity: Observable<Entity | undefined> | undefined,
    private expressionData: ExpressionData,
    public notificationSummary: NotificationSummary | undefined,
    public footerTransitions: PureComputed<FooterTransition[]> | undefined,
    cancelFn: () => Promise<void>,
    private saveFn: (disabledReload: boolean) => Promise<EntitySaveResult>,
    private saveWithPromptAsyncFn: () => Promise<boolean>,
    private transitionFn: (transition: FooterTransition) => Promise<void>,
    private captionOverride: ResourceInfo | undefined,
    private captionOverrideArgs: string[] | undefined,
    hideFooter: boolean | undefined,
    hideSaveButtons: boolean | undefined,
    hideCloseButtons: boolean | undefined,
    private messageBarProvider: RunAsClientMessageBarProvider | undefined,
    closeFn: (() => void) | undefined,
    public canShowEDocs: PureComputed<boolean>,
    public showEDocsAsync: () => void,
    public canShowLogs: PureComputed<boolean>,
    public showLogsAsync: () => void,
    public canShowMessages: PureComputed<boolean>,
    public showMessagesAsync: () => void,
    public canShowNotes: PureComputed<boolean>,
    public showNotesAsync: () => void,
    public canShowWorkflow: PureComputed<boolean>,
    public canShowDocuments: PureComputed<boolean>,
    public getDocumentMenuItemsAsync: () => Promise<WtgFrameworkActionMenuItem[]>
  ) {
    this.cancelFn = closeFn ?? cancelFn;
    this.hideAlertsSection = ko.observable(false);
    this.cancelEnabled = !closeFn;
    this.hasChanges = ko.pureComputed(this.getHasChanges, this);
    this.cancelText = ko.pureComputed(this.getCancelText, this);
    this.showAdditionalInfo = false;
    this.showAlertsSection = ko.pureComputed(this.getShowAlertsSection, this);
    this.showSidePanel = this.showAlertsSection;
    this.showFooter = (): boolean => !hideFooter;
    this.showSaveButtons = (): boolean => !hideSaveButtons;
    this.showCloseButtons = (): boolean => !hideCloseButtons;
    this.workflowMenuViewModel = ko.pureComputed(this.getWorkflowMenuViewModel, this);
    this.messageBar = lazyObservable(this.loadMessageBarAsync, this);
    this.pageExtensions = undefined;
    this._title = ko.observable("");
    this.statusBindingPath = "";
  }

  get title(): ko.Subscribable<string> {
    return this._title;
  }

  set title(newTitle: ko.Subscribable<string>) {
    this._title = newTitle;
  }

  async getTitleAsync(formTitle: string): Promise<string> {
    if (!this.captionOverride) {
      return formTitle;
    }

    if (!this.captionOverrideArgs?.length) {
      const captionOverride = captionService.getStringFromInfo(this.captionOverride);

      return getTransformedTitle(this.entity, captionOverride);
    }

    const captionWithMacros = captionService.getString(
      this.captionOverride.ResKey,
      this.captionOverride.Fallback,
      ...this.captionOverrideArgs
    );

    return await macroUtils.replaceMacrosAsync(this.expressionData, captionWithMacros);
  }

  getEntity(): ko.Observable<Entity | undefined> | undefined {
    return this.entity;
  }

  async cancelTaskAsync(): Promise<void> {
    if (this.cancelFn) {
      await this.cancelFn();
    }
  }

  async saveAsync(): Promise<EntitySaveResult> {
    return await this.saveCoreAsync(/*disableReloadOnSaved:*/ false);
  }

  async saveAndCloseAsync(): Promise<void> {
    const entitySaveResult = await this.saveCoreAsync(/*disableReloadOnSaved:*/ true);
    if (entitySaveResult.isSaved) {
      this.cancelTaskAsync();
    }
  }

  saveWithPromptAsync(): Promise<boolean> {
    return this.saveWithPromptAsyncFn();
  }

  toggleAlerts(): void {
    this.hideAlertsSection(!this.hideAlertsSection());
  }

  async invokeTransitionAsync(transition: FooterTransition): Promise<void> {
    return await this.transitionFn(transition);
  }

  getFooterTransitionMenuViewModel(): FooterTransitionMenuViewModel {
    return new FooterTransitionMenuViewModel(this);
  }

  getPrimaryActions(): FormFlowAction[] {
    const actions: FormFlowAction[] = [];
    if (this.showCloseButtons()) {
      actions.push({
        id: "f606d9cb-21b5-4877-87c5-0d015abc4bed",
        caption: this.cancelText(),
        onInvoke: () => this.cancelTaskAsync(),
      });
    }
    if (this.hasChanges() && this.showSaveButtons()) {
      actions.push({
        id: "905f025d-963b-4c99-bd05-115c54d8f2ba",
        caption: captionService.getString("358B4412-9010-4B17-A1F0-332FCD2B7D09", "Save & Close"),
        onInvoke: () => this.saveAndCloseAsync(),
      });
      actions.push({
        id: "aacc9d7c-6095-4a0c-8048-72e2865055f7",
        caption: captionService.getString("027cb47b-e0e4-4a24-be29-0761ce7d14d6", "Save"),
        onInvoke: () => this.saveAsync(),
      });
    }
    return actions;
  }

  getSecondaryActions(): FormFlowAction[] {
    if (this.footerTransitions) {
      return this.footerTransitions().map((transition: FooterTransition) => {
        return {
          id: transition.Id,
          caption:
            typeof transition.Caption === "object"
              ? captionService.getStringFromInfo(transition.Caption)
              : transition.Caption,
          onInvoke: () => this.invokeTransitionAsync(transition),
        };
      });
    }
    return [];
  }

  private async loadMessageBarAsync(): Promise<void> {
    if (this.messageBarProvider) {
      const messageBar = await this.messageBarProvider.getMessageBarAsync();
      this.messageBar(messageBar);
    }
  }

  private getHasChanges(): boolean {
    const entity = this.entity?.();
    return (
      !!entity && !entity.entityAspect.entityState.isDetached() && observeHasChanges(entity.entityAspect.entityManager)
    );
  }

  private getCancelText(): string {
    return this.hasChanges() && this.cancelEnabled
      ? captionService.getString("1F0F0D77-74D6-45A2-9827-32A24619C1E1", "Cancel")
      : captionService.getString("E757CA74-3890-44B9-B10B-96449500A79F", "Close");
  }

  private getShowAlertsSection(): boolean {
    return !this.hideAlertsSection() && (this.notificationSummary ? this.notificationSummary.hasAlerts() : false);
  }

  private getWorkflowMenuViewModel(): WorkflowMenuViewModel | null {
    const entity = this.entity?.();
    return ko.ignoreDependencies(() => {
      const entityType = entity?.entityType;
      if (entityType && canShowWorkflow(entityType)) {
        return new WorkflowMenuViewModel(getPrimaryKey(entity), entityType, entity, this);
      }

      return null;
    });
  }

  private async saveCoreAsync(disableReloadOnSaved: boolean): Promise<EntitySaveResult> {
    if (!this.hasChanges()) {
      return { isSaved: true, error: null };
    }

    return await this.saveFn(disableReloadOnSaved);
  }
}

export default TaskViewModel;
