import connection from "Connection";
import global from "Global";
import { trackBusyStateAsync } from "GlobalBusyStateTracker";
import { getLanguageCode } from "LanguageService";
import { NestedError } from "NestedError";
import $ from "jquery";

export interface GlowAjaxSettings extends GlowAjaxSettingsBase, JQuery.AjaxSettings {}

export interface GlowUrlAjaxSettings extends GlowAjaxSettingsBase, JQuery.UrlAjaxSettings {}

interface GlowAjaxSettingsBase {
  allowQueryStringInUrl?: boolean;
  excludeComplementedHeaders?: boolean;
  isSensitive?: boolean;
}

export type AjaxErrorHandler = <T>(
  jqXHR: JQuery.jqXHR,
  tryAgain: () => Promise<T | AjaxResponse<T>>
) => Promise<T | AjaxResponse<T>> | undefined;

export interface AjaxResponse<T> {
  data: T;
  status: number;
  statusText: string;
  getAllResponseHeaders: () => Record<string, string>;
}

export interface RequestSender {
  sendAsync<T>(ajaxSettings: GlowUrlAjaxSettings): Promise<T>;
  sendAsync<T>(ajaxSettings: GlowUrlAjaxSettings & { includeResponseDetails?: boolean }): Promise<T | AjaxResponse<T>>;
}

export class AjaxService {
  /** @deprecated Use named import for AjaxError instead "import { AjaxError } from "AjaxService"" */
  AjaxError = AjaxError;
  errorHandler?: AjaxErrorHandler;

  /** Override the default request sender */
  requestSenderFactory?: () => RequestSender;

  getAsync<T>(url: string, data?: JQuery.PlainObject): Promise<T> {
    return this.wrapAjaxAsync<T>("GET", url, data);
  }

  postAsync<T>(url: string, data?: JQuery.PlainObject): Promise<T> {
    return this.wrapAjaxAsync<T>("POST", url, data);
  }

  async ajaxAsync<T>(ajaxSettings: GlowUrlAjaxSettings): Promise<T>;
  async ajaxAsync<T>(ajaxSettings: GlowUrlAjaxSettings & { includeResponseDetails: true }): Promise<AjaxResponse<T>>;
  /** @deprecated Use overload with GlowUrlAjaxSettings parameter instead */
  async ajaxAsync<T>(url: string, ajaxSettings?: GlowAjaxSettings): Promise<T>;
  async ajaxAsync<T>(
    ajaxSettingsOrUrl: GlowUrlAjaxSettings | string,
    ajaxSettings?: GlowAjaxSettings
  ): Promise<T | AjaxResponse<T>> {
    const request = getRequest(ajaxSettingsOrUrl, ajaxSettings);
    const requestSender = this.requestSenderFactory?.() ?? new DefaultRequestSender(this.errorHandler);
    const result = await requestSender.sendAsync<T>(request);
    return result;
  }

  async rawAjaxAsync<T>(ajaxSettings: GlowUrlAjaxSettings): Promise<T>;
  async rawAjaxAsync<T>(ajaxSettings: GlowUrlAjaxSettings & { includeResponseDetails: true }): Promise<T>;
  /** @deprecated Use overload with GlowUrlAjaxSettings parameter instead */
  async rawAjaxAsync<T>(url: string, ajaxSettings?: GlowAjaxSettings): Promise<T | AjaxResponse<T>>;
  async rawAjaxAsync<T>(
    ajaxSettingsOrUrl: GlowUrlAjaxSettings | string,
    ajaxSettings?: GlowAjaxSettings
  ): Promise<T | AjaxResponse<T>> {
    const request = getRequest(ajaxSettingsOrUrl, ajaxSettings, true);
    const requestSender = this.requestSenderFactory?.() ?? new DefaultRequestSender();
    const result = await requestSender.sendAsync<T>(request);
    return result;
  }

  private async wrapAjaxAsync<T>(type: string, url: string, data?: JQuery.PlainObject): Promise<T> {
    const ajaxSettings: GlowUrlAjaxSettings = {
      type,
      url,
      data,
    };

    const result = await this.ajaxAsync<T>(ajaxSettings);
    return result;
  }
}

function getRequest(
  ajaxSettingsOrUrl: GlowUrlAjaxSettings | string,
  ajaxSettings?: GlowAjaxSettings,
  excludeComplementedHeaders?: boolean
): GlowUrlAjaxSettings {
  let settings: GlowUrlAjaxSettings;

  if (typeof ajaxSettingsOrUrl === "string") {
    settings = { ...ajaxSettings, url: ajaxSettingsOrUrl };
  } else {
    settings = { ...ajaxSettingsOrUrl };
  }

  const isGet = !settings.type || settings.type.toUpperCase() === "GET";
  if (isGet && !settings.allowQueryStringInUrl && settings.url && settings.url.includes("?")) {
    throw new Error(
      `URLs must not contain query strings. Please specify a parameters object instead, url requested '${settings.url}'.`
    );
  }

  if (!settings.excludeComplementedHeaders && !excludeComplementedHeaders) {
    setComplementedHeaders(settings);
  }

  return settings;
}

function setComplementedHeaders(ajaxSettings: GlowAjaxSettings): void {
  const languageCode = getLanguageCode();
  if (languageCode) {
    ajaxSettings.headers = {
      ...ajaxSettings.headers,
      /*! SuppressStringValidation String validation suppressed in initial refactor */
      "Enterprise-Language-Code": languageCode, //eslint-disable-line @typescript-eslint/naming-convention -- Object Literal Property Name 'Enterprise-Language-Code' should be camelCase/PascalCase
    };
  }
}

class DefaultRequestSender implements RequestSender {
  private readonly errorHandler?: AjaxErrorHandler;
  private remainingRetries: number;
  private canCheckOffline: boolean;

  constructor(errorHandler?: AjaxErrorHandler) {
    this.errorHandler = errorHandler;
    this.remainingRetries = errorHandler ? 2 : 0;
    this.canCheckOffline = true;
  }

  async sendAsync<T>(
    ajaxSettings: GlowUrlAjaxSettings & { includeResponseDetails?: boolean }
  ): Promise<T | AjaxResponse<T>> {
    let result;
    try {
      result = await this.sendCoreAsync<T>(ajaxSettings);
    } catch (error: unknown) {
      return this.handleErrorAsync<T>(error, ajaxSettings);
    }

    const [jqXHR, data] = result;

    return ajaxSettings.includeResponseDetails ? this.wrapResponse(data, jqXHR) : data;
  }

  private sendCoreAsync<T>(ajaxSettings: GlowUrlAjaxSettings): Promise<[JQuery.jqXHR, T]> {
    const promise = new Promise<[JQuery.jqXHR, T]>((resolve, reject) => {
      const url = ajaxSettings.url;
      const withCredentials = url.startsWith(global.baseServiceUri);

      ajaxSettings.xhrFields = ajaxSettings.xhrFields
        ? { withCredentials, ...ajaxSettings.xhrFields }
        : { withCredentials };

      const settings: JQuery.AjaxSettings<T> = {
        ...ajaxSettings,
      };
      // eslint-disable-next-line rulesdir/prefer-async-await
      $.ajax(settings).then(
        (data, _statusText, jqXHR) => {
          resolve([jqXHR, data]);
        },
        (jqXHR, errorStatusText, errorThrown) => {
          reject([jqXHR, errorStatusText, errorThrown]);
        }
      );
    });

    return trackBusyStateAsync(promise);
  }

  private async handleErrorAsync<T>(error: unknown, ajaxSettings: GlowUrlAjaxSettings): Promise<T | AjaxResponse<T>> {
    const [jqXHR, errorStatusText, errorThrown] = error as [JQuery.jqXHR, string, Error];
    if (this.remainingRetries > 0) {
      const handlingResult = this.errorHandler?.(jqXHR, async () => {
        this.canCheckOffline = true;
        this.remainingRetries--;
        const result = await this.sendAsync<T>(ajaxSettings);
        return result;
      });
      if (handlingResult) {
        return handlingResult;
      }
    }

    if (jqXHR.status !== 0 || !this.canCheckOffline) {
      throw new AjaxError(
        ajaxSettings.url,
        jqXHR,
        ajaxSettings,
        errorStatusText,
        errorThrown instanceof Error ? errorThrown : undefined
      );
    }

    const isOnline = await connection.isOnlineAsync();
    if (!isOnline) {
      throw new connection.OfflineError();
    }

    this.canCheckOffline = false;
    const result = await this.sendAsync<T>(ajaxSettings);
    return result;
  }

  private wrapResponse<T>(data: T, jqXHR: JQuery.jqXHR): AjaxResponse<T> {
    return {
      data,
      status: jqXHR.status,
      statusText: jqXHR.statusText,
      getAllResponseHeaders: () => readResponseHeaders(jqXHR),
    };
  }
}

function readResponseHeaders(jqXHR: AjaxResponseDetails): Record<string, string> {
  const headers: Record<string, string> = {};
  const responseHeaders = jqXHR.getAllResponseHeaders && jqXHR.getAllResponseHeaders();
  if (responseHeaders && responseHeaders.length > 0) {
    responseHeaders.split(/\r?\n/).forEach((item) => {
      if (item) {
        const header = item.split(": ");
        headers[header[0].toLowerCase()] = header[1];
      }
    });
  }
  return headers;
}

/*! SuppressStringValidation Property names do not require translation */
export type AjaxResponseDetails = Pick<JQuery.jqXHR, "status" | "statusText" | "responseText"> &
  Partial<Pick<JQuery.jqXHR, "getAllResponseHeaders">>;

export class AjaxError extends NestedError {
  readonly url: string;
  readonly responseText: string;
  readonly status: number;
  readonly statusText: string;
  readonly errorStatusText?: string;
  private readonly responseHeaders;
  private readonly errorData;

  constructor(
    url: string,
    jqXHR: AjaxResponseDetails,
    ajaxSettings?: GlowAjaxSettings,
    errorStatusText?: string,
    cause?: Error
  ) {
    const errorStatus = getErrorStatus(jqXHR);
    super(getErrorMessage(url, errorStatus), cause);

    this.responseHeaders = readResponseHeaders(jqXHR);
    this.errorData = getErrorData(jqXHR, ajaxSettings, errorStatus.status, errorStatusText);
    this.name = "AjaxError";
    this.url = url;
    this.responseText = jqXHR.responseText;
    this.status = errorStatus.status;
    this.statusText = errorStatus.statusText;
    this.errorStatusText = errorStatusText;
  }

  isTransientError(): boolean {
    return this.status >= 500 && this.status < 600;
  }

  getData(): Record<string, unknown>[] {
    return this.errorData;
  }

  getErrorResponse<T>(): T | undefined {
    try {
      return JSON.parse(this.responseText) as T;
    } catch (e) {
      //do nothing//
      return undefined;
    }
  }

  getAllResponseHeaders(): Record<string, string> {
    return this.responseHeaders;
  }

  getResponseHeader(headerName: string): string | null {
    const result = this.responseHeaders[headerName.toLowerCase()];
    return result !== undefined ? result : null;
  }
}

interface ErrorStatus {
  status: number;
  statusText: string;
}

function getErrorMessage(url: string, errorStatus: ErrorStatus): string {
  let result = `AjaxError: URL ${url}, STATUS ${errorStatus.status}`;
  if (errorStatus.statusText) {
    result += ", " + errorStatus.statusText;
  }

  return result;
}

function getErrorStatus(jqXHR: AjaxResponseDetails): ErrorStatus {
  if (jqXHR.status === 200 && jqXHR.responseText && /HTTP\/\d+(\.\d+)* 502 badgateway/i.test(jqXHR.responseText)) {
    /*! SuppressStringValidation http response status */
    return { status: 502, statusText: "Bad Gateway" };
  } else {
    return jqXHR;
  }
}

function getErrorData(
  jqXHR: AjaxResponseDetails,
  ajaxSettings: GlowAjaxSettings | undefined,
  status: number,
  errorStatusText: string | undefined
): Record<string, unknown>[] {
  const result: Record<string, unknown>[] = [
    { name: "ResponseText", value: jqXHR.responseText },
    {
      name: "ResponseHeaders",
      value: jqXHR.getAllResponseHeaders?.() ?? "",
    },
    { name: "ErrorStatusText", value: errorStatusText ?? "" },
  ];

  if (ajaxSettings && !ajaxSettings.isSensitive) {
    result.push({ name: "RequestData", value: ajaxSettings.data ?? "" });
    result.push({
      name: "RequestHeaders",
      value: ajaxSettings.headers ? JSON.stringify(ajaxSettings.headers) : "",
    });
  }

  if (status !== jqXHR.status) {
    result.push({ name: "OriginalStatus", value: jqXHR.status });
    result.push({ name: "OriginalStatusText", value: jqXHR.statusText });
  }

  return result;
}

export default new AjaxService();
