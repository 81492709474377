import captionService from "CaptionService";
import { DeferredPromise } from "DeferredPromise";
import dialogService from "DialogService";
import { loadTemplateAsync } from "ModuleLoader";
import { NotificationType } from "NotificationType";
import { sortBy, uniqBy } from "lodash-es";
import type { AuthenticationContext } from "./AuthenticationContext.ts";

interface SelectContextCallback {
  (value: AuthenticationContext): void;
}

class SelectUserViewModel {
  readonly contexts: AuthenticationContext[];
  readonly onSelected: SelectContextCallback;

  constructor(contexts: AuthenticationContext[], onSelected: SelectContextCallback) {
    this.contexts = sortBy(contexts, (item) => item.description?.toLowerCase() ?? "");
    this.onSelected = onSelected;
  }
}

async function selectUserAsync(
  contexts: AuthenticationContext[] | undefined
): Promise<AuthenticationContext | undefined> {
  if (!contexts?.length) {
    await dialogService.alertAsync(
      NotificationType.Error,
      captionService.getString(
        "ef7fa04f-de73-44dc-ae7a-54d66ddc40b5",
        "You do not have permission to log in to this portal. Contact your system administrator to be granted appropriate permissions."
      ),
      captionService.getString("7a76a8fe-56fd-4f45-bdc9-87e7f71d4061", "Unable to login")
    );
    return undefined;
  }

  let selectedContext: AuthenticationContext | undefined;
  const uniqUsers = uniqBy(contexts, (x) => x.userKey);
  if (uniqUsers.length === 1) {
    selectedContext = uniqUsers[0];
    return selectedContext;
  }

  const onSelected: SelectContextCallback = (value) => {
    selectedContext = value;
    dialogService.hide(dialogInfo);
  };
  const viewModel = new SelectUserViewModel(uniqUsers, onSelected);
  const bodyDfd = loadTemplateAsync("SelectUserDialog.html");
  const buttonOptions = [
    {
      caption: dialogService.buttonTypes.Cancel().text,
      result: dialogService.buttonTypes.Cancel().value,
      isDismiss: true,
    },
  ];
  const resultDfd = new DeferredPromise<AuthenticationContext | undefined>();
  /*! StartNoStringValidationRegion dialogCss is not a caption */
  const dialogInfo = await dialogService.showDialogAsync({
    title: captionService.getString("ad4d20ac-ba34-4f5e-9a6b-2b819bfff69c", "Account Selection"),
    viewModel,
    bodyAllowHtml: true,
    bodyDeferred: bodyDfd,
    dialogCss: "g-context-change-dialog",
    buttonOptions,
    preHideCallback: () => {
      resultDfd.resolve(selectedContext);
    },
  });
  /*! EndNoStringValidationRegion */

  return await resultDfd.promise;
}

export default { selectUserAsync };
