import ClientSideRule from 'ClientSideRule';
import clientSideRuleValueConverter from 'ClientSideRuleValueConverter';
import constants from 'Constants';
import DependencyExpression from 'DependencyExpression';
import dependencyExtractor from 'DependencyExtractorVisitor';
import embeddedDependenciesExtractor from 'ExtractEmbeddedDependenciesVisitor';
import global from 'Global';
import log from 'Log';
import notificationType from 'NotificationType';
import ruleDependencyValue, { DependencyType } from 'RuleDependencyValue';
import RuleExpressionCondition from 'RuleExpressionCondition';
import ServerSideRule from 'ServerSideRule';
import serverSideRuleValueConverter from 'ServerSideRuleValueConverter';
import { defaultValueSymbol } from 'Symbols';
import Promise from 'bluebird';
import _ from 'underscore';

const DependencyPathExpressionType = {
	FullExpression: 0,
	PathWithExpression: 1,
};

/*! StartNoStringValidationRegion enum constants */
export const RuleType = {
	Command: 'command',
	Lookup: 'lookup',
	Property: 'property',
	ProposedValue: 'proposedValue',
	Validation: 'validation',
};
/*! EndNoStringValidationRegion */

function Rule(definition) {
	if (!definition || !definition.id) {
		throw new Error('Rule must have id specified.');
	}
	if (!definition.components || definition.components.length === 0) {
		throw new Error('Rule must have component with at least one item.');
	}
	this.ruleId = definition.id;
	this.ruleType = definition.ruleType;
	this.property = definition.property;
	this.returnType = definition.returnType;
	this.expandPaths = definition.expandPaths || [];
	this.notificationType = parseNotificationType(definition.notificationType);
	this._cacheMode = definition.cacheMode;
	this._dependenciesInfo = undefined;
	this._hasPathDependenciesOtherThanRuleProperty = undefined;
	this._hasPathOrSelfDependenciesExcludingConditions = undefined;
	this._legacyDependencyPaths = undefined;
	this.subRules = definition.components.map(createSubRuleArray.bind(null, definition));
	this.getCacheDependencyFuncAsync = Promise.cache(() => getCacheDependencyFuncAsync(this));
}

(() => {
	Rule.prototype.getAllDependencies = function () {
		const info = getDependenciesInfo(this);
		return uniqueDependencies(
			[].concat(...info.subRules.values(), ...info.subRuleConditions.values())
		);
	};

	Rule.prototype.getDependencies = function (entity) {
		const info = getDependenciesInfo(this);
		const result = new Map();
		const addDependencies = (dependencies, isCondition) => {
			dependencies.forEach((dependency) => {
				if (!result.has(dependency.expression)) {
					result.set(dependency.expression, { dependency, isCondition });
				}
			});
		};

		for (const subRule of this.subRules) {
			if (subRule.condition) {
				const conditionValue = subRule.condition.evaluate(entity);
				addDependencies(info.subRuleConditions.get(subRule), true);
				if (conditionValue.value) {
					addDependencies(info.subRules.get(subRule), false);
					break;
				} else if (conditionValue.state === constants.States.NotLoaded) {
					break;
				}
			} else {
				addDependencies(info.subRules.get(subRule), false);
			}
		}

		return Array.from(result.values());
	};

	Rule.prototype.getDependenciesAsync = function (entity) {
		return Promise.try(async () => {
			const info = getDependenciesInfo(this);
			const result = [];

			await this.subRules.reduce(
				async (shouldContinue, subRule) => {
					const awaitedShouldContinue = await shouldContinue;
					if (!awaitedShouldContinue) {
						return false;
					}
					if (subRule.condition) {
						const conditionValue = await subRule.condition.evaluateAsync(entity);
						result.push(...info.subRuleConditions.get(subRule));
						if (conditionValue) {
							result.push(...info.subRules.get(subRule));
						}
						return !conditionValue;
					} else {
						result.push(...info.subRules.get(subRule));
						return true;
					}
				},
				true
			);
			return uniqueDependencies(result);
		});
	};

	function getDependenciesInfo(rule) {
		if (!rule._dependenciesInfo) {
			const subRules = new Map();
			const subRuleConditions = new Map();
			rule.subRules.forEach((subRule) => {
				subRules.set(
					subRule,
					mapDependencies(
						extractSubRuleDependencies(
							subRule,
							DependencyPathExpressionType.FullExpression
						)
					)
				);
				subRuleConditions.set(
					subRule,
					mapDependencies(
						extractConditionDependencies(
							subRule,
							DependencyPathExpressionType.FullExpression
						)
					)
				);
			});

			rule._dependenciesInfo = {
				subRules,
				subRuleConditions,
			};
		}

		return rule._dependenciesInfo;
	}

	function uniqueDependencies(dependencies) {
		return _.uniq(dependencies, getIteratee);
	}

	function mapDependencies(strings) {
		return trimList(_.sortBy(strings)).map((s) => new DependencyExpression(s));
	}

	function getIteratee(dependencyExpression) {
		return dependencyExpression.expression;
	}
})();

Rule.prototype.getDependencyPathsLegacyDoNotUse = function () {
	if (!this._legacyDependencyPaths) {
		const result = [];

		const type = DependencyPathExpressionType.PathWithExpression;
		this.subRules.forEach((subRule) => {
			result.push(...extractSubRuleDependencies(subRule, type));
			result.push(...extractConditionDependencies(subRule, type));
		});

		result.sort();
		this._legacyDependencyPaths = trimList(result);
	}

	return this._legacyDependencyPaths;
};

function getCacheDependencyFuncAsync(rule) {
	const subRules = rule.subRules;
	const subRulesWithCacheKeyFunc = subRules.filter((s) => s.hasCacheKeyFunc);
	if (subRulesWithCacheKeyFunc.length) {
		return Promise.map(subRulesWithCacheKeyFunc, (s) => s.loadCacheKeyFuncAsync()).then(() => {
			return (entity) => {
				const subRule = getFirstApplicableSubRule(rule, entity);
				return subRule && subRule.hasCacheKeyFunc ? subRule.getCacheKey(entity) : undefined;
			};
		});
	}
	return Promise.resolve();
}

function getFirstApplicableSubRule(rule, entity) {
	const subRules = rule.subRules;
	for (const subRule of subRules) {
		if (!subRule.condition) {
			return subRule;
		}

		const result = subRule.condition.evaluate(entity);
		if (result.value) {
			return subRule;
		} else if (result.state === constants.States.NotLoaded) {
			return;
		}
	}
}

function trimList(list) {
	const result = [];
	list.forEach((current) => {
		if (result.length) {
			const previous = result[result.length - 1];
			if (
				current.startsWith(previous) &&
				(current.length === previous.length || current[previous.length] === '.')
			) {
				result[result.length - 1] = current;
				return;
			}
		}
		result.push(current);
	});

	return result;
}

Rule.prototype.hasPathDependenciesOtherThanRuleProperty = function () {
	if (this._hasPathDependenciesOtherThanRuleProperty === undefined) {
		this._hasPathDependenciesOtherThanRuleProperty = this.getAllDependencies().some((d) =>
			d.getAllDependencyPaths().some((p) => p !== this.property)
		);
	}

	return this._hasPathDependenciesOtherThanRuleProperty;
};

Rule.prototype.hasPathOrSelfDependenciesExcludingConditions = function () {
	if (this._hasPathOrSelfDependenciesExcludingConditions === undefined) {
		this._hasPathOrSelfDependenciesExcludingConditions =
			this.subRules.some((subRule) =>
				subRule.dependencies.some(isPropertyPath) ||
				subRule.parameters.some((parameter) => {
					if (parameter.queryTypeArgument) {
						return extractEmbeddedDependencies(parameter.value).length;
					} else {
						const info = ruleDependencyValue.getDependencyInfo(parameter.value);
						if (info.type === DependencyType.Self) {
							return true;
						} else if (info.type === DependencyType.Expression) {
							return (
								extractDependencies(info.path).length ||
								extractEmbeddedDependencies(info.path).length
							);
						}
					}
				})
			);
	}

	return this._hasPathOrSelfDependenciesExcludingConditions;
};

function extractConditionDependencies(subRule, type) {
	const conditions = subRule.condition && subRule.condition.conditions;
	if (conditions) {
		const result = [];
		conditions.forEach((condition) => {
			condition.parameters.forEach((path) => {
				const info = ruleDependencyValue.getDependencyInfo(path);
				if (info.type === DependencyType.Expression && isPropertyPath(info.path)) {
					result.push(info.path);
				}
			});
		});
		return result;
	}

	const condition = subRule.condition && subRule.condition.conditionExpression;
	if (condition) {
		const propertyPaths = extractDependencies(condition);
		if (propertyPaths.length) {
			if (type !== DependencyPathExpressionType.FullExpression) {
				return propertyPaths;
			} else {
				return [condition];
			}
		}
	}

	return [];
}

function extractSubRuleDependencies(subRule, type) {
	const result = subRule.dependencies.flatMap((d) => extractDependencies(d));
	subRule.parameters.forEach((parameter) => {
		if (parameter.queryTypeArgument) {
			result.push(...extractEmbeddedDependencies(parameter.value));
		} else {
			const info = ruleDependencyValue.getDependencyInfo(parameter.value);
			if (info.type === DependencyType.Expression) {
				const propertyPaths = extractDependencies(info.path).concat(
					extractEmbeddedDependencies(info.path)
				);
				if (propertyPaths.length) {
					if (type !== DependencyPathExpressionType.FullExpression) {
						result.push(...propertyPaths);
					} else {
						result.push(info.path);
					}
				}
			}
		}
	});
	return result;
}

function extractDependencies(dependency) {
	return dependencyExtractor.visitDependency(dependency).filter(isPropertyPath);
}

function extractEmbeddedDependencies(dependency) {
	return embeddedDependenciesExtractor
		.extractEmbeddedDependencies(dependency)
		.filter(isPropertyPath);
}

function isPropertyPath(dependency) {
	return !dependency.startsWith('%');
}

Rule.prototype.tryProcessSync = function (entity, context) {
	let state = constants.States.NotAvailable;
	for (let i = 0; i < this.subRules.length; i++) {
		const subRule = this.subRules[i];
		let conditionValue = !subRule.condition;
		if (!conditionValue) {
			conditionValue = subRule.condition.evaluate(entity);
			if (conditionValue.state === constants.States.NotLoaded) {
				return { state: constants.States.NotLoaded };
			}
			else if (conditionValue.state === constants.States.Available) {
				state = conditionValue.state;
			}
			conditionValue = conditionValue.value;
		}

		if (conditionValue) {
			return subRule.tryProcessSync(this, entity, context);
		}
	}

	return { state, value: null };
};

Rule.prototype.hasSetter = function () {
	const subRules = this.subRules;
	return subRules.length === 1 && subRules[0].hasSetter();
};

Rule.prototype.invokeSetterAsync = function (entity, value) {
	if (!this.hasSetter()) {
		throw new Error('This rule does not have a setter.');
	}
	return this.subRules[0].invokeSetterAsync(entity, value);
};

Rule.prototype.isCacheDisabled = function () {
	return this._cacheMode === 'NotCached';
};

Rule.prototype.processAsync = function (entity, context) {
	const self = this;
	const startTime = performance.now();
	const invocationId = Math.floor(Math.random() * 100000);

	if (global.featureFlags.logRuleInvocationTimings) {
		log.info(`[Rule] Invoked ${self.ruleId}. (${invocationId})`);
	}

	return getFirstApplicableSubRuleAsync(entity, self.subRules).then((result) => {
		if (!result.value) {
			return {
				isSuccess: result.isSuccess,
				value: clientSideRuleValueConverter[self.ruleType] && clientSideRuleValueConverter[self.ruleType](
					defaultValueSymbol,
					null,
					self.returnType
				),
			};
		}

		const subRule = result.value;
		return subRule.processAsync(self, entity, context).tapCatch((error) => {
			log.error({
				/*! SuppressStringValidation error message */
				message: 'Failed to run rule.',
				data: error,
				entityType: entity && entity.entityType ? entity.entityType.interfaceName : '',
				rule: subRule,
			});
		}).finally(() => {
			if (global.featureFlags.logRuleInvocationTimings) {
				log.info(`[Rule] Finished ${self.ruleId} in ${performance.now() - startTime}ms. (${invocationId})`);
			}
		});
	});
};

function getFirstApplicableSubRuleAsync(entity, subRules) {
	return Promise.reduce(
		subRules,
		(result, subRule) => {
			if (result.isSuccess && result.value !== null) {
				return result;
			}

			return Promise.resolve(!subRule.condition || subRule.condition.evaluateAsync(entity))
				.then((isConditionSatisfied) => {
					if (isConditionSatisfied) {
						return { isSuccess: true, value: subRule };
					}
					else {
						return { isSuccess: result.isSuccess || isConditionSatisfied === false, value: null };
					}
				});
		},
		{ isSuccess: false });
}

function createSubRuleArray(definition, component) {
	const subRule = createSubRule(component, definition.ruleType, definition.returnType);
	if (component.conditionExpression) {
		subRule.condition = new RuleExpressionCondition(component.conditionExpression);
	}
	return subRule;
}

function createSubRule(component, ruleType, returnType) {
	let rule;
	/*! SuppressStringValidation (No caption here) */
	if (Object.prototype.hasOwnProperty.call(component, 'uri')) {
		rule = new ServerSideRule(component, serverSideRuleValueConverter[ruleType], returnType);
	}
	else {
		rule = new ClientSideRule(component, clientSideRuleValueConverter[ruleType]);
	}
	return rule;
}

function parseNotificationType(value) {
	if (_.isString(value)) {
		return notificationType.fromString(value);
	}
	else if (_.isNumber(value)) {
		return value;
	}
	else {
		return null;
	}
}

export default Rule;
