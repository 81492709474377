import captionService from 'CaptionService';

export const changePassword = {
	cancel: () => captionService.getString('6a29a12a-4807-4841-873f-3e77d2010b4a', 'Cancel'),
	changePassword: () => captionService.getString('4da314ee-f1f1-488d-99ad-c017af16d10c', 'Change password'),
	confirmPassword: () => captionService.getString('711f971d-bc2b-42d2-a164-70666ebdef73', 'Confirm password'),
	newPassword: () => captionService.getString('ac5a4ade-de17-41a7-a030-6f1afe5a778f', 'New password'),
	oldPassword: () => captionService.getString('47aeecdb-020d-4bcd-85c7-c0c9908f8c7d', 'Old password'),
	passwordsAreNotIdentical: () => captionService.getString('483e818e-7b36-42b2-8a9a-992f42f26a1b', 'Passwords are not identical'),
	passwordChangedSuccess: () => captionService.getString('f9982229-c7e7-4963-a43e-bc1914da0d58', 'Password changed successfully! Use your new password the next time you log in.'),
	passwordRequired: () => captionService.getString('1c6326b0-bfbe-4872-9c55-14687a786c98', 'Password is required'),
};
